import React, { useContext } from 'react'

import { Dropzone } from 'src/design-system/elements'
import { workspaceContext } from 'src/context'

const DashboardDropzone = ({ children }) => {
  const { uploadDocument, workspace, showStorageFilledPrompt } = useContext(workspaceContext)

  const submit = async (files) => {
    console.warn('Uploading')
    if (workspace.isOverStorage) {
      showStorageFilledPrompt()
      throw new Error('Storage overfilled')
    } else {
      uploadDocument(files[0])
    }
  }

  return (
    <Dropzone submit={submit}>
      {children}
    </Dropzone>
  )
}

export default DashboardDropzone
