import React, { useEffect, useContext, useState } from 'react'
import { components } from 'react-select'
import { organisationContext } from 'src/context'
import { useTranslation } from 'react-i18next'
import styled from '@emotion/styled'
import { CreatableSelect } from 'src/design-system/elements'

const CreateCompanySelector = ({
  isDisabled,
  onSelectOrganisation
}) => {
  const [isLoading, setLoading] = useState(false)
  const { createOrganisation, organisations } = useContext(organisationContext)
  const [orgOptions, setOrgOptions] = useState([])
  const { t } = useTranslation()
  const [value, setValue] = useState({ label: '(No Company)', value: null })

  useEffect(() => {
    setOrgOptions([
      { label: '(No Company)', value: null },
      ...organisations.map((org) => {
        return {
          label: org.name,
          value: org.orgId
        }
      })])
  }, [organisations])

  const handleChange = (option) => {
    setValue(option)
    onSelectOrganisation(option)
  }

  const handleCreate = async (inputValue) => {
    setLoading(true)
    const org = await createOrganisation(inputValue)
    handleChange({ value: org.orgId, label: org.name })
    setLoading(false)
  }

  const customStyles = {
    container: (provided) => ({
      ...provided,
      margin: '0 0 10px',
      textAlign: 'left'
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 10
    })
  }

  return (
    <CreateContainer>
      <CreatableSelect
        autoComplete='definitely-off'
        styles={customStyles}
        isClearable={false}
        components={{ Input: React.forwardRef((props, ref) => <components.Input ref={ref} {...props} required={!value} />) }}
        onChange={handleChange}
        isDisabled={isDisabled || isLoading}
        isLoading={isLoading}
        placeholder={t('Type or create new')}
        value={value}
        onCreateOption={handleCreate}
        options={orgOptions}
      />
    </CreateContainer>
  )
}

const CreateContainer = styled.div`
  position: relative;

  > div {
    > div {
      border: 1px solid var(--input-border-color);
      background-color: var(--input-background);
      color: var(--input-color);
      min-height: 32px;
      transition: all 0.3s ease-in-out;

      &:hover,
      &:focus {
        background-color: var(--input-background);
        border: 1px solid var(--input-focus);
      }

      > div {
        > div {
          color: var(--input-color);
        }
      }
    }
  }
`

export default CreateCompanySelector
