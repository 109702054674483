import React, { useState, useEffect } from 'react';

const ProgressBar = ({ progress }) => {
  const progressBarStyle = {
    width: '100%',
    backgroundColor: '#e0e0e0',
    borderRadius: '4px',
    margin: '10px 0',
  };

  const progressStyle = {
    height: '20px',
    backgroundColor: '#635bff',
    borderRadius: '4px',
    width: `${progress}%`,
    transition: 'width 0.5s ease-in-out',
  };

  return (
    <div style={progressBarStyle}>
      <div style={progressStyle}></div>
    </div>
  );
};

const CreateDocumentModal = ({ dismissModal }) => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        const newProgress = oldProgress >= 100 ? 100 : oldProgress + 25;
        if (newProgress === 100) clearInterval(timer);
        return newProgress;
      });
    }, 1000); // Update every second

    return () => clearInterval(timer);
  }, []);

  return (
    <div className='popup-container fullscreen'>
      <div className='popup modal'>
        <div className='popup-content'>
          <button className='popup-close' onClick={dismissModal}/>
          <h2><span style={{ marginRight: '18px' }} className='loading-spinner' />
            Preparing</h2>
          <ProgressBar progress={progress} />
          <p>File will appear in your dashboard shortly</p>
          <br></br>
          <center>
            <button className='btn primary' onClick={dismissModal}>Cancel</button>
          </center>
        </div>
      </div>
    </div>
  );
};

export default CreateDocumentModal;
