import gql from 'graphql-tag'

export const ADD_WORKSPACE_EVENT = gql`
  mutation addWorkspaceEvent(
    $workspace_id: uuid!,
    $type: String!
    $info: jsonb
  ) {
    insert_workspace_events(
      objects: {
        workspace_id: $workspace_id,
        type: $type,
        info: $info
      }
    ) {
      affected_rows
    }
  }
`
