import React, { useState, useContext, useCallback, useEffect, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from '@emotion/styled'
import DataTable from 'react-data-table-component'
import BeatLoader from 'react-spinners/BeatLoader'
import { format, parseISO } from 'date-fns'
import { FiEdit2, FiPlus } from 'react-icons/fi'
import { useAnalytics } from 'use-analytics'
import { useTranslation } from 'react-i18next'
import { toastr } from 'react-redux-toastr'

import { getDateLocale } from 'src/locales/i18n'
import { sendAmplitudeData } from 'src/utils/amplitude'
import { workspaceContext, organisationContext, useUserContext } from 'src/context'
import { RecipientsEditModal, Filter } from 'src/modules/recipients'

const RecipientsPage = () => {
  const navigate = useNavigate()
  const { user, workspaceIndex } = useUserContext()
  const { hasWorkspacePermission } = useContext(workspaceContext)
  const [filteredItems, setFilteredItems] = useState([])
  const [selectedRows, setSelectedRows] = useState([])
  const [toggleCleared, setToggleCleared] = useState(false)
  const [filterText, setFilterText] = useState('')
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false)
  const [showingRecipientEditingModal, toggleRecipientModal] = useState(false)
  const [selectedRecipient, setSelectedRecipient] = useState(null)
  const [isAddingRecipient, setAddingRecipient] = useState(false)
  const { loading, recipients, deleteRecipients, organisations } = useContext(organisationContext)
  const { page } = useAnalytics()
  const { t } = useTranslation()

  useEffect(() => {
    page()
    sendAmplitudeData('PAGE_VIEW_RECIPIENTS')
  }, [])

  const columns = [
    {
      name: t('Name'),
      selector: 'name',
      sortable: true
    },
    {
      name: t('Company'),
      center: true,
      sortable: true,
      cell: (item) => {
        const org = organisations.find(o => o.orgId === item.orgId)
        return org
          ? (
            <OrganisationLink onClick={() => onClickOrganisationLink(org)}>{org.name}</OrganisationLink>
          )
          : <button className='btn xs' hidden={!hasWorkspacePermission('Manage Contacts') ? 'hidden' : undefined} onClick={() => onClickEditLink(item)}>{t('Link to company')}</button>
      }
    },
    {
      name: t('Contact'),
      center: true,
      sortable: true,
      cell: (item) => {
        return item.contact ? item.contact : '-'
      }
    },
    {
      name: t('Email'),
      center: true,
      sortable: true,
      cell: (item) => {
        return item.email ? item.email : '-'
      }
    },
    {
      name: t('Last Updated'),
      selector: 'updated_at',
      sortable: true,
      width: '180px',
      cell: (item) => {
        return format(parseISO(item.updated_at), 'h:mma do MMM yyyy', { locale: getDateLocale() })
      }
    },
    {
      name: '',
      button: true,
      width: '50px',
      cell: (item) => (
        <EditButton hidden={!hasWorkspacePermission('Manage Contacts')} onClick={() => onClickEditLink(item)}>
          <FiEdit2 />
        </EditButton>
      )
    }
  ]

  useEffect(() => {
    if (recipients) {
      setFilteredItems(recipients.filter((recipient) => {
        const val = `${recipient.name},${recipient.email ? recipient.email : ''},${recipient.contact ? recipient.contact : ''}`.toLowerCase()
        return val && val.includes(filterText.toLowerCase())
      }))
    }
  }, [recipients, filterText])

  const handleRowSelected = useCallback(state => {
    setSelectedRows(state.selectedRows)
  }, [])

  const onClickOrganisationLink = useCallback((item) => {
    navigate(`../company/${item.orgId}`)
  }, [navigate, workspaceIndex])

  const onClickEditLink = useCallback((item) => {
    setSelectedRecipient(item)
    setAddingRecipient(false)
    toggleRecipientModal(true)
  }, [])

  const handleDelete = async () => {
    const recipientsList = selectedRows.map(r => r.name)
    const recipientPluralizedText = recipientsList.length > 1 ? 'these recipients' : 'this recipient'
    if (window.confirm(`Are you sure you want to remove ${recipientPluralizedText}:\r ${recipientsList.join(', ')}?`)) {
      setToggleCleared(!toggleCleared)
      const selectedIds = selectedRows.map(r => r.id)
      try {
        await deleteRecipients(selectedIds)
        toastr.info(t('Recipients removed'))
      } catch (e) {
        toastr.error(t('Failed to delete recipients, please contact support'))
      }
    }
  }

  const subHeaderComponentMemo = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle)
        setFilterText('')
      }
    }

    return (
      <Filter
        onFilter={value => { setFilterText(value) }}
        onClear={handleClear}
        filterText={filterText}
        placeholder={t('inputs.Filter by name, contact or email address')}
      />
    )
  }, [filterText, resetPaginationToggle])

  const onClickAddNewRecipient = useCallback(() => {
    setAddingRecipient(true)
    toggleRecipientModal(true)
  }, [])

  return (
    <Wrapper>
      <section>
        <TableContainer>
          <DataTable
            keyField='id'
            columns={columns}
            data={filteredItems}
            defaultSortFieldId={5} // 'updated_at'
            defaultSortAsc={false}
            selectableRows={hasWorkspacePermission('Manage Contacts')}
            selectableRowsHighlight
            contextActions={<button className='btn primary' onClick={handleDelete}>{t('Delete')}</button>}
            onSelectedRowsChange={handleRowSelected}
            clearSelectedRows={toggleCleared}
            progressPending={loading}
            contextMessage={{ singular: 'recipient', plural: 'recipients', message: 'selected' }}
            pagination
            paginationPerPage={25}
            paginationResetDefaultPage={resetPaginationToggle}
            paginationComponentOptions={{ rowsPerPageText: t('Recipients per page') + ':' }}
            striped
            subHeader
            subHeaderAlign='left'
            subHeaderComponent={subHeaderComponentMemo}
            persistTableHead
            noDataComponent={<p className='intro'>{t('There is no recipient available')}.</p>}
            progressComponent={
              <LoaderContainer>
                <BeatLoader
                  size={10}
                  margin={3}
                  color='var(--primary-color)'
                  loading={loading}
                />
              </LoaderContainer>
            }
          />
          { showingRecipientEditingModal && (selectedRecipient !== undefined || isAddingRecipient) &&
            <div className='popup-container fullscreen'>
              <div className='popup modal'>
                <div className='popup-content'>
                  <button className='popup-close'/>
                  <RecipientsEditModal
                    selectedRecipient={selectedRecipient}
                    companies={organisations}
                    user={user}
                    isAddingRecipient={isAddingRecipient}
                    dismissModal={() => { toggleRecipientModal(false) }}
                  />
                </div>
              </div>
            </div>
          }

        </TableContainer>
        <center>
          <button className='btn primary sm' hidden={!hasWorkspacePermission('Manage Contacts')} onClick={() => onClickAddNewRecipient()}>
            <FiPlus /> {t('Add new recipient')}
          </button>
        </center>
      </section>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100%;
  padding-bottom: 50px;

`

const TableContainer = styled.div`
  background-color: var(--background-color);
  color: var(--text-color);
  margin: 0 20px;

  .rdt_TableHeader {
    background-color: transparent;
    color: var(--text-color);
    min-height: 42px;
    > div:last-child {
      background-color: var(--table-row-selected);
      div {
        font-size: 13px;
        font-weight: 500;
      }
    }
  }

  header {
    background-color: transparent;

    input {
      background-color: var(--input-background);
      border: 1px solid var(--input-border-color);
      color: var(--input-color);

      &:hover,
      &:focus {
        background-color: var(--input-background);
        border-color: var(--input-focus);
      }
    }
  }

  .rdt_Pagination,
  .rdt_Table {
    background-color: transparent;

    > div {
      background-color: transparent;
    }

    button {
      color: var(--input-color);
      fill: var(--input-color);
    }
  }

  .rdt_TableCol_Sortable {
    &:focus,
    &:hover {
      color: var(--text-color) !important;
    }
  }

  .rdt_TableCol_Sortable,
  .rdt_TableHeadRow {
    background-color: transparent;
    color: var(--input-color);

    .rdt_TableCol {
      color: inherit;
    }
  }

  .rdt_TableRow {
    background-color: transparent;
    color: var(--text-color);

    &:hover {
      background-color: var(--table-row-selected);
      outline-color: var(--primary-color);

      > div {
        color: var(--text-color);
      }
    }
  }

  .rdt_TableRow:not(:last-of-type) {
    border-bottom-color: var(--border-alt) !important;
  }

  @media screen and (max-width: 580px) {
    margin: 0 5px;

    header {
      padding-left: 0;
      padding-right: 0;

      > div {
        width: 100%;
      }

      input {
        width: 100%;
      }
    }
  }

  @media screen and (max-width: 860px) {
    .rdt_TableHeadRow {
      display: none;
    }

    .rdt_TableRow {
      display: block;
      position: relative;
      padding-top: 10px;
      padding-bottom: 10px;
  
      > div {
        display: block;
        position: relative;
        padding-left: 25px;
        padding-top: 18px;
        padding-bottom: 5px;

        &:nth-child(1) {
          padding-left: 5px;
          position: absolute;
          left: 0;
          top: 7px;
          padding-top: 0px;
          z-index: 1;
        }

        &:nth-child(2) {
          padding-right: 40px;

          > div {
            white-space: normal;
          }
        }

        &:nth-child(7) {
          padding: 0;
          position: absolute;
          right: 0;
          top: 0;
          z-index: 1;
        }

        &::after {
          position: absolute;
          top: 0;
          left: 0;
          color: #aaa;
          padding-left: 25px;
          font-size: 12px;
        }

        &:nth-child(2):after {
          content: "Name"
        }

        &:nth-child(3):after {
          content: "Company"
        }

        &:nth-child(4):after {
          content: "Contact"
        }

        &:nth-child(5):after {
          content: "Email"
        }

        &:nth-child(6):after {
          content: "Last Updated"
        }
      }
    }
  }
`

const LoaderContainer = styled.div`
  margin: 30px auto;
`

const OrganisationLink = styled.span`
  color: var(--primary-color);
  text-decoration: underline;
  cursor: pointer;
`

const EditButton = styled.div`
  cursor: pointer;
  padding: 10px;
`

export default RecipientsPage
