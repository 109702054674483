import React from 'react'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'

const CardFilter = ({
  items, active, onClickItem
}) => {
  const itemList = items.map(({ name, value }) =>
    <Item key={value} className={value === active ? 'active' : ''} onClick={() => onClickItem(value)}>{name}</Item>
  )

  return (
    <Wrapper>
      {itemList}
    </Wrapper>
  )
}

CardFilter.propTypes = {
  items: PropTypes.array,
  onClickItem: PropTypes.func
}

const Wrapper = styled.div`
display: inline-flex;
  padding: 0 23px 0 32px;
  width: 100%;
  padding-left: 2em;
  justify-content: flex-start;
  position: absolute;
  bottom: 0;

  @media only screen and (max-width: 430px) {
    padding: 0 0 0 15px;
    width: auto;
  }

  transition:         all .3s ease-in-out;
`

const Item = styled.div`
  font-size: 1em;
  font-weight: 500;
  color: var(--text-color-light);
  display: flex;
  flex-direction: column;
  cursor: pointer;
  white-space: nowrap;
  padding: 9px;
  border-bottom: 3px solid transparent;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;

  &:not(:last-child) {
    margin-right: 35px;
  }

  @media only screen and (max-width: 768px) {
    &:not(:last-child) {
      margin-right: 23px;
    }
  }

  @media only screen and (max-width: 430px) {
    font-size: 10px;

    &:not(:last-child) {
      margin-right: 10px;
    }
  }

  transition:         all .3s ease-in-out;

  &:hover {
    background-color: var(--background-highlight-color);
  }

  &.active {
    background-color: var(--background-highlight-color);
    border-bottom: 3px solid var(--primary-color);
    color: var(--text-color);
  }
`

export default CardFilter
