import React, { useState, useEffect } from 'react'
import SVGInline from 'react-svg-inline'
import styled from '@emotion/styled'
import { AiOutlineClose } from 'react-icons/ai'

import MainFooter from 'src/modules/navigation/MainFooter'
import { lock, arrowRight2 } from 'src/design-system/icons'
import { ShadowedInput } from 'src/design-system/elements'

const PinScreen = ({
  checkPinInput,
  incorrectPinAttempted,
  previewMode = false,
  loading
}) => {
  const [showError, setShowError] = useState(false)
  useEffect(() => {
    if (incorrectPinAttempted > 1) {
      setShowError(true)
      setTimeout(() => setShowError(false), 500)
    }
  }, [incorrectPinAttempted])

  const onSubmitPin = (event) => {
    event.preventDefault()
    checkPinInput(event.target.pin.value)
  }

  return (
    <PinPage>
      {previewMode && (
        <CloseIcon size={20} color='#A1A8AE' onClick={checkPinInput} />
      )}
      <form onSubmit={onSubmitPin} noValidate>
        <PinContainer isError={showError}>
          <PinInputContainer>
            <InputContainer>
              <ShadowedInput
                autoComplete='really-disable-google-im-serious'
                type='password'
                Icon={() => <Lock svg={lock} />}
                placeholder='Enter PIN'
                name='pin'
                maxLength='6'
              />
            </InputContainer>
            <button className='btn primary xl' type='submit' disabled={loading ? 'loading' : undefined}><ArrowIcon svg={arrowRight2} /></button>
          </PinInputContainer>
        </PinContainer>
      </form>

      <MainFooter previewMode={previewMode} />
    </PinPage>
  )
}

const PinInputContainer = styled.div`
  input { 
    background-color: var(--input-background);
    color: var(--text-color);
  }
  display: flex;
`

const ArrowIcon = styled(SVGInline)`
  path {
    stroke: ${props => props.theme.brand.white};
    stroke-width: 2;
  }
`

const Lock = styled(SVGInline)`
  rect, path {
    transform: scale(0.8);
  }
`

const PinPage = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: var(--background-color);
  position: fixed;
  top: 0;
  bottom: 0;
  z-index: 9999;
`

const PinContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;

  @keyframes shake {
    from, to {
      transform: translate3d(0, 0, 0);
    }
    20% { transform: translate3d(-5px, 0, 0); }
    40% { transform: translate3d(5px, 0, 0); }
    60% { transform: translate3d(-2px, 0, 0); }
    80% { transform: translate3d(2px, 0, 0); }
  }
  animation-duration: ${props => props.isError ? '0.5s' : 0};
  animation-fill-mode: ${props => props.isError ? 'both' : 'none'};
  animation-name: ${props => props.isError ? 'shake' : 'none'};
`

const InputContainer = styled.div`
  width: 230px;
  margin: 0 16px 0 0;

  > div > div:first-child {
    left: auto;
    right: 12px;
  }

  input {
    width: 100%;
    padding: 24px 50px 20px 15px;
    box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.03);
    font-size: 16px;
  }

  svg {
    g {
      stroke: #ffffff;
    }
  }
`

const CloseIcon = styled(AiOutlineClose)`
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
`

export default PinScreen
