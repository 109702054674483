import React, { useRef, useContext, useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import SVGInline from 'react-svg-inline'
import ReactDOM from 'react-dom'
import { externalLink } from 'src/design-system/icons'
import ProTrialTaskList from './ProTrialTaskList'
import { subscriptionContext, useUserContext } from 'src/context'

function usePrevious (value) {
  const ref = useRef()
  useEffect(() => {
    ref.current = value
  }, [value])
  return ref.current
}

const ProTrialWidget = () => {
  const { workspaceIndex } = useUserContext()
  const { remainingTrialDays, isProTrial } = useContext(subscriptionContext)
  const oldDays = usePrevious(remainingTrialDays)
  const [daysAnimation, setDaysAnimation] = useState('')
  const [closed, setClosed] = useState(false)
  const [opened, setOpen] = useState(false)
  const navigate = useNavigate()

  const openDrawer = (status) => {
    setClosed(!status)
    setOpen(status)
  }
  const viewPlans = () => {
    openDrawer(false)
    navigate(workspaceIndex !== null ? `/s/${workspaceIndex}/billing` : '/billing')
  }

  useEffect(() => {
    if (oldDays && remainingTrialDays && remainingTrialDays !== oldDays) {
      setDaysAnimation('extend-animation')
      setTimeout(() => setDaysAnimation(''), 1000)
    }
  }, [remainingTrialDays, oldDays])

  return ReactDOM.createPortal(isProTrial
    ? <div>
      <div className='protrial-widget' onBlur={() => openDrawer(false)}>
        {!opened && (
          <button className={`protrial-header ${daysAnimation}`} onClick={() => openDrawer(!opened)} href='#'>
            <span>My Pro Trial: {remainingTrialDays} Days Left</span>
            <SVGInline svg={externalLink} />
          </button>
        )}
        {opened && (
          <button className={`protrial-header ${daysAnimation} open`} onClick={() => openDrawer(false)} href='#'>
            Complete tasks to extend trial
          </button>
        )}
        <div className={`protrial-body ${opened && 'open'} ${closed && 'close'}`}>
          <h5 className='padding'>You can revisit these at any time</h5>
          <ProTrialTaskList onAction={() => openDrawer(false)} />
          <div className='btn-group center'>
            <button className='btn lg' onClick={() => openDrawer(false)}>Close</button>
            <button className='btn lg primary' onClick={viewPlans}>View plans</button>
          </div>
        </div>
      </div>
      </div>
    : <div />, document.body)
}

export default ProTrialWidget
