import { Link } from 'react-router-dom'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { subscriptionContext } from 'src/context'

const DashboardNotice: React.FunctionComponent = () => {
  const { currentPlan } = useContext(subscriptionContext)
  const [noticeVisible, setVisible] = useState(false)
  const dismiss = useCallback(() => {
    localStorage.setItem('dismissedSoloNotice', 'true')
    setVisible(false)
  }, [])
  useEffect(() => {
    setVisible(!localStorage.getItem('dismissedSoloNotice') && currentPlan?.planId === '')
  }, [setVisible, currentPlan])
  return (
    <div className={`dashboard-notice label small regular ${noticeVisible ? '' : 'hidden'}`}>
      Announcement: Sizle Free is now the Solo plan. <br /><Link to='../billing' className='label regular small blue'>For more information, view  plans</Link>
      <div onClick={dismiss} className='close' />
    </div>
  )
}

export default DashboardNotice
