import React, { useContext, useEffect } from 'react';
import styled from '@emotion/styled';
import { useAnalytics } from 'use-analytics';
import { UserPresentations, DashboardDropzone } from 'src/modules/presentations';
import LatestActivity from 'src/modules/navigation/main-sidebar/components/LatestActivity';
import { sendAmplitudeData } from 'src/utils/amplitude';
import useUserContext from 'src/context/UserContext';
import { workspaceContext } from 'src/context';

const PresentationsPage = () => {
  const { page } = useAnalytics();
  const { user } = useUserContext();
  const { presentations } = useContext(workspaceContext);

  useEffect(() => {
    page();
    sendAmplitudeData('PAGE_VIEW_PRESENTATIONS');
  }, [page]);

  return (
    <DashboardDropzone>
      <Wrapper>
        <DashboardContainer>
          <UserPresentations presentations={presentations} />
          <LatestActivity user={user} />
        </DashboardContainer>
      </Wrapper>
    </DashboardDropzone>
  );
};

const DashboardContainer = styled.div`
  position: relative;
  min-height: calc(100vh - 60px);
  display: flex;
  flex-direction: row;
  align-items: stretch;
  overflow: hidden; /* Ensures no overflow issues */

  @media (max-width: 767px) {
    flex-direction: column; /* Stacks items vertically on small screens */
  }

  & > *:first-of-type {
    flex-grow: 5; /* Allows UserPresentations to take available space */
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100%;
  width: 100%; /* Ensures full width */
`;

export default PresentationsPage;
