import React, { useState } from 'react'
import { PropTypes } from 'prop-types'
import { useNavigate } from 'react-router-dom'
import { useUserContext } from 'src/context'
import CreateWorkspaceModal from './CreateWorkspaceModal'

function CreateNewWorkspaceContainerModal ({ user, dismissModal }) {
  const [workspaceComplete, setWorkspaceComplete] = useState(false)
  const { workspaces } = useUserContext()
  const navigate = useNavigate()

  const onCreateWorkspaceComplete = async (_) => {
    setWorkspaceComplete(true)
    dismissModal()
    const workspaceIndex = workspaces.length
    navigate(`/s/${workspaceIndex === -1 ? 0 : workspaceIndex}/settings#invite-user`)
  }

  return (!workspaceComplete && (
    <CreateWorkspaceModal
      user={user}
      isOpen
      dismissModal={dismissModal}
      onCreateWorkspaceComplete={onCreateWorkspaceComplete}
    />)
  )
}

CreateNewWorkspaceContainerModal.propTypes = {
  user: PropTypes.any,
  dismissModal: PropTypes.func
}

export default CreateNewWorkspaceContainerModal
