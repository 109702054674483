
export const getFullName = (user) => {
  if (!user) { return '' }
  if (user.family_name || user.given_name) {
    return user.family_name && user.family_name !== '' ? `${user.given_name} ${user.family_name}` : user.given_name
  } else if (user.name) {
    return user.name
  } else {
    return ''
  }
}

export const getUserType = (user) => {
  const appMetadata = user?.app_metadata || user?.['https://sizle.io/app_metadata'] || user?.meta_data || {}
  const { accountTier } = appMetadata

  return {
    isProTrial: accountTier === 'Pro-Trial',
    hasProSubscription: accountTier === 'Pro',
    hasProFeatures: ['Pro', 'Pro-Trial'].includes(accountTier)
  }
}

export function userEligibleForProTrial (user) {
  // TODO: Originally this automatically initiated pro-trials, instead we'll move all this into the subscription context
  if (!user) {
    return false
  }
  const userType = getUserType(user)
  if (userType.hasProSubscription) {
    return false
  }
  if (userType.isProTrial) {
    return false
  }
  const expiredTimestamp = getUserMetadata(user).proTrialExpiry
  if (expiredTimestamp && expiredTimestamp > 1640998861000) {
    return false
  }
  return true
}
