import React, { memo, useCallback, useEffect, useState } from 'react'
import { useAuth } from 'oidc-react'
import styled from '@emotion/styled'
import { useMutation } from '@apollo/client'
import { useLocation } from 'react-router-dom'

import { sendAmplitudeData } from 'src/utils/amplitude'
import LoginToComment from 'src/modules/modals/components/LoginToComment'
import ResponseItem from './ResponseItem'
import ResponseForm from './ResponseForm'
import { ADD_WORKSPACE_EVENT } from 'src/graphql/workspace-events'
import { useResponses } from 'src/modules/responses/hooks/useResponses.ts'
import { useUserContext } from 'src/context'

const ResponseList = ({ linkDetails, currentLeadId }) => {
  const { user } = useUserContext()
  const { signIn } = useAuth()
  const [isModalOpen, setModalOpen] = useState(false)
  const [isUserAnonymous, setUserAnonymous] = useState(false)
  const { responses, addResponse, deleteResponse, editResponse } = useResponses(currentLeadId)

  const [addWorkspaceEvent] = useMutation(ADD_WORKSPACE_EVENT)

  const handleEditSubmit = useCallback(async (item, text) => {
    editResponse(item.id, text)
  }, [editResponse])

  const handleDeleteClick = useCallback(async (id) => {
    deleteResponse(id)
  }, [deleteResponse])

  const goToLogin = useCallback(() => {
    signIn({ redirect_uri: window.location.toString() })
  }, [signIn])

  const leaveComment = useCallback(async (text) => {
    if (text && text.length === 0) { return }
    await addResponse(text)
    await addWorkspaceEvent({
      variables: {
        workspace_id: linkDetails.presentation.workspace_id,
        type: 'comment',
        info: {
          linkId: linkDetails.linkId
        }
      }
    })
    sendAmplitudeData('DOCUMENT_COMMENT_ADDED')
  }, [addResponse, addWorkspaceEvent, linkDetails])

  useEffect(() => {
    const comment = localStorage.getItem('comment')
    if (user && comment && currentLeadId) {
      console.log('Creating saved comment')
      leaveComment(comment)
      localStorage.removeItem('comment')
    }
  }, [leaveComment, user, currentLeadId])

  const handleSubmit = useCallback(async (text) => {
    if (!user && !isUserAnonymous) {
      localStorage.setItem('comment', text)
      setModalOpen(true)
    } else {
      setUserAnonymous(true)
      await leaveComment(text)
    }
  }, [leaveComment, setUserAnonymous, setModalOpen, isUserAnonymous, user])

  const dismissModal = () => {
    setModalOpen(false)
    setUserAnonymous(true)
    leaveComment(localStorage.getItem('comment'))
    localStorage.removeItem('comment')
  }

  return (
    <Wrapper>
      {responses.map(el => (
        <ResponseItem
          key={`response-${el.id}`}
          item={el}
          onEditSubmit={handleEditSubmit}
          onDeleteClick={handleDeleteClick}
          title={el.userId && user && el.userId === user.sub && user.sub !== linkDetails.owner.auth0_id ? 'You' : el.title}
          isOwnersResponse={el.userId && el.userId === linkDetails.owner.auth0_id}
          ownerId={linkDetails.owner.auth0_id}
        />
      ))}
      <ResponseFormContainer>
        <ResponseForm onSubmit={handleSubmit} />
      </ResponseFormContainer>
      <LoginToComment isOpen={isModalOpen} dismiss={dismissModal} goToLogin={goToLogin} />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: relative;
  min-width: 340px;
  max-height: 600px;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  z-index: 11;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
`

const ResponseFormContainer = styled.div`
  position: relative;
  margin-top: 15px;
`

ResponseList.propTypes = {
}

export default memo(ResponseList)
