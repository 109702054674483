import React from 'react'
import styled from '@emotion/styled'

import Button from './Button'

const ReactionsButtons = ({ onClick }) => {
  return (
    <>
      <Button color='var(--reaction-thumbs)'>
        <ThumbsButtons>
          <ThumbButton onClick={() => onClick('thumbUp')}>👍</ThumbButton>
          <ThumbButton onClick={() => onClick('thumbDown')}>👎</ThumbButton>
        </ThumbsButtons>
      </Button>
      <Button
        color='var(--reaction-approve)'
        onClick={() => onClick('approve')}
      >
        Approve
      </Button>
      <Button
        color='var(--reaction-decline)'
        onClick={() => onClick('decline')}
      >
        Decline
      </Button>
    </>
  )
}

const ThumbsButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const ThumbButton = styled.button`
  &:not(:last-child) {
    margin-right: 12px;
  }
`

export default ReactionsButtons
