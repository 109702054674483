import React, { useState, useContext } from 'react'
import styled from '@emotion/styled'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { toastr } from 'react-redux-toastr'
import { Link } from 'react-router-dom'
import { FiLink } from 'react-icons/fi'
import { useTranslation } from 'react-i18next'

import PinInput from './PinInput'
import ExpirationDateInput from './ExpirationDateInput'
import BurnTimerInput from './BurnTimerInput'
import CreateCompanySelector from './CompanySelector'
import CreateRecipientSelector from './RecipientSelector'
import { useStaticLink } from 'src/modules/presentations/hooks'
import { workspaceContext } from 'src/context'
import LabeledToggle from 'src/modules/presentations/components/popup/LabeledToggle'

const CreateLinkTab = ({
  presentationId
}) => {
  const { t } = useTranslation()
  const [{ linkId, url }, createStaticLink] = useStaticLink()

  const [selectedOrganisation, setSelectedOrganisation] = useState(null)
  const [selectedRecipient, setSelectedRecipient] = useState('')
  const [isLoading, toggleLoading] = useState(false)
  const { workspaceIndex } = useContext(workspaceContext)

  const [displayingLinkCreated, toggleDisplayCreatedLink] = useState(false)
  const [linkPin, setLinkPin] = useState(null)
  const [expirationDate, setExpirationDate] = useState(null)
  const [burnTimer, setBurnTimer] = useState(null)
  const [downloadsEnabled, setDownloadsEnabled] = useState(true)
  const [commentsEnabled, setCommentsEnabled] = useState(true)
  const [reactionsEnabled, setReactionsEnabled] = useState(true)
  const [activeLinkPin, toggleLinkPin] = useState(false)

  const toggleAccessPin = (status) => {
    toggleLinkPin(status)
  }

  const handleAccessPinChange = (value) => {
    setLinkPin(value)
  }

  const handleExpirationDateChange = (value) => {
    setExpirationDate(value)
  }

  const handleBurnTimerChange = (value) => {
    setBurnTimer(value)
  }

  const handleSelectRecipient = (selectedRecipient) => {
    toggleDisplayCreatedLink(false)
    setSelectedRecipient(selectedRecipient.value)
  }

  const handleSelectOrganisation = (selectedOrg) => {
    toggleDisplayCreatedLink(false)
    setSelectedOrganisation(selectedOrg.value)
    setSelectedRecipient(null)
  }

  const submitLink = async (e) => {
    e.preventDefault()
    toggleLoading(true)
    await createStaticLink(presentationId, {
      recipientId: selectedRecipient,
      burnTimer,
      expirationDate,
      pin: linkPin,
      downloadsEnabled,
      commentsEnabled,
      reactionsEnabled
    })
    toggleLoading(false)
    toggleDisplayCreatedLink(true)
  }

  const showCopiedMessage = () => {
    toastr.info('Link copied to clipboard', { timeout: 1000 })
  }

  const clickCreateAnotherLink = () => {
    toggleLoading(false)
    toggleDisplayCreatedLink(false)
  }

  return (
    !displayingLinkCreated
      ? (
        <Container onSubmit={submitLink}>
          <div className='p2'>
          <RowInputLabel>{t('Contact')}</RowInputLabel>
            <CreateRecipientSelector className='create-selector' organisationId={selectedOrganisation} onSelectRecipient={handleSelectRecipient} />
            <RowInputLabel>{t('Company')}</RowInputLabel>
            <CreateCompanySelector onSelectOrganisation={handleSelectOrganisation} />
            <LabeledToggle title='Reactions' subtitle='' defaultChecked={reactionsEnabled} onChange={e => setReactionsEnabled(e.target.checked)} />
            <LabeledToggle title='Comments' subtitle='' defaultChecked={commentsEnabled} onChange={e => setCommentsEnabled(e.target.checked)} />
        <LabeledToggle title='Download allowed' subtitle='' defaultChecked={downloadsEnabled} onChange={e => setDownloadsEnabled(e.target.checked)} />
        <br />
        <span>Security</span>
            <PinInput
              label={t('PIN')}
              activeLinkPin={activeLinkPin}
              handleAccessPinChange={handleAccessPinChange}
              toggleAccessPin={toggleAccessPin}
            />
            <ExpirationDateInput label={t('Expiry')} onChange={handleExpirationDateChange}/>
            <BurnTimerInput label={t('Burn after')} onChange={handleBurnTimerChange}/>
          </div>
          <br />
          <ButtonWrapper>
            <button className='btn primary tall' loading={isLoading ? 'loading' : undefined} type='submit'>
              <FiLink />
              {t('Create link')}
            </button>
          </ButtonWrapper>
        </Container>
      )
      : (
        <>
          <div className='p2'>
            <SuccessMessage>
            <h6>{t('Success')} <span role='img' aria-label='Success!'></span></h6>
            <span>{t('Copy and share the link below')}.</span>
          </SuccessMessage>
          </div>
          <LinkContainer>
            <LinkInput disabled value={url} />
            <LinkButtons>
              <CopyToClipboard text={`${url}`} onCopy={() => showCopiedMessage()}>
                <button className='btn'>{t('setupPopup.Copy link')}</button>
              </CopyToClipboard>
              <Link to={`/s/${workspaceIndex}/presentation/${presentationId}/analytics?filter=unique&link_id=${linkId}`}>
                <button className='btn'>{t('Analytics')}</button>
              </Link>
            </LinkButtons>
            <br />
            <button className='btn primary md' onClick={clickCreateAnotherLink}><FiLink /> {t('Create new link')}</button>
          </LinkContainer>
        </>
      )
  )
}

const BorderInput = styled.input`
  border-radius: 8px;
  border-width: 1px;
  border-style: solid;
  border-color: var(--input-border-color);
  background-color: var(--input-background);
  color: var(--input-color);
  font-size: 12px;
  padding: 8px 12px;
  display: block;
  margin: 0 12px 10px;
  width: 350px;
  min-height: 30px;
`

const Container = styled.form`
  &:invalid button[type="submit"] {
    opacity: 0.5;
    cursor: not-allowed;
  }
`

const SuccessMessage = styled(Container)`
  max-width: 295px;
  text-align: center;
  margin: 0 auto;

  h6 {
    font-size: 15px;
    font-weight: 500;
    margin: 0 0 4px;
    color: var(--text-color);
  }
  p {
    margin: 0 10px 16px;
    color: var(--text-color-lighter);
  }
  i {
    color: var(--text-color);
    font-style: normal;
    font-weight: 500;
  }
  > span {
    color: var(--text-color-lighter);
    font-size: 12px;
    line-height: 1.4;
    display: block;
  }
`

const ButtonWrapper = styled.div`
  display: flex;
  margin: px 0 0;
  align-items: center;
  padding: 0px 0px 0px 0px;
  justify-content: left;
`

const LinkContainer = styled.div`
  width: 100%;
  text-align: center;
  padding: 16px 48px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;

  h6 {
    color: var(--text-color);
    font-size: 12px;
    display: block;
  }
`

const RowInputLabel = styled.label`
  font-size: 12px;
  font-weight: 500;
  color: var(--text-color);
  display: block;
  text-align: left;
  white-space: nowrap;
  padding-bottom: 4px;
`

const LinkButtons = styled.div`
  display: flex;
  flex-wrap: nowrap;
  >button:first-of-type{
    margin-right: 20px;
  }
`

const LinkInput = styled(BorderInput)`
font-size: 1em;
margin: 5px 0 25px;
`

export default CreateLinkTab
