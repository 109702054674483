import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { ru } from 'date-fns/locale'

import _en from './en.json'
import _ru from './ru.json'

i18n.use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    lng: window?.localStorage?.i18nextLng || 'en',
    debug: false,

    interpolation: {
      escapeValue: false // not needed for react!!
    },

    resources: {
      en: { translation: _en },
      ru: { translation: _ru }
    }
  })

export function t (name, params = {}) {
  return i18n.t(name, params)
}

export const getLanguage = () => {
  return i18n.language ||
    (typeof window !== 'undefined' && window.localStorage.i18nextLng) ||
    'en'
}

export const getDateLocale = () => {
  switch (getLanguage()) {
    case 'en':
      return undefined
    case 'ru':
      return ru
    default:
      return undefined
  }
}

export const changeLanguage = (lang = 'en', setCookies) => {
  i18n.changeLanguage(lang)
  setCookies('preferableLanguage', lang, {
    path: '/',
    domain: `${import.meta.env.VITE_COOKIE_STORAGE_DOMAIN}`,
    secure: import.meta.env.VITE_COOKIE_SECURED,
    sameSite: 'lax'
  })
}

export default i18n
