import { checkCircle } from '@sizle-icons'
import { useLocation, useParams } from 'react-router-dom'
import SVGInline from 'react-svg-inline'
import React, { useContext } from 'react'
import { workspaceContext, subscriptionContext, useUserContext } from 'src/context'
import { OptionalLink } from 'src/modules/controls/components/buttons'

const ProTrialTaskList = ({ onAction, className }) => {
  const { visiblePresentations } = useUserContext()
  const { hasWorkspacePermission } = useContext(workspaceContext)
  const { trial, remainingTrialDays } = useContext(subscriptionContext)
  const { pathname } = useLocation()
  const { workspaceIdentifier } = useParams()

  const getInviteUserLocation = () => {
    if (!hasWorkspacePermission('Workspace Settings')) return null
    return workspaceIdentifier ? `/s/${workspaceIdentifier}/settings#invite-user` : '/settings#invite-user'
  }

  const getShareDocumentLocation = () => {
    if (!hasWorkspacePermission('Share Documents')) return null
    if (pathname.includes('edit')) {
      return '#share'
    } else {
      const path = workspaceIdentifier ? `/s/${workspaceIdentifier}/presentations` : '/presentations'
      return visiblePresentations.length > 0 ? `${path}#presentation-dropdown-0` : `${path}#create-document`
    }
  }
  const getCreateWorkspaceLocation = () => {
    return pathname.includes('edit') ? '../../presentations#create-workspace' : '#create-workspace'
  }

  const getCreateDocumentLocation = () => {
    if (!hasWorkspacePermission('Add & Edit Documents')) return null
    return pathname.includes('edit') ? '../../presentations#create-document' : '#create-document'
  }

  const createDocumentUsed = trial?.extensions_used.includes('CreateDocument')
  const shareDocumentUsed = trial?.extensions_used.includes('ShareDocument')
  const createWorkspaceUsed = trial?.extensions_used.includes('CreateWorkspace')
  const inviteTeamMemberUsed = trial?.extensions_used.includes('InviteTeamMember')

  const checklist = [
    [createDocumentUsed, getCreateDocumentLocation, 'Upload a file', '1', 'Click to go to document upload and complete this task'],
    [shareDocumentUsed, getShareDocumentLocation, 'Share a document', '1', 'Share your first file or document to complete this task'],
    [createWorkspaceUsed, getCreateWorkspaceLocation, 'Create a workspace', '1', 'Click to go to workspace creation and complete this task'],
    [inviteTeamMemberUsed, getInviteUserLocation, 'Invite a team member', '2', 'Send an invite to a shared workspace to complete this task'],
  ]

  return (
    <div className={`trial-tasks ${className}`}>
      { checklist.map(([used, getLocation, title, days, tooltip]) => (
        <OptionalLink
          data-tooltip={!used ? tooltip : undefined}
          to={!used ? getLocation() : undefined}
          onClick={used ? undefined : onAction}
          className={`trial-task tooltip tooltip-top ${used ? 'used' : ''}`}
        >
          <span>{title}</span>
          <span>+{days} day</span>
          <div className='goto-action'><div /></div>
          <SVGInline className='check' svg={checkCircle} />
        </OptionalLink>
      ))}
      <div className='trial-timer'>
        {remainingTrialDays} days left in Pro trial
      </div>
    </div>
)
}

export default ProTrialTaskList
