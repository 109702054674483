import gql from 'graphql-tag'

export const UPDATE_RECIPIENT = gql`
  mutation update_recipients(
    $recipientId: uuid!,
    $recipientName: String!,
    $recipientContact: String,
    $recipientEmail: String,
    $orgId: uuid
  ) {
    update_recipients(
      where: {
        recipientId: { _eq: $recipientId },
        deleted_at: {_is_null: true}
      },
      _set: {
        recipientName: $recipientName,
        recipientContact: $recipientContact,
        recipientEmail: $recipientEmail,
        orgId: $orgId
      }
    ) {
      returning {
        recipientId
        recipientName
        recipientContact
        recipientEmail
      }
    }
  }
`
export const DELETE_RECIPIENTS = gql`
  mutation update_recipients(
    $recipientIds: [uuid!]!,
  ) {
    update_recipients(
      where: {
        recipientId: { _in: $recipientIds },
        deleted_at: {_is_null: true}
      },
      _set: {
        deleted_at: "now()"
      }
    ) {
      returning {
        recipientId
        recipientName
      }
    }
  }
`

export const INSERT_RECIPIENT = gql`
  mutation insert_recipients(
    $workspaceId: uuid!,
    $recipientName: String!,
    $recipientContact: String,
    $recipientEmail: String,
    $orgId: uuid
  ) {
    insert_recipients(objects: {
      workspaceId: $workspaceId,
      recipientName: $recipientName,
      recipientContact: $recipientContact,
      recipientEmail: $recipientEmail,
      orgId: $orgId
    }) {
      returning {
        recipientId
        recipientName
        recipientContact
        recipientEmail
      }
    }
  }
`

export const INSERT_RECIPIENT_FOR_EXISTING_ORGANISATION = gql`
  mutation insert_recipients(
    $workspaceId: uuid!,
    $recipientName: String!,
    $recipientContact: String,
    $recipientEmail: String,
    $organisationId: uuid
  ) {
    insert_recipients(objects: {
      workspaceId: $workspaceId,
      recipientName: $recipientName,
      recipientContact: $recipientContact,
      recipientEmail: $recipientEmail,
      orgId: $organisationId
    }) {
      returning {
        workspaceId
        recipientId
        recipientName
        recipientContact
        recipientEmail
      }
    }
  }
`

export const INSERT_RECIPIENT_WITH_NEW_ORGANISATION = gql`
  mutation insert_recipients(
    $workspaceId: uuid!,
    $recipientName: String!,
    $recipientContact: String,
    $recipientEmail: String,
    $organisationName: String!
  ) {
    insert_recipients(objects: {
      workspaceId: $workspaceId,
      recipientName: $recipientName,
      recipientContact: $recipientContact,
      recipientEmail: $recipientEmail,
      organisation: {
        data: {
          name: $organisationName,
          workspaceId: $workspaceId
        }
      }
    }) {
      returning {
        recipientId
        recipientName
        recipientContact
        recipientEmail
        organisation {
          orgId
          name
          updated_at
        }
      }
    }
  }
`
