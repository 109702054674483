import React, { useContext } from 'react'
import { ModeContext } from 'src/context/ModeContext'

// previewWorkspaceId is a bit of a hack, when we're showing the viewer banner via the preview mode
// then we want to try to load the workspace banner, which usually would require a linkid. Since
// we don't have a linkid while in preview mode, we pass in the workspaceId and use that.
const PoweredByLabel = ({ linkId, previewWorkspaceId }) => {
  const { hideSizleBrand, mode } = useContext(ModeContext)

  return (
    <a className='powered-by' href={(!hideSizleBrand && !previewWorkspaceId) ? `${import.meta.env.VITE_MAIN_URL}/` : ''} target='_blank' rel='noreferrer'>
      {previewWorkspaceId
      ? <img src={`/api/workspaces/${previewWorkspaceId}/banner?mode=${mode}&poweredBy`} alt='Logo' />
      : <img src={`/api/links/${linkId}/banner?mode=${mode}&poweredBy`} alt='Logo' />}
    </a>
  )
}

export default PoweredByLabel
