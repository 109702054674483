import React, { useState, useRef, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { ProPopup } from 'src/modules/modals/components/ProPopup'
import { DateTimePicker } from 'src/design-system/elements'

const ExpirationDateInput = ({
  label,
  isDisabled,
  defaultValue = null,
  onChange
}: {
  label?: string,
  isDisabled?: boolean,
  value?: Date,
  defaultValue: Date | null,
  onChange: (expirationDate: Date | null) => Promise<void> | null
}) => {
  const [expirationDate, setExpirationDate] = useState(defaultValue)
  const [toggled, toggle] = useState(!!defaultValue)
  const inputRef = useRef()
  const { t } = useTranslation()

  const handleDateUpdated = useCallback((value: Date) => {
    setExpirationDate(value)
    toggle(true)
    // We can't use state stored value yet, as its stored async
    onChange && onChange(value)
  }, [toggled, onChange, expirationDate])

  const handleToggle = useCallback((_event: React.MouseEvent<HTMLButtonElement>) => {
    toggle((t) => !t)
    // We can't use state stored value yet, as its stored async
    onChange && onChange(!toggled ? expirationDate : null)
  }, [expirationDate, toggled, onChange])

  return (
    <ProPopup requiredFeature='shareTimers'>
      <div className='expired-datepicker'>
      <button disabled={isDisabled} type='button' onClick={handleToggle} className={`switch ${toggled && 'on'}`} />
        <div className='input-wrapper'>
          <label htmlFor='expirationDate' className='label small' title={t('setupPopup.Expiration date tooltip')}>
            {label || t('Expiry')}
          </label>
          <DateTimePicker
            required={toggled}
            placeholder='Choose date'
            padding='10px 8px'
            maxWidth='180px'
            minWidth='180px'
            ref={inputRef}
            name='expirationDate'
            open
            selected={expirationDate}
            isDisabled={isDisabled}
            onChange={handleDateUpdated}
            minDate={new Date()}
          />
        </div>
      </div>
    </ProPopup>
  )
}

export default ExpirationDateInput
