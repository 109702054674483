import gql from 'graphql-tag'

export const INSERT_WORKSPACE_ROLE = gql`
mutation insert_workspace_role($workspaceId: uuid!, $roleName: String!) {
  insert_workspace_roles(objects: { name: $roleName, workspace_id: $workspaceId }) {
    returning {
      role_id
    }
  }
}`

export const INSERT_ROLE_PERMISSION = gql`
mutation insert_role_permission($workspaceId: uuid!, $roleId: Int!, $permissionName: Int!) {
  insert_role_permissions(objects: {role_id: $roleId, permission: $permissioName}) {
    returning {
      role_id
      permission
    }
  }
}`

export const REMOVE_ROLE_PERMISSION = gql`
mutation delete_role_permission($workspaceId: uuid!, $roleId: String!, $permissionName: String!) {
  delete_role_permissions(where: {_and: [{workspace_id: {_eq: $workspaceId}}, {role_id: {_eq: $roleId}}, {permission: {_eq: $permissionName}}]}) {
    returning {
      role_id
      permission
      name
    }
  }
}`

export const CREATE_DEFAULT_USER_WORKSPACE = gql`
  mutation create_user_personal_workspace(
    $workspaceName: String!
  ) {
    insert_folders(objects: {
      folderName: "My Documents",
        workspace: {
          data: {
            name: $workspaceName
            workspace_members: {
              data: {
                type: admin
              }
            }
          }
        }
      }
    ) {
      returning {
        folderId
        workspace {
          workspace_id
        }
      }
    }
  }
`

export const GET_USER_DETAILS = gql`
  query get_user_detail($userId: String!, $activeFolder: Int! = -99, $email: String!) {
    presentations (order_by: {updated_at: desc}, where: {folderId: {_eq: $activeFolder}}) {
      presentationId
      title
      url
      content
      created_at
      updated_at
      emailNotificationsEnabled
      openTrackingEnabled
      slideAnalyticsEnabled
      formEnabled
      originalFileUrl
      originalFileType
      thumbnail
      status
      owner {
        name
        updated_at
        family_name
        given_name
        picture
        auth0_id
      }
    }
    trials {
      extensions_used
      expiry
      started
    }
    user_subscriptions(
      where: {
        userId: {_eq: $userId }
      }
    ) {
      id
      userId
      plan {
        planId
        planTier
        seatCount
        workspaceCount
        pin
        allowInvites
        analytics
      }
    }
    permissions {
      name
      description
    }
    workspace_invites(
      where: {
        email_address: { _eq: $email },
        is_seen: {_neq: true},
        has_accepted: {_is_null: true}
      }
    ) {
      email_address
      invite_id
      is_sent
      is_seen
      invite_details
      workspace_id
      role {
        role_id
        is_editable
        name
      }
    }
    workspace_members(
      where: {
        user_id: {_eq: $userId}
      },
      order_by: {
        created_at: asc
      }
    ) {
      workspace {
        theme { has_banner }
        presentations_aggregate(where: { title: { _neq: "Welcome to Sizle!"} }) { aggregate { count sum { filesize } } }
        custom_domains {
          domain
          cloudfront_domain
          cloudfront_domain_status
          acm_verification_name
          acm_verification_value
          acm_verification_status
          enabled
          deploying
        }
        workspace_members(order_by: {created_at: asc }) {
          role { role_id, name, permissions { permission } }
          user_id
          member_type { value }
          user_id
          user {
            email
            meta_data
            name
            given_name
            family_name
            picture
            updated_at
          }
        }
        roles(order_by: { role_id: asc }) {
          role_id
          name
          is_default
          is_editable
          permissions { permission }
        }
        subscription {
          includedSeats
          purchasedSeats
          plan {
            planId
            seatCount
            cost
          }
        }
        invites {
          has_accepted
          email_address
          invite_id
          role {
            role_id
            name
            is_editable
          }
          user {
            email
            meta_data
            name
            given_name
            family_name
            picture
          }
        }
        owner {
          auth0_id
          meta_data
          subscription {
            plan {
              seatCount
              planId
              planTier
              workspaceCount
              pin
              allowInvites
              storageLimit
              documentLimit
              allowDownloads
              shareTimers
              customDomains
              analytics
              noCaptcha
              email_collection
              sharing
            }
          }
        }
        workspace_invitation_link {
          email_extensions
          invitation_link_id
        }
        is_personal
        name
        picture
        workspace_id
        updated_at
      }
    }
  }
`

export const ADD_NEW_WORKSPACE = gql`
  mutation create_workspace(
    $workspaceName: String!,
    $picture: String!,
    $owner: String!
  ) {
    create_workspace(args: { workspace_name: $workspaceName, owner: $owner, picture: $picture }) {
      workspace_id
    }
  }

`

export const TRANSFER_EXISTING_USER_PROPERTIES_TO_DEFAULT_WORKSPACE = gql`
  mutation transfer_existing_user_properties_to_default_workspace(
    $workspaceId: uuid!,
  ) {
    update_folders(
      where: {
        deleted_at: {_is_null: true}
      },
      _set: {workspaceId: $workspaceId}
    ) {
      returning {
        folderId
      }
    }
    update_organisations(
      where: {
        deleted_at: {_is_null: true}
      },
      _set: {workspaceId: $workspaceId}
    ) {
      returning {
        orgId
        name
      }
    }
    update_recipients(
      where: {
        deleted_at: {_is_null: true}
      },
      _set: {workspaceId: $workspaceId}
    ) {
      returning {
        recipientId
        recipientName
      }
    }
  }
`

export const GET_WORKSPACE_DETAILS = gql`
  query get_workspace_details($workspaceId: uuid!) {
    workspaces(
      where: {
        workspace_id: {_eq: $workspaceId}
      }
    ) {
      name
      picture
      workspace_members (
        order_by: {
          member_type: {value: asc},
          user: {name: asc}
        }
      ) {
        type
        user {
          family_name
          given_name
          auth0_id
          name
          email
          picture
          meta_data
        }
      }
      invites(
        where: {
          is_seen: {_eq: false},
          has_accepted: {_is_null: true}
        }
      ) {
        email_address
        is_sent
        is_seen
        has_accepted
        invite_id
        owner {
          family_name
          given_name
          auth0_id
          name
          email
          picture
        }
      }
      subscription {
        includedSeats
        purchasedSeats
        isFromAnnualSubscription
        isFromLifetimeSubscription
      }
    }
    workspace_subscriptions (
      where: {
        workspaceId: {_eq: $workspaceId}
      }
    ) {
      workspaceSubscriptionId
    }
  }
`

export const UPDATE_WORKSPACE_PICTURE = gql`
  mutation update_workspace_picture(
    $userId: String!,
    $workspaceId: uuid!,
    $picture: String!,
  ) {
    update_workspaces(
      where: {
        workspace_id: {_eq: $workspaceId},
        owner_id: {_eq: $userId}
      },
      _set: {
        picture: $picture
      }) {
      returning {
        workspace_id
      }
    }
  }
`

export const UPDATE_WORKSPACE_NAME = gql`
  mutation update_workspace_name(
    $userId: String!,
    $workspaceId: uuid!,
    $workspaceName: String!,
  ) {
    update_workspaces(
      where: {
        workspace_id: {_eq: $workspaceId},
        owner_id: {_eq: $userId}
      },
      _set: {
        name: $workspaceName
      }) {
      returning {
        workspace_id
      }
    }
  }
`

export const DELETE_WORKSPACE_MEMBER = gql`
  mutation delete_workspace_members(
    $userId: String!,
    $workspaceId: uuid!
  ) {
    delete_workspace_members(
      where: {
        _and: {
          user_id: {_eq: $userId},
          workspace_id: {_eq: $workspaceId}
        },
        type: {_eq: editor}
      }) {
      returning {
        user_id
      }
    }
  }
`

export const CREATE_INVITATION_LINK = gql`
  mutation create_invitation_link(
    $workspaceId: uuid!,
    $invitationLinkId: String!
  ) {
    insert_workspace_invitation_links(objects: {
      workspace_id: $workspaceId,
      invitation_link_id: $invitationLinkId
    }) {
      affected_rows
    }
  }
`

export const DELETE_INVITATION_LINK = gql`
  mutation delete_invitation_link(
    $invitationLinkId: String!
  ) {
    delete_workspace_invitation_links(where: {
      invitation_link_id: {_eq: $invitationLinkId}
    }) {
      affected_rows
    }
  }
`

export const UPDATE_INVITATION_LINK = gql`
  mutation update_invitation_link(
    $invitationLinkId: String!
    $emailExtensions: jsonb!
  ) {
    update_workspace_invitation_links(
      where: {invitation_link_id: {_eq: $invitationLinkId}},
      _set: {email_extensions: $emailExtensions}
    ) {
      affected_rows
    }
  }
`
