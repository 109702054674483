import React, { useEffect, useCallback, useState, ReactNode } from 'react'
import { differenceInDays, compareAsc, addMonths, format, addWeeks } from 'date-fns'
import axios from 'axios'

import { getDateLocale } from 'src/locales/i18n'
import { getUserType } from 'src/utils/user'
import { useModal } from 'src/modules/modals'
import { Token } from '@stripe/stripe-js'
import useUserContext from './UserContext'

export const subscriptionContext: React.Context<any> = React.createContext({
  monthlyPlan: {},
  annualPlan: {},
  advMonthlyPlan: {},
  advAnnualPlan: {},
  freePlan: {},
  soloMonthlyPlan: {},
  soloAnnualPlan: {},
  seatPlan: {},
  trialPlan: {}
})

export const SubscriptionProvider = ({ children }: {children: ReactNode | ReactNode[]}) => {
  const { setModal } = useModal()
  const { user } = useUserContext()
  const [subscription, setSubscription] = useState<any>({})
  const [{
    seatPlan,
    trialPlan,
    freePlan,
    soloMonthlyPlan,
    soloAnnualPlan,
    annualPlan,
    monthlyPlan,
    advMonthlyPlan,
    advAnnualPlan
  }, setPlans] = useState({
    seatPlan: {} as any,
    trialPlan: {} as any,
    freePlan: {} as any,
    soloMonthlyPlan: {} as any,
    soloAnnualPlan: {} as any,
    annualPlan: {} as any,
    monthlyPlan: {} as any,
    advMonthlyPlan: {} as any,
    advAnnualPlan: {} as any
  })
  const [upcomingInvoices, setUpcomingInvoices] = useState<null | any[]>([])
  const [invoices, setInvoices] = useState([])
  const [nextInvoiceDate, setNextInvoiceDate] = useState<string | null>(null)
  const [nextInvoiceAmount, setNextInvoiceAmount] = useState<string | null>(null)
  const [paymentMethod, setPaymentMethod] = useState<any>(null)
  const [trial, setTrial] = useState<null | {expiry: Date, initiated: Date}>(null)

  /**
   * Track subscripion etc. state sent from server, data is fetched in both plain GET
   * and various POST responses so a common function handles them all
   */
  const updateSubscriptionState = useCallback((blob) => {
    setSubscription(blob.subscription)
    setPaymentMethod(blob.paymentMethod)
    setPlans(blob.plans)
    setTrial(blob.trial
      ? { expiry: new Date(blob.trial.expiry), initiated: new Date(blob.trial.initiated) }
      : null
    )
    setInvoices(blob.invoices)
    setUpcomingInvoices(blob.upcomingInvoice || null)
    setNextInvoiceDate(blob.upcomingInvoice?.date ? format(new Date(blob.upcomingInvoice.date), 'do MMM yyyy', { locale: getDateLocale() }) : null)
    setNextInvoiceAmount(blob.upcomingInvoice?.amount ? `${blob.upcomingInvoice.amount / 100}` : null)
  }, [setSubscription, setPaymentMethod, setInvoices, setUpcomingInvoices])

  /**
   * Fetch the user's subscription, paymentmethod, invoice state, and available plans.
   *
   * Because this may happen early in the application lifecycle, we may need to ensure
   * that the auth token is loaded into the cookies to avoid a race condition
   */
  const getSubscription = useCallback(async () => {
    const { data } = await axios({
      method: 'GET',
      url: '/api/self/subscription'
    })
    updateSubscriptionState(data)
  }, [updateSubscriptionState])
  /**
   * Get subscription state on application load
   */
  useEffect(() => {
    if (user?.sub) {
      getSubscription()
    }
  }, [user?.sub, getSubscription])

  /**
   * Consume a voucher_code in exchange for a full lifetime subscription
   */
  const redeemLicenseKey = async (licenseKey: string) => {
    try {
      const { data } = await axios.post('/api/self/license', {
        couponCode: licenseKey,
        email: user?.email
      }, {
        headers: { 'Content-Type': 'application/json' }
      })
      updateSubscriptionState(data)
    } catch (e: any) {
      throw Error(e.response?.data?.message)
    }
  }

  /**
   * Use Stripe CC token to update the user's default payment method
   */
  const updatePaymentDetails = async (token: Token) => {
    try {
      const { data } = await axios.put('/api/self/paymentMethod', {
        stripeToken: token.id,
        email: user?.email
      }, {
        headers: { 'Content-Type': 'application/json' }
      })
      updateSubscriptionState(data)
      return 'Your payment details have been updated'
    } catch (err) {
      throw Error('An error has occured while updating your card payment method.')
    }
  }

  /**
   * Cancel a subscription
   */
  const cancelSubscription = async () => {
    const { data } = await axios.post('/api/self/subscription', {
      email: user?.email,
      productPlan: null
    }, { headers: { 'Content-Type': 'application/json' } })
    updateSubscriptionState(data)
  }

  /**
   * Purchase a new user subscription, either monthly or annual
   */
  const purchaseSubscription = async (token: Token, coupon: string, planId: string) => {
    // Update subscription
    try {
      const { data } = await axios.post('/api/self/subscription', {
        email: user?.email,
        stripeToken: token?.id,
        productPlan: planId,
        userId: user?.sub,
        couponCode: coupon
      }, { headers: { 'Content-Type': 'application/json' } })
      updateSubscriptionState(data)
    } catch (err) {
      if ((err as any)?.response?.data?.error === 'No such coupon') {
        throw Error('Coupon does not exist')
      }
      throw Error('An error has occured while processing your payment')
    }
    return 'Your account has been upgraded successfully.'
  }

  /*
   * Purchase a workspace subscription, adding or removing seats.
   * Also refetches the various subscription state
   */
  const purchaseWorkspaceSubsription = useCallback(async (workspaceId: string, seatAmount: number) => {
    const { data } = await axios({
      method: 'POST',
      url: `/api/workspaces/${workspaceId}/subscription`,
      data: { seatAmount }
    })
    updateSubscriptionState(data)
  }, [updateSubscriptionState])

  /**
   * Try to begin a protrial if the user is eligible
   */
  const beginProTrial = async () => {
    await axios({
      url: '/api/self/trial',
      method: 'POST',
      withCredentials: true
    })
  }

  /**
   * Extend the protrial for a reason, if it hasn't already been used
   */
  const tryExtendTrial = useCallback(async (reason: string) => {
    if (subscription.trial && new Date(subscription?.trial?.expiry) > new Date()) {
      await axios({
        url: '/api/self/trial/extend',
        method: 'POST',
        withCredentials: true,
        data: { extensionReason: reason }
      })
    }
  }, [subscription.trial])

  useEffect(() => {
    const lastSeenUpgradeModal = localStorage.getItem('sizle.lastSeenUpgradeModal')
    const lastSeenOnboardingModal = localStorage.getItem('sizle.lastSeenOnboardingModal')
    /*
     * Show the upgrade modal once the user is logged in and plans are loaded, if the user is one of the solo plans, and has last seen the modal more than a week ago
     */
    if (user) {
      if (
        annualPlan?.id &&
        (subscription?.plan?.planTier as string)?.toLowerCase()?.includes('solo') &&
        (!lastSeenUpgradeModal ||
          addWeeks(new Date(lastSeenUpgradeModal), 1) < new Date()
        )
      ) {
        setModal('upgrade')
      }
      if (
        (!lastSeenOnboardingModal ||
          addMonths(new Date(lastSeenOnboardingModal), 1) < new Date()
        )
      ) {
        setModal('onboarding')
      }
    }
  }, [user, setModal, subscription, annualPlan?.id])

  return (
    <subscriptionContext.Provider
      value={{
        currentSubscriptions: [subscription],
        upcomingInvoices,
        nextInvoiceDate,
        nextInvoiceAmount,
        purchaseSubscription,
        purchaseWorkspaceSubsription,
        updatePaymentDetails,
        redeemLicenseKey,
        invoices,
        tryExtendTrial,
        beginProTrial,
        userEligibleForProTrial: false,
        isProTrial: subscription?.plan?.planId === 'PRO_TRIAL',
        remainingTrialDays: differenceInDays(new Date(subscription?.trials?.[0]?.expiry), new Date(subscription?.trials?.[0]?.started)),
        seatPlan,
        freePlan,
        monthlyPlan,
        annualPlan,
        trialPlan,
        advMonthlyPlan,
        advAnnualPlan,
        soloMonthlyPlan,
        soloAnnualPlan,
        cancelSubscription,
        trial,
        currentPlan: subscription?.plan || freePlan,
        hasPro: (subscription?.plan && !subscription?.plan.planId.includes('FREE') && !subscription?.plan.planId.includes('TRIAL')),
        // License fallback for auth0 users
        hasLicense: subscription?.plan?.planId.startsWith('LICENSE_') ||
          (!subscription?.plan && getUserType(user).hasProSubscription),
        paymentMethod: paymentMethod ? `${paymentMethod.card.brand} (XXXX XXXX XXXXX ${paymentMethod.card.last4})` : null
      }}
    >
      {children}
    </subscriptionContext.Provider>
  )
}
