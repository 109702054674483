import React, { useCallback, useState } from 'react';
import { ProPopup } from 'src/modules/modals/components/ProPopup';
import { useTranslation } from 'react-i18next';

const BurnTimerInput = ({
  label,
  isDisabled,
  value,
  onChange,
  defaultValue = null,
}: {
  label?: string;
  isDisabled?: boolean;
  value?: number;
  onChange?: (burnTimer: number | null) => Promise<void>;
  defaultValue: number | null;
}) => {
  const [time, setTime] = useState(defaultValue || 1);
  const [isChecked, setChecked] = useState(!!defaultValue);
  const { t } = useTranslation();

  const options = [1, 5, 10, 30, 60, 120, 240, 360, 720, 1440, 10080];

  const handleChangeTimer = useCallback((e: React.ChangeEvent<HTMLSelectElement>) => {
    const { value } = e.target;
    setChecked(true);
    setTime(parseInt(value));
    onChange && onChange(parseInt(value));
  }, [onChange, setChecked, setTime]);

  const handleToggle = useCallback((_e: React.MouseEvent<HTMLButtonElement>) => {
    setChecked(c => !c);
    onChange && onChange(!isChecked ? time : null);
  }, [onChange, setChecked, isChecked, time]);

  return (
    <ProPopup requiredFeature='shareTimers'>
      <div className='burn-input-timer'>
      <button
          disabled={isDisabled}
          type='button'
          onClick={handleToggle}
          className={`switch ${isChecked && 'on'}`}
        />
        <div className='input-wrapper'>
          <label htmlFor='burnTimeout' className='label small' title={t('setupPopup.Burn timer tooltip')}>
            {label || t('Burn after')}
          </label>
          <select
            className='input-text burn-input'
            name='burnTimeout'
            value={time}
            disabled={isDisabled ? 'disabled' : undefined}
            onChange={handleChangeTimer}
            style={{
              marginLeft: '1em',
              width: '50%', // Set the width to 50%
              color: 'var(--input-color)',
              border: '1px solid var(--input-border-color)',
              backgroundColor: 'var(--input-background)',
              fontSize: '11px',
              padding: '6px 8px',
              maxWidth: '50px',
              boxShadow: 'none',
            }}
          >
            {options.map(o => (
              <option key={o} value={o}>
                {t(`time.${o}`)}
              </option>
            ))}
          </select>
        </div>
      </div>
    </ProPopup>
  );
};

export default BurnTimerInput;
