import React from 'react'

import { CarouselProvider } from 'pure-react-carousel'
import 'pure-react-carousel/dist/react-carousel.es.css' // @FIXME
import OnboardingSlider from './OnboardingSlider'

const OnboardingModal = ({ dismissModal }) => {
  const onClose = async () => {
    dismissModal()
    localStorage.setItem('sizle.lastSeenOnboardingModal', new Date())
  }

  return (
    <div className='popup-container fullscreen dark' >
      <div className='popup modal'>
        <div className='popup-content'>
          <button className='popup-close'/>
          <CarouselProvider
            naturalSlideWidth={530}
            naturalSlideHeight={550}
            totalSlides={2}
          >
            <OnboardingSlider dismissModal={onClose} />
          </CarouselProvider>
        </div>
      </div>
    </div>
  )
}

export default OnboardingModal
