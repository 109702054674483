import React, { useCallback, useEffect, useState, useContext } from 'react'
import axios from 'axios'
import { useLazyQuery, useMutation } from '@apollo/client'
import { useModal } from 'src/modules/modals'

import {
  GET_PRESENTATION,
  UPDATE_PRESENTATION_TITLE
} from 'src/graphql/presentations'
import { workspaceContext } from 'src/context/WorkspaceContext'
import useUserContext from 'src/context/UserContext'

export const presentationContext = React.createContext({
  presentation: { updated_at: '', title: '', presentationId: '' },
  savePresentationTitle: async (_presentationId) => {},
  setPresentationId: async (_) => {},
  clonePresentation: async (_presentationId) => {}
})

export const PresentationProvider = ({ children }) => {
  const [presentationId, setPresentationId] = useState(null)
  const { user } = useUserContext()
  const [updatePresentationTitleMtn] = useMutation(UPDATE_PRESENTATION_TITLE, { refetchQueries: [{ query: GET_PRESENTATION, variables: { presentationId } }] })
  const [getPresentation, { called, loading: loadingPresentation, data: presentationsQueryData }] = useLazyQuery(GET_PRESENTATION)
  useEffect(() => {
    if (presentationId) {
      getPresentation({
        variables: { presentationId }
      })
    }
  }, [presentationId])

  const [presentationTitle, setPresentationTitle] = useState(presentationsQueryData?.title || '')
  const { showStorageFilledPrompt, refetchWorkspaces } = useContext(workspaceContext)
  const { setModal } = useModal()

  useEffect(() => {
    if (presentationsQueryData?.presentations?.[0]) {
      setPresentationTitle(presentationsQueryData.presentations[0].title)
    }
  }, [presentationsQueryData])

  const savePresentationTitle = async (title) => {
    await updatePresentationTitleMtn({
      variables: {
        userId: user.sub,
        presentationId,
        title
      }
    })
    setPresentationTitle(title)
    await refetchWorkspaces()
  }

  const clonePresentation = async (presentationId) => {
    setModal('create_document')
    const result = await axios({
      method: 'POST',
      url: `/api/documents/${presentationId}/copy`,
      withCredentials: true,
      validateStatus: (r) => r.toString()[0] !== '5' // 5xx responses should throw, 4xx should not

    })
    if (result.status === 200) {
      setModal('')
      await refetchWorkspaces()
    } else if (result.data.error === 'Document limit exceeded') {
      showStorageFilledPrompt()
    }
  }

  const updatePresentationFile = useCallback(async (documentId, document) => {
    const formData = new FormData()
    formData.append('document', document)
    await axios({
      url: `/api/documents/${documentId}/file`,
      method: 'POST',
      data: formData,
      withCredentials: true
    })
    await refetchWorkspaces()
  }, [refetchWorkspaces])

  return (
    <presentationContext.Provider
      value={{
        presentation: presentationsQueryData?.presentations && presentationsQueryData.presentations[0],
        presentationId,
        loading: loadingPresentation,
        called,
        savePresentationTitle,
        presentationTitle,
        clonePresentation,
        setPresentationId,
        updatePresentationFile
      }}
    >
      {children}
    </presentationContext.Provider>
  )
}
