import React, { useContext, useState } from 'react'
import { toastr } from 'react-redux-toastr'
import { workspaceContext } from 'src/context/WorkspaceContext.jsx'
import { ProPopup } from 'src/modules/modals/components/ProPopup'

export const CreateCustomDomainForm = () => {
  const { addCustomDomain } = useContext(workspaceContext)
  const [inputExpanded, expandInput] = useState(false)
  const [domainCreating, setDomainCreating] = useState(false)
  const submitDomain = async (e: React.FormEvent<HTMLFormElement>) => {
    const form = e.currentTarget
    e.preventDefault()
    e.stopPropagation()
    setDomainCreating(true)
    try {
      await addCustomDomain((form.elements.namedItem('domain') as HTMLInputElement).value)
      form.reset()
    } catch (e) {
      if ((e as any).message === 'Domain in use') {
        toastr.error('This domain is already in use. Please ensure you have not already set-up a white label for this domain.')
      }
    }
    setDomainCreating(false)
  }
  const resetForm = () => expandInput(false)

  return (
    <form className='create-custom-domain' action='' onReset={resetForm} onSubmit={submitDomain}>
      <div className={`expanding-input ${inputExpanded ? 'expanded' : ''}`}>
        <input title='Domain to access the site on, ie. "documents.mycompany.com"' className='input-text' required pattern='^(\*\.)?(((?!-)[A-Za-z0-9-]{0,62}[A-Za-z0-9])\.)+((?!-)[A-Za-z0-9-]{1,62}[A-Za-z0-9])$' placeholder='www.example.com' name='domain' />
        <div className='closed-buttons'>
          <ProPopup requiredFeature='customDomains' title='You discovered a pro feature!' subtitle='This feature requires a Sizle Pro subscription. View plans below'>
            <button name='toggle' type='button' className='btn primary' onClick={() => expandInput(true)}>Add a URL</button>
          </ProPopup>
        </div>
        <div className='expanded-buttons'>
          <button type='submit' className={`btn primary ${domainCreating ? 'loading-spinner' : ''}`}>Save Domain</button>
          <button type='reset' className='btn'>Cancel</button>
        </div>
      </div>
    </form>
)
}
