import React, { useContext, useState, useEffect, useMemo } from 'react'
import { toastr } from 'react-redux-toastr'
import { workspaceContext, useUserContext } from 'src/context'
import { CreatableSelect } from 'src/design-system/elements'

export const Extension = ({ extensions }) => {
  const { workspace, updateLinkExtensions } = useContext(workspaceContext)
  const { user } = useUserContext()
  const link = workspace.workspace_invitation_link

  const [loading, setLoading] = useState(false)
  const [value, setValue] = useState(extensions.map(extension => ({ value: extension, label: extension })) || [])

  useEffect(() => {
    if (extensions.length !== value.length) {
      setValue(extensions.map(extension => ({ value: extension, label: extension })))
      setLoading(false)
    }
  }, [extensions, value])

  const handleChange = async (newValue) => {
    setLoading(true)
    try {
      await updateLinkExtensions(link.invitation_link_id, newValue.map(item => item.value))
    } catch (error) {
      toastr.error('There was an error. Please try later')
      setLoading(false)
    }
  }

  const handleCreate = (inputValue) => {
    if (inputValue.match(/(^[a-zA-Z]+)(\.)([a-zA-Z]+$)/)) {
      const newValue = [...value]
      newValue.push({ lable: inputValue, value: inputValue })
      handleChange(newValue)
    } else {
      toastr.error('Email domain is invalid')
    }
  }

  const customStyles = {
    container: (provided) => ({
      ...provided,
      marginBottom: '4px',
      marginLeft: '8px',

      '@media only screen and (max-width: 1179px)': {
        marginLeft: 0,
        marginTop: '8px',
        width: '440px'
      },
      '@media only screen and (max-width: 699px)': {
        width: '100%'
      }
    })
  }

  const options = useMemo(() => {
    const res = [
    ]
    const userDomain = user.email.split('@').pop()
    const existingIndex = res.findIndex(option => option.value === userDomain)

    if (existingIndex >= 0) res.splice(existingIndex, 1)
    res.unshift({ value: userDomain, label: userDomain })
    return res
  }, [user])

  return (
    <div className='create-select-container'>
      <CreatableSelect
        value={value}
        styles={customStyles}
        isMulti
        onChange={handleChange}
        options={options}
        isDisabled={loading}
        isLoading={loading}
        placeholder='Allowed email domains'
        onCreateOption={handleCreate}
      />
    </div>
  )
}

export default Extension
