import gql from 'graphql-tag'

export const GET_ORGANISATION_QUERY = gql`
  query getOrganisation($workspaceId: uuid!, $orgId: uuid!) {
    organisations(
      where: {
        workspaceId: {_eq: $workspaceId},
        orgId: {_eq: $orgId},
        deleted_at: {_is_null: true}
      }
    ) {
      name
      id: orgId
      recipients {
        id: recipientId
        name: recipientName
        email: recipientEmail
        contact: recipientContact
        updated_at
      }
      updated_at
    }
  }
`

export const GET_ORGANISATIONS_QUERY = gql`
  query getOrganisations($workspaceId: uuid!) {
    organisations(
      where: {
        workspaceId: {_eq: $workspaceId},
        deleted_at: {_is_null: true}
      }
    ) {
      name
      id: orgId
      recipients {
        id: recipientId
        name: recipientName
        email: recipientEmail
        contact: recipientContact
        updated_at
      }
      updated_at
    }
    recipients(
      where: {
        workspaceId: {_eq: $workspaceId},
        orgId: {_is_null: true},
        deleted_at: {_is_null: true}
      }
    ) {
      id: recipientId
      name: recipientName
      email: recipientEmail
      contact: recipientContact
      updated_at
    }
  }
`

export const GET_RECIPIENTS_QUERY = gql`
  query getRecipients($workspaceId: uuid!) {
    recipients(
      where: {
        workspaceId: {_eq: $workspaceId},
        deleted_at: {_is_null: true}
      },
      order_by: {updated_at: asc}
    ) {
      id: recipientId
      name: recipientName
      contact: recipientContact
      email: recipientEmail
      updated_at,
      orgId
    }
    organisations(
      where: {
        workspaceId: {_eq: $workspaceId},
        deleted_at: {_is_null: true}
      }
    ) {
      name
      orgId
      updated_at
    }
  }
`

export const DELETE_COMPANIES = gql`
  mutation update_organisations(
    $orgIds: [uuid!]!,
  ) {
    update_organisations(
      where: {
        orgId: { _in: $orgIds }
      },
      _set: {
        deleted_at: "now()"
      }
    ) {
      returning {
        orgId
        name
      }
    }
  }
`

export const UPDATE_COMPANY = gql`
  mutation update_organisations(
    $orgId: uuid!,
    $orgName: String!,
  ) {
    update_organisations(
      where: {
        orgId: { _eq: $orgId }
      },
      _set: {
        name: $orgName
      }
    ) {
      returning {
        orgId
        name
        updated_at
      }
    }
  }
`

export const INSERT_COMPANY = gql`
  mutation insert_organisations(
    $workspaceId: uuid!,
    $orgName: String!
  ) {
    insert_organisations(objects: {
      name: $orgName,
      workspaceId: $workspaceId
    }) {
      returning {
        orgId
        name
        workspaceId
        updated_at
      }
    }
  }
`
