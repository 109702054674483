import gql from 'graphql-tag'

export const NOTIFICATIONS_QUERY = gql`
  subscription getNotifications($userId: String!) {
    notifications(where: {userId: {_eq: $userId}}, order_by: {created_at: desc}) {
      notificationId
      details
      isRead
      updated_at
      created_at
    }    
  }
`

export const NOTIFICATION_READ_UPDATE = gql`
  mutation update_notifications(
    $userId: String!,
    $notificationId: uuid!,
  ) {
    update_notifications(
      where: {
        notificationId: { _eq: $notificationId },
        userId: { _eq: $userId }
      },
      _set: {
        isRead: "true"
      }
    ) {
      returning {
        notificationId
      }
    }
  }
`

export const NOTIFICATION_READ_ALL_UPDATE = gql`
  mutation update_all_notifications_as_read(
    $userId: String!,
  ) {
    update_notifications(
      where: {
        userId: { _eq: $userId },
        isRead: { _eq: false }
      },
      _set: {
        isRead: "true"
      }
    ) {
      returning {
        notificationId
      }
    }
  }
`
