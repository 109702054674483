import { CSSReset, ThemeProvider as ChakraThemeProvider } from '@chakra-ui/core'
import { ThemeProvider } from '@emotion/react'
import axios from 'axios'
import THEME from './utils/theme'
import { AuthProvider, UserManager } from 'oidc-react'
import React, { Suspense, useContext, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { AuthApolloProvider, ModeContext } from './context'
import SizleLoader from './modules/controls/components/SizleLoader'
import ErrorBoundary from './modules/error/ErrorBoundary'
import { MaintenancePage } from './pages/maintenance/MaintenancePage'
import AppRouter from './router'
import './styles/main.scss'
import Cookies from 'universal-cookie'

const isAWSDebugMode = import.meta.env.VITE_AWS_DEBUG
if (isAWSDebugMode === 'true') {
  window.LOG_LEVEL = 'DEBUG'
}

const iamHostname = import.meta.env.VITE_IAM_HOSTNAME
const userManager = new UserManager({
  revokeAccessTokenOnSignout: true,
  client_id: window.location.hostname,
  authority: `https://${iamHostname}/realms/Sizle`,
  automaticSilentRenew: true,
  redirect_uri: window.location.origin,
  scope: 'openid profile email phone offline_access',
  post_logout_redirect_uri: window.location.origin
})
userManager.events.addUserLoaded(async () => {
  const cookies = new Cookies()
  const user = await userManager.getUser()
  cookies.set('sizle-auth0-cookie', user.access_token, {
    secure: true,
    sameSite: 'strict',
    expires: new Date(user.expires_at * 1000),
    path: '/'
  })
})
axios.interceptors.request.use(async (config) => {
  const reqUrl = new URL(config.url, window.location.origin)
  if (reqUrl.hostname === window.location.hostname) {
    try {
      config.headers.Authorization = `Bearer ${(await userManager.getUser()).access_token}`
    } catch (e) {
      // A fail here is expected- if not logged int yet, there will be no access token
    }
  }
  return config
})

export const App = () => {
  const { mode } = useContext(ModeContext)
  const { pathname } = useLocation()

  useEffect(() => {
    const rootElm = document.getElementById('root')
    rootElm.style.scrollbarColor = 'var(--scrollbar-handle) var(--scrollbar-background)'
    rootElm.style.scrollbarWidth = 'thin'

    if (mode === 'dark') {
      rootElm.classList.remove('light')
      rootElm.classList.add('dark')
      document.querySelector(':root').classList.add('dark')
    } else {
      rootElm.classList.remove('dark')
      rootElm.classList.add('light')
      document.querySelector(':root').classList.remove('dark')
    }
  }, [mode])

  if (import.meta.env.VITE_MAINTENANCE_MODE) { return <MaintenancePage /> }

  return (
    <AuthProvider autoSignIn={!pathname.startsWith('/p')} userManager={userManager}>
      <AuthApolloProvider userManager={userManager}>
        <ThemeProvider theme={THEME}>
          <ChakraThemeProvider theme={THEME}>
            <CSSReset />
            <ErrorBoundary>
              <Suspense fallback={<SizleLoader />}>
                <AppRouter />
              </Suspense>
            </ErrorBoundary>
          </ChakraThemeProvider>
        </ThemeProvider>
      </AuthApolloProvider>
    </AuthProvider>
  )
}

export default App
