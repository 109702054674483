import gql from 'graphql-tag'

export const GET_EVENTS = gql`
  query getEvents($presentationId: String!) {
    events(
      where: {presentation: {_eq: $presentationId}}
      order_by: { timestamp: asc },
    ) {
      id, user, event, meta, timestamp, sessionId
    }
  }
`
export const SYNC_EVENTS = gql`
  mutation syncEvents($events: [events_insert_input!]!) {
    insert_events(objects: $events) {
      affected_rows
    }
  }
`
