import React, { useState, useContext, useCallback, useEffect, HTMLProps } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAnalytics } from 'use-analytics'
import { useTranslation } from 'react-i18next'
import { format, parseISO } from 'date-fns'
import { toastr } from 'react-redux-toastr'
import { getDateLocale } from 'src/locales/i18n'
import { organisationContext, workspaceContext, useUserContext } from 'src/context'
import { FiPlus, FiEdit2 } from 'react-icons/fi'
import { CompanyEditModal } from 'src/modules/recipients'
import { ColumnDef, flexRender, getCoreRowModel, useReactTable, SortingState, getSortedRowModel, getFilteredRowModel } from '@tanstack/react-table'

interface Company {
  name: string,
  orgId: string,
  updated_at: Date | string,
}

const CompaniesPage = () => {
  const navigate = useNavigate()
  const { user, workspaceIndex, sendAmplitudeData } = useUserContext()
  const [toggleCleared, setToggleCleared] = useState(false)
  const [isAddingCompany, setAddingCompany] = useState(false)
  const { page } = useAnalytics()
  const { t } = useTranslation()
  const { deleteOrganisations, recipients, organisations } = useContext(organisationContext)
  const { hasWorkspacePermission } = useContext(workspaceContext)
  const [sorting, setSorting] = React.useState<SortingState>([])
  const [globalFilter, setGlobalFilter] = React.useState('')

  const handleEdit = useCallback(item => {
    navigate(`/s/${workspaceIndex}/company/${item?.row?.original?.orgId}`)
  }, [navigate, workspaceIndex])

  const exportCSV = () => {
    const rows = [
      ['Name', 'ID', 'Last Updated'],
      ...table.getSelectedRowModel().flatRows.map(({original: o}) => Object.values(o))
    ]

    const csvContent = "data:text/csv;charset=utf-8,"
      + rows.map(e => e.join(";")).join("\n");
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "sizle-companies.csv");
    document.body.appendChild(link);
    link.click();
  }

  const handleDelete = async () => {
    const selectedRows = table.getSelectedRowModel().rows.map(x => x.original) as Company[]
    const companyList = selectedRows.map(r => r.name)
    const companyPluralizedText = companyList.length > 1 ? 'these companies' : 'this company'
    if (window.confirm(t('Are you sure you want to remove', { text: `${companyPluralizedText}:\r ${companyList.join(', ')}` }))) {
      setToggleCleared(!toggleCleared)
      const selectedIds = selectedRows.map(r => r.orgId)
      try {
        await deleteOrganisations(selectedIds)
        table.resetRowSelection()
        toastr.info('Companies removed 👍')
      } catch (e) {
        console.error('Failed to delete companies', e)
        toastr.error('Unable to delete companies, please contact support!')
      }
    }
  }

  const onClickAddNewCompany = useCallback(() => {
    setAddingCompany(true)
  }, [])

  useEffect(() => {
    page()
    sendAmplitudeData('PAGE_VIEW_COMPANIES')
    setGlobalFilter('')
  }, [])

  // Table Definition
  const formatDate = (d: Date | undefined) => d ? format(parseISO(d), 'h:mma do MMM yyyy', { locale: getDateLocale() }) : ''
  const columns: ColumnDef<Company>[] = [
    {
      id: 'select',
      header: ({ table }) => (
        <IndeterminateCheckbox
          {...{
            checked: table.getIsAllRowsSelected(),
            indeterminate: table.getIsSomeRowsSelected(),
            onChange: table.getToggleAllRowsSelectedHandler()
          }}
        />
      ),
      cell: ({ row }) => (
        <div>
          <IndeterminateCheckbox
            {...{
              checked: row.getIsSelected(),
              indeterminate: row.getIsSomeSelected(),
              onChange: row.getToggleSelectedHandler()
            }}
          />
        </div>
      )
    },
    { header: 'Name', accessorKey: 'name' },
    {
      header: 'Recipients',
      accessorKey: 'orgId',
      cell: i => recipients.filter(r => r.orgId === i.getValue()).length
    },
    {
      header: 'Last Updated',
      accessorKey: 'updated_at',
      cell: i => formatDate(i.getValue())
    },
    {
      id: 'actions',
      header: () => '',
      cell: (cell) => <FiEdit2 className='action' onClick={() => handleEdit(cell)} stroke='var(--primary-color)' />
    }
  ]
  const table = useReactTable({
    data: organisations,
    columns,
    getFilteredRowModel: getFilteredRowModel(),
    state: { sorting, globalFilter },
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    getCoreRowModel: getCoreRowModel()
  })

  return (
    <div className='page company-page'>
      <div className='page-section-header row'>
        <div className='col-4'><h2>Companies</h2></div>
        <div className='col-8 right flex-right'>
          <input type='search' className='input-text' placeholder='Search Companies' value={globalFilter ?? ''} onChange={e => setGlobalFilter(String(e.target.value))} />
          <button className="btn primary sm" hidden={!hasWorkspacePermission('Manage Contacts')} onClick={() => onClickAddNewCompany()}>
            <FiPlus /> Create Company
          </button>
        </div>
      </div>
      <div className='page-section'>
        <table className='table'>
          <thead>
            {table.getHeaderGroups().map(headerGroup => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map(header => (
                  <th key={header.id} colSpan={header.colSpan}>
                    {flexRender(header.column.columnDef.header, header.getContext())}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map(row => (
              <tr key={row.id}>
                {row.getVisibleCells().map(cell => (
                  <td key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
        <div className='pagination row'>
          <div className='col-4 col-12-sm'>
            <button className='btn danger' onClick={handleDelete} disabled={!table.getIsSomeRowsSelected() && !table.getIsAllPageRowsSelected()}>Delete</button>
            <button className='btn' onClick={exportCSV} disabled={!table.getIsSomeRowsSelected() && !table.getIsAllPageRowsSelected()}>Export CSV</button>
          </div>
          <div className='col-4 col-12-sm'>
            <button className='btn' onClick={() => table.setPageIndex(0)} disabled={!table.getCanPreviousPage()}>{'<<'}</button>
            <button className='btn' onClick={() => table.previousPage()} disabled={!table.getCanPreviousPage()}>{'<'}</button>
            <button className='btn' onClick={() => table.nextPage()} disabled={!table.getCanNextPage()}>{'>'}</button>
            <button className='btn' onClick={() => table.setPageIndex(table.getPageCount() - 1)} disabled={!table.getCanNextPage()}>{'>>'}</button>
          </div>
          <div className='col-4 col-12-sm'>
            <span className='flex items-center gap-1'>
              <span>Page </span>
              <strong>
                {table.getState().pagination.pageIndex + 1} of{' '}
                {table.getPageCount()}
              </strong>
            </span>
            <select value={table.getState().pagination.pageSize} onChange={e => { table.setPageSize(Number(e.target.value)) }}>
              {[10, 20, 30, 40, 50].map(pageSize => (
                <option key={pageSize} value={pageSize}>
                  Show {pageSize}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>

      {isAddingCompany && (
        <div className='popup-container fullscreen'>
          <div className='popup modal'>
            <div className='popup-content'>
              <button className='popup-close'/>
              <CompanyEditModal
                isAddingCompany={isAddingCompany}
                user={user}
                dismissModal={() => { setAddingCompany(false) }}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

function IndeterminateCheckbox ({
  indeterminate, className = '', ...rest
}: { indeterminate?: boolean } & HTMLProps<HTMLInputElement>) {
  const ref = React.useRef<HTMLInputElement>(null!)

  React.useEffect(() => {
    if (typeof indeterminate === 'boolean') {
      ref.current.indeterminate = !rest.checked && indeterminate
    }
  }, [ref, indeterminate])

  return (
    <input type='checkbox' ref={ref} className={className + ' cursor-pointer'} {...rest} />
  )
}

export default CompaniesPage
