import { useCallback } from 'react'
import axios from 'axios'

export const useReactions = (leadId: string) => {
  return {
    addReaction: useCallback(async (reaction) => {
      await axios(`/api/leads/${leadId}/reaction`, {
        data: JSON.stringify({ reaction }),
        method: 'POST',
        headers: { 'Content-Type': 'application/json' }
      })
    }, [leadId])
  }
}
