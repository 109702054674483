import { useAuth } from 'oidc-react'
import React, { ReactNode, useEffect } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { LocationProvider, ParamProvider, PresentationProvider, SubscriptionProvider, UserProvider, WorkspaceProvider } from 'src/context'
import BlankLayout from 'src/layouts/BlankLayout'
import EditorLayout from 'src/layouts/EditorLayout'
import MainLayout from 'src/layouts/MainLayout'
import ViewerLayout from 'src/layouts/ViewerLayout'
import AccountPage from 'src/pages/account/AccountPage'
import AnalyticsPage from 'src/pages/analytics/AnalyticsPage'
import BillingPage from 'src/pages/billing/BillingPage'
import EditorPage from 'src/pages/editor/EditorPage'
import Error404Page from 'src/pages/others/Error404Page'
import ExpiredLinkPage from 'src/pages/others/ExpiredLinkPage'
import PresentationsPage from 'src/pages/presentations/PresentationsPage'
import CompaniesPage from 'src/pages/recipients/CompaniesPage'
import CompanyPage from 'src/pages/recipients/CompanyPage'
import RecipientsPage from 'src/pages/recipients/RecipientsPage'
import { SlugPage } from 'src/pages/SlugPage'
import ViewerPage from 'src/pages/viewer/ViewerPage'
import InvitationLinkPage from 'src/pages/workspace/InvitationLinkPage'
import WorkspaceSettingsPage from 'src/pages/workspace/WorkspaceSettingsPage'
import LandingPage from './pages/LandingPage'

// Lazy Pages
// const WorkspaceInvitationPage = React.lazy(() => import('src/pages/workspace/WorkspaceInvitationPage'))

const ProtectedRoute = ({ Layout = MainLayout, children }: {children: ReactNode | ReactNode[], Layout?: any}) => {
  return (
    <ParamProvider>
      <Layout>{children}</Layout>
    </ParamProvider>
  )
}

const Auth = ProtectedRoute

const Public = ({ children, Layout = BlankLayout }: {children: ReactNode | ReactNode[], Layout?: any}) => {
  return <UserProvider><Layout>{children}</Layout></UserProvider>
}

const AppRouter = () => {
  const unauthRouteProps = { layout: BlankLayout }
  return (
    <UserProvider>
      <SubscriptionProvider>
        <WorkspaceProvider>
          <PresentationProvider>
            <Routes>
              <Route path='/' element={<Auth><LandingPage /></Auth>} />
              <Route path='/presentations' element={<Auth><Navigate to='/s/0/presentations' replace /></Auth>} />
              <Route path='/billing' element={<Auth><BillingPage /></Auth>} />
              <Route path='/s'>
                <Route path=':workspaceIdentifier'>
                  <Route path='presentations' element={<Auth><PresentationsPage /></Auth>} />
                  <Route path='presentation'>
                    <Route path=':presentationId'>
                      <Route path='analytics' element={<Auth><AnalyticsPage /></Auth>} />
                      <Route path='edit' element={<Auth Layout={ViewerLayout}><EditorPage /></Auth>} />
                    </Route>
                  </Route>
                  <Route path='recipients' element={<Auth><RecipientsPage /></Auth>} />
                  <Route path='companies' element={<Auth><CompaniesPage /></Auth>} />
                  <Route path='company/:id' element={<Auth><CompanyPage /></Auth>} />
                  <Route path='account' element={<Auth><AccountPage /></Auth>} />
                  <Route path='settings' element={<Auth><WorkspaceSettingsPage /></Auth>} />
                  <Route path='billing' element={<Auth><BillingPage /></Auth>} />
                  <Route />
                </Route>
              </Route>
              <Route path='/document/:slug' element={<Auth><SlugPage /></Auth>} />
              <Route path='/join-workspace' element={<Auth><LandingPage /></Auth>} />
              <Route path='/invite/:invitationLinkId' element={<Auth Layout={BlankLayout}><InvitationLinkPage /></Auth>} />
              <Route path='/p/:linkId' element={<Public Layout={ViewerLayout}><LocationProvider><ViewerPage /></LocationProvider></Public>} />
              <Route path='/expired-link/:linkId' element={<Public {...unauthRouteProps}><ExpiredLinkPage /></Public>} />
              <Route path='*' element={<Public {...unauthRouteProps}><Error404Page /></Public>} />
            </Routes>
          </PresentationProvider>
        </WorkspaceProvider>
      </SubscriptionProvider>
    </UserProvider>
  )
}

export default AppRouter
