import gql from 'graphql-tag'

export const DELETE_QUERY = gql`
  mutation deleteFolder($folderId: Int!) {
    update_folders(
      where: {folderId: {_eq: $folderId}},
      _set: {deleted_at: "now()"}
    ) {
      returning {
        folderId
      }
    }
  }
`

export const DUPLICATE_QUERY = gql`
  mutation($parentId: Int, $folderName: String!, $icon: String, $workspaceId: uuid!) {
    insert_folders(objects: {folderName: $folderName, icon: $icon, parentId: $parentId, workspaceId: $workspaceId}) {
      returning {
        folderId
        folderName
        icon
      }
    }
  }
`

export const RENAME_QUERY = gql`
  mutation($folderId: Int!, $folderName: String!) {
    update_folders(where: {folderId: {_eq: $folderId}}, _set: {folderName: $folderName}) {
      returning {
        folderId
        folderName
      }
    }
  }
`

export const MOVE_FOLDER_QUERY = gql`
  mutation($folderId: Int!, $newParent: Int!) {
    update_folders(
      where: {
        folderId: {_eq: $folderId}
      },
      _set: {
        parentId: $newParent
      }
    ) {
      returning {
        folderId
        folderName
      }
    }
  }
`

export const CREATE_FOLDER_QUERY = gql`
  mutation($parentId: Int, $folderName: String!, $workspaceId: uuid!, $isOriginal: Boolean) {
    insert_folders(
      objects: {
        folderName: $folderName,
        parentId: $parentId,
        workspaceId: $workspaceId,
        isOriginal: $isOriginal
      }
    ) {
      returning {
        folderId
        folderName
      }
    }
  }
`

export const GET_FOLDERS_QUERY = gql`
  query getWorkspaceFolders($workspaceId: uuid!) {
    folders(
      where: {
        workspaceId: {_eq: $workspaceId},
        parentId: {_is_null: true}
      },
      order_by: {created_at: asc}
    ) {
      label: folderName
      key: folderId
      isOriginal
      folderId
      icon
      nodes {
        label: folderName
        key: folderId
        folderId
        icon
        nodes {
          label: folderName
          key: folderId
          folderId
          icon
          nodes {
            label: folderName
            key: folderId
            folderId
            icon
            nodes {
              label: folderName
              key: folderId
              folderId
              icon
              nodes {
                label: folderName
                key: folderId
                folderId
                icon
                nodes {
                  label: folderName
                  key: folderId
                  folderId
                  icon
                  nodes {
                    label: folderName
                    key: folderId
                    folderId
                    icon
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
