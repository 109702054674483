import React, { useState, useEffect, useCallback, useContext, useMemo } from 'react';
import { ProPopup } from 'src/modules/modals/components/ProPopup';
import { Helmet } from 'react-helmet';
import { useLocation, useNavigate } from 'react-router-dom';
import SVGInline from 'react-svg-inline';
import { format } from 'date-fns';
import { toastr } from 'react-redux-toastr';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { presentationContext, workspaceContext } from 'src/context';
import { clipboard } from 'src/design-system/icons';
import { useModal } from 'src/modules/modals';
import { useResponses } from 'src/modules/responses/hooks/useResponses';
import Chart from 'src/modules/analytics/delivery-item/Chart';
import useLeads from 'src/modules/analytics/hooks/useLeads';
import 'src/styles/pages/analytics-page.scss';

const AnalyticsPage = () => {
  const { presentation, savePresentationTitle } = useContext(presentationContext);
  const { workspaceIndex } = useContext(workspaceContext);
  const { setModal } = useModal();
  const { updateLink, sampleMode, publicLinks, uniqueLinks, emailLinks } = useLeads(presentation?.presentationId, presentation?.title === 'Welcome to Sizle!');
  const [requiresEmailCapture, setRequiresEmailCapture] = useState(false);

  const { search } = useLocation();
  const navigate = useNavigate();

  const getBrowserInfo = () => {
    const ua = navigator.userAgent;
    let name = 'Unknown';
    let os = 'Unknown';
    let platform = 'Unknown';

    if (/windows phone/i.test(ua)) {
      os = 'Windows Phone';
    } else if (/win/i.test(ua)) {
      os = 'Windows';
    } else if (/android/i.test(ua)) {
      os = 'Android';
    } else if (/linux/i.test(ua)) {
      os = 'Linux';
    } else if (/iPhone|iPad|iPod/i.test(ua)) {
      os = 'iOS';
    } else if (/mac/i.test(ua)) {
      os = 'Mac OS';
    }

    if (/mobile/i.test(ua)) {
      platform = 'Mobile';
    } else if (/tablet/i.test(ua)) {
      platform = 'Tablet';
    } else {
      platform = 'Desktop';
    }

    if (/chrome/i.test(ua)) {
      name = 'Chrome';
    } else if (/safari/i.test(ua)) {
      name = 'Safari';
    } else if (/firefox/i.test(ua)) {
      name = 'Firefox';
    } else if (/msie|trident/i.test(ua)) {
      name = 'Internet Explorer';
    } else if (/edge/i.test(ua)) {
      name = 'Edge';
    }

    return { name, os, platform };
  };

  const [selectedFilter, selectedLinkId, selectedLeadId] = useMemo(() => {
    const params = new URLSearchParams(search);
    let [filter, selectedLinkId, selectedLeadId] = [
      params.get('filter'),
      params.get('link_id'),
      params.get('lead_id'),
    ];
    if (!filter) {
      filter = publicLinks.length > 0 ? 'public' : uniqueLinks.length > 0 ? 'unique' : emailLinks.length > 0 ? 'email' : 'public';
    }
    const active =
      filter === 'public' ? publicLinks : filter === 'unique' ? uniqueLinks : emailLinks;
    if (!active.find((l) => l.link_id === selectedLinkId)) {
      selectedLinkId = null;
    }
    if (!active.find((l) => l.leads.find((le) => le.lead_id === selectedLeadId))) {
      selectedLeadId = null;
    }
    if (!selectedLinkId && !selectedLeadId) {
      const link = active.find((l) => l.leads.length > 0) || active[0];
      if (link) {
        selectedLinkId = link.link_id;
        if (link.leads[0]) {
          selectedLeadId = link.leads[0].lead_id;
        }
      }
    }
    return [filter, selectedLinkId, selectedLeadId];
  }, [search, emailLinks, uniqueLinks, publicLinks]);

  const selectFilter = useCallback(
    (filter) => {
      const active =
        filter === 'public' ? publicLinks : filter === 'unique' ? uniqueLinks : emailLinks;
      let [newSelectedLinkId, newSelectedLeadId] = [selectedLinkId, selectedLeadId];

      const link = active.find((l) => l.leads.length > 0);
      if (link) {
        newSelectedLinkId = link.link_id;
        newSelectedLeadId = link.leads[0].lead_id;
      }
      const params = new URLSearchParams(search);
      params.set('filter', filter);
      if (newSelectedLinkId) params.set('link_id', newSelectedLinkId);
      else params.delete('link_id');
      if (newSelectedLeadId) params.set('lead_id', newSelectedLeadId);
      else params.delete('lead_id');
      navigate(`?${params.toString()}`);
    },
    [search, navigate, selectedLinkId, selectedLeadId]
  );

  const [tmpPin, setTmpPin] = useState('');
  const [tmpExpDate, setTmpExpDate] = useState(null);
  const [tmpBurnTimer, setTmpBurnTimer] = useState(null);
  const [tmpCommentsEnabled, setTmpCommentsEnabled] = useState(null);
  const [tmpReactionEnabled, setTmpReactionEnabled] = useState(null);
  const [tmpDownloadEnabled, setTmpDownloadEnabled] = useState(null);
  const [tmpTitle, setTmpTitle] = useState(null);

  useEffect(() => {
    setTmpTitle(presentation?.title);
  }, [presentation]);

  const { locations, selectedLink, selectedLead, opens } = useMemo(() => {
    const selectedLead = [...uniqueLinks, ...publicLinks, ...emailLinks]
      .reduce((carry, cur) => {
        return [...carry, ...cur.leads];
      }, [])
      .find((l) => l.lead_id === selectedLeadId);

    const selectedLink = [...uniqueLinks, ...publicLinks, ...emailLinks].find(
      (l) => l.link_id === selectedLinkId
    );

    if (selectedLink) {
      setTmpPin(selectedLink.pin);
      setTmpExpDate(selectedLink.expiration_date ? new Date(selectedLink.expiration_date) : null);
      setTmpBurnTimer(selectedLink.burn_timer);
      setTmpCommentsEnabled(selectedLink.comments_enabled);
      setTmpDownloadEnabled(selectedLink.downloads_enabled);
      setTmpReactionEnabled(selectedLink.reactions_enabled);
    }

    return {
      locations: (selectedLead?.raw_activity || [])
        .filter(
          (ra) => (ra.ipAddress && ra.ipAddress !== 'N/A') || (ra.location && ra.location !== 'N/A')
        )
        .map((ra) => ({ location: ra.location, ipAddress: ra.ipAddress })),
      selectedLead,
      selectedLink,
      opens: (selectedLead?.raw_activity || []).map((event) => {
        const browser = getBrowserInfo();
        return {
          browser: browser.name || 'Unknown',
          os: browser.os || 'Unknown',
          platform: browser.platform || 'Unknown',
          time: format(new Date(event.timeStamp), 'yyyy-MM-dd HH:mm:ss'),
        };
      }),
    };
  }, [selectedLeadId, selectedLinkId, uniqueLinks, publicLinks, emailLinks]);

  const [submittingLinkUpdate, setSubmittingLinkUpdate] = useState(false);
  const updateLinkConfig = useCallback(
    async (e) => {
      setSubmittingLinkUpdate(true);
      e.preventDefault();
      e.stopPropagation();
      if (selectedLinkId) {
        await updateLink(selectedLinkId, {
          pin: tmpPin,
          burnTimer: tmpBurnTimer,
          expirationDate: tmpExpDate,
          commentsEnabled: tmpCommentsEnabled,
          reactionsEnabled: tmpReactionEnabled,
          downloadsEnabled: tmpDownloadEnabled,
        });
      }
      setSubmittingLinkUpdate(false);
    },
    [tmpBurnTimer, tmpExpDate, tmpDownloadEnabled, tmpCommentsEnabled, tmpReactionEnabled, tmpPin, selectedLinkId, updateLink]
  );

  const selectLink = useCallback(
    (linkId) => {
      const params = new URLSearchParams(search);
      params.set('link_id', linkId);
      params.delete('lead_id');
      navigate(`?${params.toString()}`);
    },
    [search, navigate]
  );

  const selectLead = useCallback(
    (linkId, leadId) => {
      const params = new URLSearchParams(search);
      params.set('link_id', linkId);
      params.set('lead_id', leadId);
      navigate(`?${params.toString()}`);
    },
    [search, navigate]
  );

  const edit = useCallback(() => navigate('../edit'), [navigate]);
  const share = useCallback(() => {
    setModal('share_presentation', {
      presentationId: presentation?.presentationId,
      title: presentation?.title,
      shareType: selectedFilter,
    });
  }, [setModal, presentation?.presentationId, presentation?.title, selectedFilter]);

  const { addResponse } = useResponses(null, false, false);
  const [submittingResponse, setSubmitting] = useState(false);

  // Corrected submitResponse definition
  const submitResponse = useCallback(
    async (e) => {
      setSubmitting(true);
      e.preventDefault();
      e.stopPropagation();
      await addResponse(e.target.elements.body.value);
      e.target.reset();
      setSubmitting(false);
    },
    [addResponse]
  );

  const onChangeTitle = useCallback(
    (e) => {
      setTmpTitle(e.currentTarget.value);
    },
    [setTmpTitle]
  );

  const [updatingTitle, setUpdatingTitle] = useState(false);
  const onSubmitTitle = useCallback(
    async (e) => {
      setUpdatingTitle(true);
      e.preventDefault();
      e.stopPropagation();
      await savePresentationTitle(tmpTitle);
      setUpdatingTitle(false);
    },
    [savePresentationTitle, tmpTitle]
  );

  const proxySampleDataUrl = useCallback(
    (linkId) => {
      if (sampleMode) {
        return (
          <ProPopup
            title="Your trial has expired"
            subtitle="Please select a Solo, Pro or Advanced plan to reactivate sharing"
            requiredFeature="sharing"
          >
            <a onClick={(e) => e.stopPropagation()} target="_blank" href="edit#preview">
              {`${window.location.hostname}/p/sample`}
            </a>
          </ProPopup>
        );
      } else {
        return (
          <ProPopup
            title="Your trial has expired"
            subtitle="Please select a Solo, Pro or Advanced plan to reactivate sharing"
            requiredFeature="sharing"
          >
            <a
              className="btn primary"
              onClick={(e) => e.stopPropagation()}
              target="_blank"
              href={`/p/${linkId}`}
              rel="noreferrer"
            >
              {window.location.hostname}/p/{linkId}
            </a>
          </ProPopup>
        );
      }
    },
    [sampleMode]
  );

  // Toast message on copy
  const showCopiedMessage = () => {
    toastr.info('Link copied to clipboard', { timeOut: 1000 });
  };

  return (
    <div className="analytics-page">
      <Helmet>
        <title>{`Sizle - ${presentation?.title}`}</title>
      </Helmet>

      <div className="section link-section">
        <div className="document-details">
          <div className="document-title label giant">
          <label>Name</label>
            <form onSubmit={onSubmitTitle}>
              <input
                required
                onChange={onChangeTitle}
                className="label input-text"
                key={presentation?.title}
                defaultValue={presentation?.title}
              />
              <button
                hidden={presentation?.title === tmpTitle}
                type="submit"
                className={`btn primary ${updatingTitle ? 'loading-spinner' : ''}`}
              >
                Save
              </button>
            </form>
          </div>
          
          <ProPopup
                title="Your trial has expired"
                subtitle="Please select a Solo, Pro or Advanced plan to reactivate sharing"
                requiredFeature="sharing"
              >
                <button onClick={share} className="share-button">
                  Share document
                </button>
              </ProPopup>
              {presentation?.presentationId && (
            <img
              src={`/api/documents/thumbnail/${presentation?.presentationId}/0?timestamp=${presentation?.updated_at}`}
              className="document-thumbnail"
              alt="Document Thumbnail"
            />
          )}
              <button className="custom-button" onClick={() => navigate(`/s/${workspaceIndex}/presentations`)}>
            Back to Home
          </button>
                    <div className="buttons">
            <div className="actions">
            </div>
          </div>
        </div>

        <div className="links-section">
          <div className="header">
            <div hidden={!sampleMode} className="sample-banner">
              This is a preview of what you'll see after sharing a document
            </div>
          </div>
          {selectedFilter === 'public' && (
            <div className="lead-table public">
              {publicLinks.length > 0 ? (
                publicLinks.map((link) => (
                  <React.Fragment key={link.link_id}>
                    <div
                      onClick={() => selectLink(link.link_id)}
                      className={`${
                        !selectedLeadId && link.link_id === selectedLinkId ? 'selected' : ''
                      } table-row`}
                    >
                      <label className="label linkid small">
                      <span style={{ flexGrow: 1, display: 'flex', alignItems: 'center' }}>
  <span 
    style={{ flexGrow: 1, opacity: 0.75, cursor: 'pointer' }}
    onClick={showCopiedMessage}  // Add this line to trigger the toast
  >
    Copy share link
  </span>
  <CopyToClipboard text={link.url} onCopy={showCopiedMessage}>
    <span style={{ cursor: 'pointer' }}>
      <SVGInline svg={clipboard} />
    </span>
  </CopyToClipboard>
</span>
                      </label>
                    </div>
                    {link.leads.length > 0 ? (
                      <>
                        <label className="label header recipient small">Viewer</label>
                        <label className="label header link-detail small">Reaction</label>
                        <label className="label header link-detail small">First Activity</label>
                        <label className="label header link-detail small">Last Activity</label>
                        {link.leads.map((lead) => (
                          <div
                            onClick={() => selectLead(link.link_id, lead.lead_id)}
                            key={lead.lead_id}
                            className={`${
                              lead.lead_id === selectedLeadId ? 'selected' : ''
                            } table-row`}
                          >
                            <div className="label small recipient regular">{lead.recipient}</div>
                            <div className={`reaction link-detail ${lead.reaction}`} />
                            <div className="label small link-detail regular">
                              {lead.first_activity && format(new Date(lead.first_activity), 'yyyy/MM/dd hh:mma')}
                            </div>
                            <div className="label small link-detail regular">
                              {lead.last_activity && format(new Date(lead.last_activity), 'yyyy/MM/dd hh:mma')}
                            </div>
                          </div>
                        ))}
                      </>
                    ) : (
                      <label className="label no-viewers recipient small regular">
                        No viewers yet
                      </label>
                    )}
                  </React.Fragment>
                ))
              ) : (
                <div className="label big no-links">Share a link to get started</div>
              )}
            </div>
          )}
          {selectedFilter === 'unique' && (
            <div className="lead-table unique">
              <label className="label header recipient small">Link</label>
              <label className="label header recipient link-detail small">Recipient</label>
              <label className="label header recipient link-detail small">Organisation</label>
              <label className="label header small link-detail">Reaction</label>
              <label className="label header small link-detail">First Activity</label>
              <label className="label header small link-detail">Last Activity</label>

              {uniqueLinks.length > 0 ? (
                uniqueLinks.map((link) => (
                  <React.Fragment key={link.link_id}>
                    <div
                      onClick={() => selectLead(link.link_id, link.leads[0]?.lead_id)}
                      className={`${link.link_id === selectedLinkId ? 'selected' : ''} table-row`}
                    >
                      <div className="label linkid small">
                        <span style={{ flexGrow: 1, display: 'flex', alignItems: 'center' }}>
                          <span style={{ flexGrow: 1, opacity: 0.75 }}>Copy shareable link</span>
                          <CopyToClipboard text={link.url} onCopy={showCopiedMessage}>
                            <span style={{ cursor: 'pointer' }}>
                              <SVGInline svg={clipboard} />
                            </span>
                          </CopyToClipboard>
                        </span>
                      </div>
                      <div className="label small recipient link-detail regular">
                        {link.leads[0].recipient}
                      </div>
                      <div className="label small recipient link-detail regular">
                        {link.leads[0].organisation}
                      </div>
                      <div className={`reaction link-detail ${link.leads[0].reaction}`} />
                      <div className="label small regular link-detail">
                        {link.leads[0].first_activity && format(new Date(link.leads[0].first_activity), 'yyyy/MM/dd hh:mma')}
                      </div>
                      <div className="label small regular link-detail">
                        {link.leads[0].last_activity && format(new Date(link.leads[0].last_activity), 'yyyy/MM/dd hh:mma')}
                      </div>
                    </div>
                  </React.Fragment>
                ))
              ) : (
                <div className="no-links label big">Share a link to get started</div>
              )}
            </div>
          )}
          {selectedFilter === 'email' && (
            <div className="lead-table email">
              <label className="label header recipient small">Link</label>
              <label className="label header link-detail recipient small">Recipient</label>
              <label className="label header link-detail small">Reaction</label>
              <label className="label header link-detail small">First Activity</label>
              <label className="label header link-detail small">Last Activity</label>
              {emailLinks.length > 0 ? (
                emailLinks.map((link) => (
                  <React.Fragment key={link.link_id}>
                    <div
                      onClick={() => selectLead(link.link_id, link.leads[0]?.lead_id)}
                      className={`${link.link_id === selectedLinkId ? 'selected' : ''} table-row`}
                    >
                      <div className="label linkid small">
                        <span style={{ flexGrow: 1, display: 'flex', alignItems: 'center' }}>
                          <span style={{ flexGrow: 1, opacity: 0.75 }}>Copy shareable link</span>
                          <CopyToClipboard text={link.url} onCopy={showCopiedMessage}>
                            <span style={{ cursor: 'pointer' }}>
                              <SVGInline svg={clipboard} />
                            </span>
                          </CopyToClipboard>
                        </span>
                      </div>
                      <div className="label small link-detail recipient regular">{link.leads[0].recipient}</div>
                      <div className={`reaction link-detail ${link.leads[0].reaction}`} />
                      <div className="label small link-detail regular">
                        {link.leads[0].first_activity && format(new Date(lead.first_activity), 'yyyy/MM/dd hh:mma')}
                      </div>
                      <div className="label small link-detail regular">
                        {lead.last_activity && format(new Date(link.leads[0].last_activity), 'yyyy/MM/dd hh:mma')}
                      </div>
                    </div>
                  </React.Fragment>
                ))
              ) : (
                <div className="no-links label big">Share a link to get started</div>
              )}
            </div>
          )}
        </div>
      </div>
      {selectedLink?.type === 'email' && (
        <div className="section">
          <label className="label big">Delivery Timeline</label>
          <div className="card timeline">
            <div
              className="step completed"
              data-title="Sent"
              data-timestamp={
                selectedLink?.created_at ? format(new Date(selectedLink?.created_at), 'yyyy/MM/dd hh:mma') : ''
              }
            />
            <div className={`step-track ${selectedLink?.email_delivered ? 'completed' : ''}`} />
            <div
              className={`step ${selectedLink?.email_delivered ? 'completed' : ''}`}
              data-title="Delivered"
              data-timestamp={
                selectedLink?.email_delivered
                  ? format(new Date(selectedLink?.email_delivered), 'yyyy/MM/dd hh:mma')
                  : ''
              }
            />
            <div className={`step-track ${selectedLink?.email_opened ? 'completed' : ''}`} />
            <div
              className={`step ${selectedLink?.email_opened ? 'completed' : ''}`}
              data-title="Opened"
              data-timestamp={
                selectedLink?.email_opened
                  ? format(new Date(selectedLink?.email_opened), 'yyyy/MM/dd hh:mma')
                  : ''
              }
            />
          </div>
        </div>
      )}
      {selectedLead ? (
        <>
          <div className="section">
            <div className="charts">
              <div>
                <div className="title big label">
                  <label className="label-huge">Time per page</label>
                  <br />
                  <label className="label small">How long the viewer spent on each page</label>
                </div>
                <Chart
                  yDataKey="page"
                  xDataKey="time"
                  presentation={presentation}
                  data={selectedLead.avgpagetime}
                />
              </div>
              <div>
                <div className="title big label">
                  <label className="label-huge">Page views</label>
                  <br />
                  <label className="label small">Total page views, includes repeated views of a single page</label>
                </div>
                <Chart
                  yDataKey="page"
                  xDataKey="count"
                  presentation={presentation}
                  data={selectedLead.avgpagetime}
                />
              </div>
            </div>
          </div>
          <div className="section analytics-section">
            <div className="analytics-card">
              <label className="label-huge">Lead activity</label>
              <label className="label small">
                How <span className="label small blue">{selectedLead.recipient}</span> interacted with your document
              </label>
              <div className="card">
                <div className="table">
                  <label className="label small">Total opens</label>
                  <label className="label small">{selectedLead.open_count}</label>
                  <label className="label small regular">Link first opened</label>
                  <label className="label small regular">
                    {selectedLead.first_activity && format(new Date(selectedLead.first_activity), 'yyyy/MM/dd hh:mma')}
                  </label>
                  <label className="label small regular">Downloaded?</label>
                  <label className="label small regular">
                    {selectedLead.downloaded_timestamp
                      ? format(new Date(selectedLead.downloaded_timestamp), 'yyyy-MM-dd HH:mm:ss')
                      : 'N/A'}
                  </label>
                </div>
              </div>
            </div>
            <div className="analytics-card comments">
              <label className="label-huge">Discussion</label>
              <label className="label small">View and respond to comments</label>
              <div className="card responses">
                {selectedLead?.responses?.length > 0 ? (
                  selectedLead.responses.map((r, i) => (
                    <div className={`response ${r.title === 'Owner' ? 'self' : 'other'}`} key={i}>
                      <div className="title label">
                        {r.title}
                        <div className="label small tight">
                          {format(new Date(r.created_at), 'LLL dd, yyyy @ hh:mma')}
                        </div>
                      </div>
                      <div className="label small">{r.text}</div>
                    </div>
                  ))
                ) : (
                  <label className="label small">No comments have been added</label>
                )}
                <form onSubmit={submitResponse}>
                  <textarea name="body" className="self input-text" />
                  <button
                    disabled={sampleMode}
                    className={`create-response wide btn primary ${submittingResponse ? 'loading-spinner' : ''}`}
                  >
                    Send
                  </button>
                </form>
              </div>
            </div>
            <div className="analytics-card geography">
              <label className="label-huge">Geography & IP</label>
              <div className="card">
                {locations.length > 0 ? (
                  <div className="table nogap">
                    <label className="label header small">IP Address</label>
                    <label className="label header small">Location</label>
                    {locations.map((l, i) => (
                      <React.Fragment key={i}>
                        <label className="label regular small">{l.ipAddress || 'Unknown'}</label>
                        <label className="label regular small">{l.location || 'Unknown'}</label>
                      </React.Fragment>
                    ))}
                  </div>
                ) : (
                  <label className="label small regular">No geography and IP data yet</label>
                )}
              </div>
            </div>
            <div className="analytics-card insights">
              <label className="label-huge">Interaction log</label>
              <div className="card">
                {opens.length > 0 ? (
                  <div className="table nogap">
                    <div className="label header small">Time</div>
                    <div className="label header small">Device Type</div>
                    <div className="label header small">Operating System</div>
                    <div className="label header small">Browser</div>
                    {opens.map((event, i) => (
                      <React.Fragment key={i}>
                        <div className="label regular small">{event.time}</div>
                        <div className="label regular small">{event.platform}</div>
                        <div className="label regular small">{event.os}</div>
                        <div className="label regular small">{event.browser}</div>
                      </React.Fragment>
                    ))}
                  </div>
                ) : (
                  <label className="label small">No open insights yet</label>
                )}
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className="empty-lead">
          <div className="label hyooj">Link analytics and insights will appear here after sharing</div>
        </div>
      )}
    </div>
  );
};

export default AnalyticsPage;

