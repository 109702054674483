import React, { useContext, useState, useCallback } from 'react'
import { toastr } from 'react-redux-toastr'
import { organisationContext } from 'src/context'

const CompanyEditModal = ({
  dismissModal,
  selectedCompany,
  isAddingCompany
}) => {
  let company = selectedCompany

  if (isAddingCompany) {
    company = {}
  }

  const [companyName, setCompanyName] = useState(company.name)
  const [loading, setLoading] = useState(false)
  const [formError, setFormError] = useState(null)
  const { createOrganisation } = useContext(organisationContext)

  const onClickCancel = useCallback(() => {
    dismissModal()
  }, [dismissModal])

  const onClickCreate = async () => {
    setLoading(true)
    if (!companyName) {
      setFormError('companyName')
    } else {
      try {
        await createOrganisation(companyName)
        toastr.info('New company added  👍')
        dismissModal()
      } catch (e) {
        console.error('Unable to create organisation', e)
        toastr.error('Unable to crate company, please contact support')
      }
      setLoading(false)
    }
  }

  const onChange = (e) => {
    setCompanyName(e.target.value)
    setFormError(null)
  }

  return (
    <>
      <h2>
        {isAddingCompany
          ? <span>Add new company</span>
          : <span>Editing <em>{company.name}</em></span>}
      </h2>
      <div>
        <div className='form-control'>
          <label htmlFor='name'>Company name</label>
          <input type='text' placeholder='Example & Co' defaultValue={companyName} onChange={onChange} loading={loading ? 'loading' : undefined} autofocus />
        </div>
      </div>
      <center className='btn-group'>
        <button className='btn' onClick={() => { onClickCancel() }}>Cancel</button>
        { isAddingCompany &&
          <button className='btn primary' loading={loading ? 'loading' : undefined} onClick={onClickCreate}>Add company</button>
        }

      </center>
    </>
  )
}

export default CompanyEditModal
