import React, { useContext, useState, forwardRef } from 'react'
import BeatLoader from 'react-spinners/BeatLoader'
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js'
import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'
import SVGInline from 'react-svg-inline'

import { stripeLock, stripeAmex, stripePowered, stripeMastercard, stripeUnionPay, stripeVisa, stripeJCB } from 'src/design-system/icons'
import { sendAmplitudeData } from 'src/utils/amplitude'
import { useUserContext, ModeContext } from 'src/context'

const CheckoutForm = ({ onSubmit, actionText, includePromoField }) => {
  const elements = useElements()
  const stripe = useStripe()
  const { t } = useTranslation()
  const { user } = useUserContext()
  const appMetadata = user?.['https://sizle.io/app_metadata'] || {}
  const [submitting, setSubmitting] = useState(false)
  const { mode } = useContext(ModeContext)

  const updateCardDetails = async (e) => {
    setSubmitting(true)
    e.preventDefault()
    const cardElement = elements.getElement(CardElement)
    try {
      const name = e?.target?.name?.value
      const promo = e?.target?.promo?.value
      const result = await stripe.createToken(cardElement, { name })
      if (result.token && onSubmit) {
        await onSubmit({
          token: result.token,
          promo
        })
      }
      sendAmplitudeData('PAYMENT_MADE', { userUpgradingFrom: appMetadata.accountTier })

      const fullnameElement = document.getElementById('fullname')
      const promoElement = document.getElementById('promo')
      fullnameElement.value = ''
      if (promoElement) promoElement.value = ''
      cardElement.clear()
    } catch (e) {
      sendAmplitudeData('PAYMENT_ERROR')
      throw e
    }
    setSubmitting(false)
  }

  return (
    <StripeForm onSubmit={updateCardDetails}>
      <div className='top-inputs'>
        <div>
          <label>{t('Full name')}</label>
          <input className='input-text' required name='fullname' type='text' id='fullname' />
        </div>
        {!!includePromoField && (
          <div>
          </div>
        )}
      </div>
      <br />
      <label>{t('Payment method')}</label>
      <CardElement options={{ style: { base: { color: mode === 'dark' ? 'white' : 'unset' } }, hidePostalCode: true }} className='card-input' />
      <div><button className='primary btn extra-big' type='submit'>{actionText}</button></div>
      <Loader disabled={submitting}>
        <BeatLoader size={10} margin={3} color='var(--primary-color)' loading={submitting} />
      </Loader>
    </StripeForm>
  )
}

const StripeForm = styled.form`
  width: min(420px, 90%);
  display: flex;
  flex-direction: column;
  align-items: stretch;
  text-align: left;
  position: relative;

  .top-inputs {
    display: grid;
    grid-template-columns: 2fr 1fr;
    column-gap: 20px;

    input { width: 100%; }
  }

  .card-input {
    border-radius: 8px;
    height: 30px;
    padding-top: 6px;
    padding-left: 7px;
    background-color: var(--input-background);
    border: 1px solid var(--input-border-color);

    &:hover,
    &:focus { border-color: var(--input-focus); }
  }

  .card-name-input {
    background - color: var(--input-background);
    color: var(--input-color);
    border-color: var(--input-border-color);

    &:hover,
    &:focus { border-color: var(--input-focus); }
  }

  .btn.primary {
    margin-top: 20px;
  }
`

const PoweredBy = styled.label`
  margin-top: 15px;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  color: #979797CC;
`

const Icon = styled(SVGInline)`
  width: 30px;
  margin: 0px 2px 0px 2px;
  height: 20px;
  svg{width:30px;max-height:22px;}
`

const LockIcon = styled(SVGInline)`
  width: 18px;
`

const GuaranteeIcon = styled(SVGInline)`
  margin-right: 20px;
  margin-left: 5px;
  width: 110px;
`

const Loader = styled.div`
  pointer-events: none;
  position: absolute;
  margin: auto;
  min-width: 100%;
  min-height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  &[disabled] {background-color: var(--background-loading); }

  .css-0 { margin: auto; }
`
export default CheckoutForm
