import React, { useEffect, useState, useMemo, useCallback, useContext } from 'react'
import SVGInline from 'react-svg-inline'
import { workspaceContext } from '../../../context'
import { CreatableSelect } from '../../../design-system/elements/index.js'
import { components } from 'react-select'
import { RoleEditPopup } from './RoleEditPopup.tsx'
import { trash } from '../../../design-system/icons/index.js'
import styles from './PermissionsSelect.module.scss'
import { toastr } from 'react-redux-toastr'

export const PermissionsSelect = ({ workspaceMember, isInvite }) => {
  const { deleteRole, setInviteRole, setUserRole, createWorkspaceRole, currentWorkspaceRoles, hasWorkspacePermission, workspace } = useContext(workspaceContext)
  const [isLoading, setLoading] = useState(false)
  const [isDeleting, setDeleting] = useState({})
  const [currentRole, setInternalCurrentRole] = useState({ label: workspaceMember?.role?.name, value: workspaceMember?.role?.role_id })
  const [openEdit, setOpenEdit] = useState(null)
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const editEnabled = useMemo(
    () => hasWorkspacePermission('Workspace Settings') && workspace.owner.auth0_id !== workspaceMember.user_id,
    [workspace?.owner?.auth0_id, workspaceMember?.user_id, hasWorkspacePermission]
  )

  useEffect(() => {
    setInternalCurrentRole({ label: workspaceMember?.role?.name, value: workspaceMember?.role?.role_id })
  }, [workspaceMember])

  const createRole = useCallback(async (option) => {
    await createWorkspaceRole(option)
    toastr.success('Role created')
  }, [createWorkspaceRole])

  const onAdd = useCallback(async (e) => {
    e.preventDefault()
    e.stopPropagation()
    await createWorkspaceRole('New role')
    toastr.success('Role created')
  }, [createWorkspaceRole])
  const setRole = async (e) => {
    setLoading(true)
    if (isInvite) {
      await setInviteRole(workspaceMember.invite_id, e.value)
    } else {
      await setUserRole(workspaceMember.user_id, e.value)
    }
    setDropdownOpen(false)
    setLoading(false)
  }
  const submitDeleteRole = useCallback(async (roleId) => {
    setDeleting(d => ({ ...d, [roleId]: true }))
    await deleteRole(roleId)
    setDeleting(d => ({ ...d, [roleId]: false }))
  }, [deleteRole])

  const PermissionSelectControl = useCallback(({ children, getValue, ...props }) => {
    return (
      <components.Control {...props}>
        <span className={styles['select-label']}>
          {children}
          {editEnabled && <span>
            {!props.selectProps.isLoading && <>
              <span title='Add new role with custom permissions' className={styles['add-icon']} onMouseDown={onAdd} />
              <RoleEditPopup open={() => setOpenEdit('select')} close={() => setOpenEdit(null)} isOpen={openEdit === 'select'} roleName={getValue()[0].label} roleId={getValue()[0].value} />
                                             </>}
          </span>}
        </span>
      </components.Control>
)
  }, [onAdd, openEdit, editEnabled, setOpenEdit])
  const PermissionOptionControl = useCallback(({ children, getValue, ...props }) => {
    const roleEditable = currentWorkspaceRoles.find(r => r.role_id === props.value)?.is_editable
    const noDeleteReason = !roleEditable
      ? 'This role may not be deleted'
      : workspace.workspace_members.some(wm => wm.role.role_id === props.value)
        ? 'Unassign this role from all users before deleting it'
        : null
    return (
      <components.Option {...props}>
        <span className={styles['select-option']}>
          <span>{children}</span>
          {editEnabled && <>
            <RoleEditPopup
              open={() => setOpenEdit(props.value)}
              close={() => setOpenEdit(null)}
              isOpen={openEdit === props.value}
              roleName={props.label}
              roleId={props.value}
            />
            <SVGInline
              onClick={(e) => {
              if (!noDeleteReason) submitDeleteRole(props.value)
              e.stopPropagation()
            }}
              title={noDeleteReason}
              disabled={!!noDeleteReason}
              className={`${styles['trash-svg']} ${isDeleting[props.value] ? 'loading-spinner' : ''}`}
              svg={trash}
            />
          </>}
        </span>
      </components.Option>
)
  }, [submitDeleteRole, currentWorkspaceRoles, editEnabled, workspaceMember, openEdit, hasWorkspacePermission, setOpenEdit, isDeleting, workspace?.workspace_members])

  return (
    <>
      {dropdownOpen && <div className={styles['select-backdrop']} onClick={() => setDropdownOpen(false)} />}
      <CreatableSelect
        isDisabled={!editEnabled}
        menuIsOpen={dropdownOpen}
        onMenuOpen={() => setDropdownOpen(true)}
        onMenuClose={() => setDropdownOpen(false)}
        isLoading={isLoading}
        onCreateOption={createRole}
        styles={{ menu: (provided) => ({ ...provided, zIndex: 5 }) }}
        value={currentRole}
        components={{
        Option: PermissionOptionControl,
        Control: PermissionSelectControl
      }}
        className={`${styles.select} ${isLoading ? 'loading-spinner' : ''} permissions-${encodeURIComponent(workspaceMember.email)}`}
        onChange={setRole}
        options={useMemo(() => currentWorkspaceRoles.map((r) => {
        return {
          label: r.name,
          value: r.role_id
        }
      }), [currentWorkspaceRoles])}
      >
        <span>test</span>
      </CreatableSelect>
    </>
)
}
