import React, { useEffect, useContext, useCallback } from 'react'
import { useAuth } from 'oidc-react'
import { useAnalytics } from 'use-analytics'
import PageTitle from 'src/modules/navigation/PageTitle'
import { Modals, useModal } from 'src/modules/modals'
import MainSidebar from 'src/modules/navigation/main-sidebar/components/Sidebar'
import { ProTrialWidget } from 'src/modules/protrial'
import { userContext, workspaceContext } from 'src/context'

const MainLayout = (props) => {
  const { userData: user } = useAuth()
  const { identify } = useAnalytics()
  const { setActiveFolder } = useContext(userContext)
  const { invites } = useContext(workspaceContext)
  const { setModal } = useModal()

  // When a user logs in, fetch their info, and begin the invitation acceptance process if necessary
  useEffect(() => {
    if (user) {
      // Track the user identify for GoogleAnalytics
      if (user.sub) { identify(user.sub) }
      if (identify) {
        identify({ name: `${user.given_name} ${user.family_name}`, userId: user.sub, email: user.email })
      }
    }
  }, [user, identify])

  useEffect(() => {
    if (invites.length > 0) { setModal('workspace_invintation') }
  }, [invites, setModal])

  const updateCurrentFolder = useCallback((folderKey) => {
    setActiveFolder(`${folderKey}`)
  }, [setActiveFolder])

  return (
    <div className='main-layout'>
      <ProTrialWidget />
      <MainSidebar setActiveFolder={updateCurrentFolder} />
      <div className='main-layout-padder' id='mainContentContainer'>
        <div className='main-layout-content'>
          <PageTitle />
          {props.children}
        </div>
      </div>
      <Modals user={user} />
    </div>
  )
}

export default MainLayout
