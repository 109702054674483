import { useState } from 'react'
import { useMutation } from '@apollo/client'
import { v4 as uuid } from 'uuid'

import { SYNC_EVENTS } from 'src/graphql/events'
import { ADD_WORKSPACE_EVENT } from 'src/graphql/workspace-events'

export const PRESENTATION_OPENED = 'PRESENTATION_OPENED'
export const PRESENTATION_ENDED = 'PRESENTATION_ENDED'
export const PRESENTATION_SLIDE_OPENED = 'PRESENTATION_SLIDE_OPENED'

const usePresentationAnalytics = () => {
  const [presentationData, setPresentationData] = useState({
    presentationId: null,
    leadId: null
  })
  const [syncEvents] = useMutation(SYNC_EVENTS)
  const [addWorkspaceEvent] = useMutation(ADD_WORKSPACE_EVENT)

  const presentationStart = ({ presentationId, leadId, linkId, workspaceId }) => {
    const event = {
      id: uuid(),
      event: PRESENTATION_OPENED,
      user: leadId,
      presentation: presentationId,
      timestamp: new Date().toISOString()
    }

    syncEvents({
      variables: { events: [event] }
    })

    addWorkspaceEvent({
      variables: {
        workspace_id: workspaceId,
        type: 'open',
        info: {
          linkId
        }
      }
    })

    setPresentationData({
      presentationId,
      leadId
    })
  }

  const viewSlide = (pos) => {
    const event = {
      id: uuid(),
      event: PRESENTATION_SLIDE_OPENED,
      meta: { pos },
      user: presentationData.leadId,
      presentation: presentationData.presentationId,
      timestamp: new Date().toISOString()
    }

    syncEvents({
      variables: { events: [event] }
    })
  }

  const presentationEnded = () => {
    if (!presentationData.presentationId || !presentationData.leadId) {
      throw new Error('Presentation data not set')
    }
    const event = {
      id: uuid(),
      event: PRESENTATION_ENDED,
      user: presentationData.leadId,
      presentation: presentationData.presentationId,
      timestamp: new Date().toISOString()
    }

    syncEvents({
      variables: { events: [event] }
    })
  }

  return { presentationStart, viewSlide, presentationEnded }
}

export default usePresentationAnalytics
