import { theme } from '@chakra-ui/core'

const THEME = {
  ...theme,
  colors: {
    ...theme.colors,
    primary: '#00092b',
    brand: {
      500: '#635bff'
    }
  },
  fonts: {
    body: 'Work Sans, sans-serif',
    heading: 'Work Sans, sans-serif',
    mono: 'Menlo, monospace'
  },

  fontWeights: {
    normal: 400,
    medium: 500,
    bold: 700
  },

  fontSizes: {
    xs: '8px',
    sm: '10px',
    md: '12px',
    lg: '16px',
    xl: '20px',
    '2xl': '24px',
    '3xl': '28px',
    '4xl': '36px',
    '5xl': '48px',
    '6xl': '64px'
  },

  brand: {
    pro: '#635bff',
    primary: '#635bff',
    primaryFaint: '#E0DDE9',
    primaryFailting: '#d0c0ff',
    primaryDark: '#5843FF',
    primaryShade: '#5b75bc',
    secondaryLight: '#e55a90',
    secondaryDark: '#e5a46f',
    background: '#fff',
    black: '#4e4e4e',
    black3: '#1E1F21',
    grey: '#9B9B9B',
    greyHeading: '#4e4e4e',
    lighterGray: '#e4e4e4',
    lightGray: '#f7f9fe',
    white: '#FFF',
    white2: '#ECEDEF',
    white3: '#eaeaea',
    white4: '#c6c9cf',
    lightnessBg: '#FFF',
    lightBg: '#494fff',
    labelDark: '#AAAEB3',
    cardBackground: '#9B9B9B',
    cardDots: '#4A4A4A',
    red: '#f44336',
    tertiary: '#fc3d71',
    borderGray: '#C7C7C7',
    borderDarkGray: '#979797',
    borderLightGray: '#efefef'
  },

  typo: {
    fontDefault: 'Work Sans, sans-serif',
    fontAlternate: 'Work Sans',
    // fontAlternate: 'Open Sans',
    colorBlack: '#1E1F21',
    colorWhite: '#FFF',
    colorGrey: '#4e4e4e',
    baseFontSize: '12px'
  },

  notifications: {
    success: '#4bc565',
    error: '#ff5351'
  },

  buttons: {
    borderRadius: '8px',
    boxShadow: '0 2px 10px 0 rgba(0, 0, 0, 0.16)'
  },

  scenes: {
    cardShadow: '0px 6px 20px rgba(0, 0, 0, 0.269446);'
  },

  inputs: {
    boxShadow: 'inset 0 5px 6px 0 rgba(0, 0, 0, 0.05)',
    grayBorder: '#dae1f4',
    darkBorder: '#393c40',
    redBorder: '#f44236',
    lightBlueBorder: '#D4DBED'
  },

  cards: {
    background: '#f7f7f7',
    boxShadow: '0 6px 10px 0 rgba(131, 134, 163, 0.12)',
    menuShadow: '0 2px 10px 0 rgba(0, 0, 0, 0.16)'
  },

  dropdown: {
    boxShadow: '0px 6px 20px 0 rgba(0, 0, 0, 0.269446);'
  },

  sidebar: {
    width: '304px'
  },

  borders: {
    color0: '#eae9e6'
  }
}

export default THEME
