import React from 'react'
import { useAuth } from 'oidc-react'
import { Modals } from 'src/modules/modals'
import { Toaster } from 'react-hot-toast'

const ViewerLayout = ({ children }) => {
  const { userData } = useAuth()
  return (
    <div style={{ height: '100%' }}>
      {children}
      <Toaster
        position='bottom-right'
        reverseOrder={false}
      />
      <Modals user={userData} />
    </div>
  )
}

export default ViewerLayout
