import React from 'react'

const BlankLayout = ({ children }) => (
  <div className='blank-layout'>
    <div>
      {children}
    </div>
  </div>
)

export default BlankLayout
