import React, { useContext, useEffect, useState } from 'react'
import { components } from 'react-select'
import { useTranslation } from 'react-i18next'
import { organisationContext } from 'src/context'
import styled from '@emotion/styled'
import { CreatableSelect } from 'src/design-system/elements'

const CreateRecipientSelector = ({
  isDisabled,
  organisationId,
  onSelectRecipient
}) => {
  const { createRecipient, recipients } = useContext(organisationContext)
  const [isLoading, setLoading] = useState(false)
  const [value, setValue] = useState(null)
  const [recipientOptions, setRecipientOptions] = useState([])
  const { t } = useTranslation()

  useEffect(() => {
    setValue(v => recipients.filter(v => v.id === value?.value && v.orgId === organisationId).map(r => {
      return {
        label: r.name,
        value: r.id
      }
    })[0] || null)
  }, [recipients, organisationId])

  useEffect(() => {
    setRecipientOptions(recipients.filter(r => r.orgId === organisationId).map((r) => {
      return {
        label: r.name,
        value: r.id
      }
    }))
  }, [recipients, organisationId])

  const handleChange = (option) => {
    setValue(option)
    onSelectRecipient(option)
  }

  const handleCreate = async (inputValue) => {
    setLoading(true)
    const recipient = await createRecipient(inputValue, null, null, organisationId)
    handleChange({ value: recipient.recipientId, label: recipient.recipientName })
    setLoading(false)
  }

  const customStyles = {
    container: (provided) => ({
      ...provided,
      margin: '0 0 10px',
      textAlign: 'left'
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 10
    })
  }

  return (
    <CreateContainer>
      <CreatableSelect
        styles={customStyles}
        autoComplete='definitely-off'
        components={{ Input: React.forwardRef((props, ref) => <components.Input {...props} type='text' name='what' autoComplete='definitely-not' ref={ref} required={!value} />) }}
        onChange={handleChange}
        isDisabled={isDisabled || isLoading}
        isLoading={isLoading}
        placeholder={t('Type or create new')}
        value={value}
        onCreateOption={handleCreate}
        options={recipientOptions}
      />
    </CreateContainer>
  )
}

const CreateContainer = styled.div`
  position: relative;

  > div {
    > div {
      border: 1px solid var(--input-border-color);
      background-color: var(--input-background);
      color: var(--input-color);
      min-height: 32px;
      transition: all 0.3s ease-in-out;

      &:hover,
      &:focus {
        background-color: var(--input-background);
        border: 1px solid var(--input-focus);
      }

      > div {
        > div {
          color: var(--input-color);
        }
      }
    }
  }
`

export default CreateRecipientSelector
