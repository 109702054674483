import React, { memo, useState, useCallback } from 'react';
import styled from '@emotion/styled';
import { useAuth } from 'oidc-react';

import ResponseItem from './ResponseItem';
import ResponseForm from './ResponseForm';

const ResponseListPreview = ({ presentationId }) => {
  const { userDetails: user } = useAuth();

  const [responses, setResponses] = useState([
    {
      id: 1,
      text: "Viewer's comment",
      presentationId,
      userId: null,
      created_at: new Date().toISOString(),
      title: 'Viewer',
    },
    {
      id: 2,
      text: "Owner's comment",
      presentationId,
      userId: 'owner',
      created_at: new Date().toISOString(),
      title: 'Owner',
    },
  ]);

  const handleSubmit = useCallback(
    (text) => {
      if (!text || text.length === 0) return;

      setResponses((prevResponses) => [
        ...prevResponses,
        {
          id: prevResponses.length ? prevResponses[prevResponses.length - 1].id + 1 : 1,
          text,
          presentationId,
          userId: user.sub,
          created_at: new Date().toISOString(),
          title: 'Viewer',
        },
      ]);
    },
    [presentationId, user.sub]
  );

  const removeResponse = useCallback((id) => {
    setResponses((prevResponses) => prevResponses.filter((r) => r.id !== id));
  }, []);

  const editResponse = useCallback((response, body) => {
    setResponses((prevResponses) =>
      prevResponses.map((r) => (r.id === response.id ? { ...r, text: body } : r))
    );
  }, []);

  return (
    <Wrapper>
      {responses.map((el) => (
        <ResponseItem
          key={`response-${el.id}`}
          item={el}
          title={el.userId === user?.sub ? 'You' : el.title}
          onEditSubmit={editResponse}
          onDeleteClick={removeResponse}
          isOwnersResponse={el.userId === 'owner'}
          ownerId='owner'
        />
      ))}
      <ResponseFormContainer>
        <ResponseForm onSubmit={handleSubmit} />
      </ResponseFormContainer>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  min-width: 340px;
  max-height: 600px;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  z-index: 11;
  padding: 10px;
  border-bottom-left-radius: 6px;
  background-color: var(--background-color);
  border-bottom-right-radius: 6px;
  box-shadow: var(--editor-shadow);
`;

const ResponseFormContainer = styled.div`
  position: relative;
  margin-top: 15px;
`;

export default memo(ResponseListPreview);
