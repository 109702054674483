import React, { useContext, useState, useCallback, useEffect } from 'react'
import { toastr } from 'react-redux-toastr'

import { organisationContext } from 'src/context'
import { CreatableSelect } from 'src/design-system/elements'

const RecipientsEditModal = ({
  dismissModal,
  companies,
  selectedRecipient,
  isAddingRecipient,
  hideCompanySelector,
  selectedOrg
}) => {
  let recipient = selectedRecipient

  if (isAddingRecipient) {
    recipient = {}
  }

  const recipientId = recipient.id
  const [loading, setLoading] = useState(false)
  const [name, setName] = useState(recipient.name)
  const [contact, setContact] = useState(recipient.contact)
  const [email, setEmail] = useState(recipient.email)
  const [options, setOptions] = useState([])
  const { updateRecipient, organisations, createRecipient } = useContext(organisationContext)

  useEffect(() => {
    if (hideCompanySelector) {
      if (selectedOrg) {
        setSelectedCompany(selectedOrg)
      }
    } else {
      const emptySelection = {
        name: '(No company)',
        id: null
      }

      const coms = Array.isArray(companies) ? companies.slice() : []
      coms.push(emptySelection)
      setOptions(coms)

      if (recipient.orgId) {
        setSelectedCompany(organisations.find(o => o.orgId === recipient.orgId))
      } else {
        setSelectedCompany(emptySelection)
      }
    }
  }, [companies, hideCompanySelector, recipient.organisation, selectedOrg])

  const [selectedCompany, setSelectedCompany] = useState(null)

  const onSubmit = async (e) => {
    e.preventDefault()
    setLoading(true)
    if (isAddingRecipient) {
      setLoading(true)
      try {
        await createRecipient(name, email, contact, selectedCompany?.orgId)
        toastr.info('New recipient added  👍')
        dismissModal()
      } catch (e) {
        console.error('Failed to create recipient', e)
        toastr.error('There was an issue creating the recipient, please contact support!')
      }
    } else {
      try {
        await updateRecipient(recipientId, name, email, contact, selectedCompany?.orgId)
        toastr.info('Recipient details updated  👍')
        dismissModal()
      } catch (e) {
        console.error('Failed to update recipient', e)
        toastr.error('Failed to update recipient details, please contact support!')
      }
    }
    setLoading(false)
  }

  const onClickCancel = useCallback(() => {
    dismissModal()
  }, [dismissModal])

  const handleChangeCompany = (option, action) => {
    setSelectedCompany(option)
  }

  return (
    <form onSubmit={onSubmit} className='text-left'>
      <h2 className='text-center'>
        {isAddingRecipient
          ? <span>Add new recipient</span>
          : <span>Editing <em>{recipient.name}</em></span>}
      </h2>
      <div>
        <div className='form-control'>
          <label htmlFor='name'>Name</label>
          <input required placeholder='John Doe' type='text' id='name' defaultValue={name} onChange={(e) => { setName(e.target.value) }} />
        </div>
        { !hideCompanySelector &&
          <div className='form-control'>
            <label htmlFor='email'>Company</label>
            <CreatableSelect
              isClearable={false}
              onChange={handleChangeCompany}
              placeholder='Select a company'
              value={selectedCompany}
              options={options}
              getOptionLabel={(item) => item.name}
              getOptionValue={(item) => item.orgId}
            />
          </div>
        }
        <div className='form-control'>
          <label htmlFor='email'>Email address</label>
          <input type='email' id='email' defaultValue={email} onChange={(e) => setEmail(e.target.value)} placeholder='johndoe@example.com' />
        </div>
        <div className='form-control'>
          <label htmlFor='contact'>Contact number</label>
          <input pattern='^[0-9 +_\-\(\)]+$' placeholder='0412345678' type='tel' id='contact' defaultValue={contact} onChange={(e) => setContact(e.target.value)}/>
        </div>
      </div>
      <div className='btn-group'>
        <button className='btn' onClick={() => { onClickCancel() }}>Cancel</button>
        <button className='btn primary' type='submit' loading={loading ? 'loading' : undefined}>{isAddingRecipient ? 'Add recipient' : 'Save'}</button>
      </div>
    </form>
  )
}

export default RecipientsEditModal
