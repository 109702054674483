import React, { useContext, useState } from 'react'
import { toastr } from 'react-redux-toastr'
import { CopyToClipboard } from 'react-copy-to-clipboard'

import { workspaceContext } from 'src/context'
import Extensions from './Extensions'
import CreateLinkButton from './CreateLinkButton'

export const InvitationLink = ({ onAddSeats }: any) => {
  const { deleteInvitationLink, workspace } = useContext(workspaceContext)
  const link = workspace.workspace_invitation_link
  const [deleteLoading, setDeleteLoading] = useState(false)

  const handleClickCopy = (text: string) => {
    navigator.clipboard.writeText(text)
    toastr.info('Invitation link copied to clipboard')
  }

  const handleClickDelete = async (_: React.MouseEvent<HTMLButtonElement>) => {
    setDeleteLoading(true)
    try {
      await deleteInvitationLink(link.invitation_link_id)
      toastr.info('Invitation link was deleted')
      setDeleteLoading(false)
    } catch (error) {
      toastr.error('There was an error. Please try later')
      setDeleteLoading(false)
    }
  }

  const linkText = link ? `${import.meta.env.VITE_ROOT_DOMAIN || window.location.origin}/invite/${link.invitation_link_id}` : ''

  return (
    <div>
      {link
        ? (
          <div className='invite-link-wrapper'>
            <div className='invite-link'>
              <input type='text' className='input-text xs long' value={linkText} readOnly />
              <div className='btn-group compact'>
                <CopyToClipboard text={linkText} onCopy={handleClickCopy}>
                  <button className='btn primary'>Copy link</button>
                </CopyToClipboard>
                <button className='btn danger' onClick={handleClickDelete} loading={deleteLoading ? 'loading' : undefined}>
                  Delete
                </button>
              </div>
            </div>
            <Extensions extensions={link.email_extensions} />
          </div>
        )
        : (
          <CreateLinkButton onAddSeats={onAddSeats} />
        )}
    </div>
  )
}

export default InvitationLink
