import React from 'react'
import DatePicker from 'react-datepicker'

const DateTimePicker = ({ isDisabled, selected, onChange, minDate, required, ...rest }) => (
  <div className='react-datepicker-wrapper'>
    <DatePicker
      showTimeSelect
      disabled={isDisabled}
      popperProps={{ strategy: 'fixed ' }}
      selected={selected}
      onChange={onChange}
      minDate={minDate}
      placeholderText='Select date'
      customInput={
        <input {...rest} className='input-text' required={required ? 'required' : undefined} disabled={isDisabled ? 'disabled' : undefined} />
      }
      required={required}
    />
  </div>
)

export default DateTimePicker
