import React, { useContext } from 'react'
import { ModeContext } from 'src/context'
import animationData from 'src/design-system/animations/sizle.json'
import Lottie from 'react-lottie-player'

const SizleLoader = ({ children, ...props }) => {
  const { hideSizleBrand } = useContext(ModeContext)
  return (
    <div className='sizle-loader'>
      <div className='loader-inner'>
        <Lottie
          animationData={animationData}
          loop
          play
          speed={1}
          style={{ width: 120, height: 120 }}
          rendererSettings={{ preserveAspectRatio: 'xMidYMid slice' }}
        />
      </div>
      <span className='slogan'>{children || (!hideSizleBrand && <>Powered by <b>Sizle</b></>)}</span>
    </div>
  )
}
export const SizleLoaderContainer = ({ children }) => (
  <div className='sizle-loader-outer'><SizleLoader>{children}</SizleLoader></div>)

export default SizleLoader
