import React from 'react'
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { NavLink } from 'react-router-dom'
import { PropTypes } from 'prop-types'
import SVGInline from 'react-svg-inline'

const NavigationLink = React.forwardRef(({
  mini,
  isExternal,
  isActionableAnchor,
  href,
  to,
  icon,
  iconName,
  children,
  gray,
  active,
  onClickItem,
  ...rest
}, ref) => {
  // @TODO: add tooltips to these unused items
  if (isExternal) {
    return (
      <StyledLink ref={ref} {...rest} className={active ? 'active' : ''} href={href} target='_blank'>
        <Icon svg={icon} width='20px' height='20px' />
        {!mini && <Text className={active ? 'active' : ''} active={active} gray={gray}>
          {children}
        </Text>}
      </StyledLink>
    )
  } else if (isActionableAnchor) {
    return (
      <StyledLink ref={ref} {...rest} className={active ? 'active' : ''} onClick={onClickItem}>
        <Icon className={`icon-${iconName}`} svg={icon} />
        {!mini && <Text className={active ? 'active' : ''} active={active} gray={gray}>
          {children}
        </Text>}
      </StyledLink>
    )
  } else {
    return (
      <Element ref={ref} {...rest} className={`${mini ? 'tooltip tooltip-right' : ''} ${active ? 'active' : ''}`} to={to} data-tooltip={mini ? children : undefined} end>
        <Icon svg={icon} width='20px' height='20px' className={iconName && `icon-${iconName}`} />
        {!mini && <Text className={active ? 'active' : ''} active={active} gray={gray}>
          {children}
        </Text>}
      </Element>
    )
  }
})

NavigationLink.propTypes = {
  to: PropTypes.string,
  icon: PropTypes.node,
  children: PropTypes.string,
  active: PropTypes.bool,
  gray: PropTypes.bool
}

const Text = styled.span`
  color: var(--text-color);
  transition: all 0.3s ease-in-out;
`

const Icon = styled(SVGInline)`
  margin-right: 5px;

  svg {
    stroke: var(--text-color-light);
    > g {
      stroke: var(--text-color-light);
      stroke-width: 1.9;
    }
  }

  &.icon-credit-card {
    margin-left: -6px;
    svg {
      transform: scale(.85) translateX(3px);
      width: 26px !important;
      height: 18px !important;
    }
  }
  &.icon-companies {
    svg {
      width: 19px;
      height: 20px;
      stroke-width: 1.4;

      > g > g {
        stroke: var(--text-color-light);
      }
    }
  }
`

const sharedStyles = props => css`
  display: flex;
  width: 90%;
  height: 45%;
  align-items: center;
  cursor: pointer;
  position: relative;
  color: var(--text-color);
  transition: all 0.3s ease-in-out;
  padding: 10px 0px 10px 20px;
  margin-top: 5px;
  margin-left: 5px;
  
  &:hover {
  background-color: var(--primary-color-vfaint);
  border-radius: 8px;
    span {
      opacity: 1;
    }
  }

  span:last-child {
    color: var(--text-color);
    white-space: nowrap;
  }

  &.active {
  background-color: ${props => (props.isFocused ? 'var(--background-highlight-color)' : 'transparent')};
    background-color: #5d55fa19;
    border-radius: 8px;
  }
`

const StyledLink = styled('a')`
  ${sharedStyles}
`

const Element = styled(NavLink)`
  ${sharedStyles}
`

export default NavigationLink
