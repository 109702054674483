import React from 'react'
import styled from '@emotion/styled'
import SVGInline from 'react-svg-inline'
import { AiOutlineClose } from 'react-icons/ai'

import { arrowRight2 } from 'src/design-system/icons'
import { ShadowedInput } from 'src/design-system/elements'
import MainFooter from 'src/modules/navigation/MainFooter'

const EmailScreen = ({ onSubmit, isLoading, previewMode = false }) => {
  const handleEmailSubmit = (e) => {
    e.preventDefault()
    onSubmit(e.target.email.value)
  }
  return (
    <PageContainer>
      {previewMode && (
        <CloseIcon size={20} color='#A1A8AE' onClick={onSubmit} />
      )}
      <Container>
        <form onSubmit={handleEmailSubmit}>
          <Heading>Enter email address to view document</Heading>
          <br />
          <FieldWrapper>
            <InputContainer>
              <Input
                placeholder='Your email'
                name='email'
                type='email'
                enableInnerOnChange={false}
                autoComplete='really-disable-google-im-serious'
              />
            </InputContainer>
            <button className='btn primary xl' type='submit' disabled={isLoading ? 'loading' : undefined}><ArrowIcon svg={arrowRight2} /></button>
          </FieldWrapper> 
          <br /><br />
          <center> <span>We respect your privacy and never share your email with third parties.</span></center>
        </form>
      </Container>
      <MainFooter previewMode={previewMode} />
    </PageContainer>
  )
}

export default EmailScreen

const PageContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--background-color);
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 9999;
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
`

const FieldWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  input { 
    background-color: var(--input-background);
    color: var(--text-color);
  }
`

const InputContainer = styled.div`
  max-width: 430px;
  margin-right: 20px;

  > div > div:first-child {
    left: auto;
    right: 12px;
  }

  svg {
    g {
      stroke: #A1A8AE;
    }
  }
`

const Input = styled(ShadowedInput)`
  width: 100%;
  padding: 24px 50px 20px 15px;
  box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.03);
  font-size: 16px;

  @keyframes shake {
    from, to {
      transform: translate3d(0, 0, 0);
    }
    20% { transform: translate3d(-5px, 0, 0); }
    40% { transform: translate3d(5px, 0, 0); }
    60% { transform: translate3d(-2px, 0, 0); }
    80% { transform: translate3d(2px, 0, 0); }
  }

  animation-duration: ${props => props.isError ? '1s' : 0};
  animation-fill-mode: ${props => props.isError ? 'both' : 'none'};
  animation-name: ${props => props.isError ? 'shake' : 'none'};
`

const Heading = styled.h1`
  color: var(--primary-color);
  text-align: center;
  margin-bottom: 20px;
  font-weight: 500;
`

const ArrowIcon = styled(SVGInline)`
  path {
    stroke: #ffffff);
    stroke-width: 2;
  }
`

const CloseIcon = styled(AiOutlineClose)`
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
`
