import React, { useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useUserContext, workspaceContext } from 'src/context'

const LandingPage = () => {
  const navigate = useNavigate()
  const { workspaces } = useContext(workspaceContext)

  useEffect(() => {
    if (workspaces && workspaces.length > 0) {
      const activeWorkspace = localStorage.getItem('sizle.activeWorkspace')
      navigate(`/s/${activeWorkspace || 0}/presentations`, { replace: true })
    }
  }, [navigate, workspaces])

  return <div />
}

export default LandingPage
