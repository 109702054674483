import React, { memo, useState, useEffect, useCallback } from 'react';
import SVGInline from 'react-svg-inline';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { format, parseISO } from 'date-fns';
import PropTypes from 'prop-types';
import { useAuth } from 'oidc-react';

import { edit, trash } from 'src/design-system/icons';
import { Textarea } from 'src/design-system/elements';

const ResponseItem = ({ item, onEditSubmit, onDeleteClick, title, isAnalytics = false, isOwnersResponse = false, ownerId }) => {
  const [editing, setEditing] = useState(false);
  const [editingText, setEditingText] = useState(item?.text);
  const { userDetails: user } = useAuth();

  useEffect(() => {
    if (item.text) {
      setEditingText(item.text);
    }
  }, [item.text]);

  const handleTextChange = useCallback((e) => {
    setEditingText(e.target.value);
  }, []);

  const handleTextBlur = () => {
    setEditing(false);
    onEditSubmit(item, editingText);
  };

  const handleEditClick = () => {
    setEditing(true);
  };

  const handleDeleteClick = () => {
    if (onDeleteClick) {
      onDeleteClick(item.id);
    }
  };

  return (
    <Wrapper isAnalytics={isAnalytics} isOwnersResponse={isOwnersResponse}>
      <Element isOwnersResponse={isOwnersResponse} isAnalytics={isAnalytics}>
        <IconsContainer>
          {item.id && user && item.userId === user.sub && item.text && (
            <Edit onClick={handleEditClick} svg={edit} />
          )}
          {item.id && user && ((item.userId === user.sub) || (ownerId === user.sub)) && item.text && (
            <Remove onClick={handleDeleteClick} svg={trash} />
          )}
        </IconsContainer>
        <ResponseHeader isOwnersResponse={isOwnersResponse}>{title}</ResponseHeader>
        <ResponseLabel>{format(parseISO(item.created_at), 'MMM d, yyyy @ h:mma')}</ResponseLabel>
        <ResponseBodyContainer>
          <ResponseBody active={!editing}>{item.text || (item.renderContent && item.renderContent())}</ResponseBody>
          <ResponseEdit
            onBlur={handleTextBlur}
            onChange={handleTextChange}
            active={editing}
            value={editingText}
          />
        </ResponseBodyContainer>
      </Element>
    </Wrapper>
  );
};

const Wrapper = styled.div(props => props.isAnalytics && css`
  margin-bottom: 6px;
  margin-left: ${props.isOwnersResponse ? 'auto' : 0};
  border-left-color: var(--primary-color);
  border-left-style: solid;
  border-left-width: ${props.isOwnersResponse ? 0 : '3px'};
  border-right-color: ${props.theme.brand.tertiary};
  border-right-style: solid;
  border-right-width: ${props.isOwnersResponse ? '3px' : 0};

  @media screen and (max-width: 768px) {
    margin-left: 0;
    border-left-width: 0;
    border-right-width: 0;
  }
`);

const Element = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  background: var(--background-color);
  width: ${props => props.isAnalytics ? '320px' : '305px'};
  border-width: 1px;
  border-style: solid;
  border-color: ${props => props.isOwnersResponse ? props.theme.brand.tertiary : props.theme.brand.primary};
  border-radius: 2px;
  margin-bottom: ${props => props.isAnalytics ? 0 : '6px'};
  padding: 15px 17px 22px 21px;
  margin-left: ${props => props.isOwnersResponse && props.isAnalytics ? 0 : '14px'};
  margin-right: ${props => props.isOwnersResponse && props.isAnalytics ? '14px' : 0};

  @media screen and (max-width: 768px) {
    width: auto;
    margin-left: 0;
    margin-right: 0;
  }
`;

const IconsContainer = styled.div`
  position: absolute;
  top: 22px;
  right: 18px;
  display: flex;
  flex-direction: row;
`;

const ResponseHeader = styled.div`
  font-size: 13px;
  font-weight: 500;
  color: ${props => props.isOwnersResponse ? props.theme.brand.tertiary : props.theme.brand.primary};
  margin-bottom: 1px;
`;

const ResponseLabel = styled.div`
  font-size: 12px;
  font-weight: 500;
  color: var(--text-color-lighter);
  margin-bottom: 10px;
`;

const ResponseBodyContainer = styled.div`
  width: 100%;
  display: flex;
  position: relative;
`;

const ResponseEdit = styled(Textarea)`
  width: 100%;
  display: ${props => props.active ? 'flex' : 'none'};
  padding: 3px 5px;
  height: 85px;
  background-color: var(--input-background);
  border: 1px solid var(--input-border-background);
  color: var(--input-color);
  border-radius: 10px;
`;

const ResponseBody = styled.div`
  display: ${props => props.active ? 'none' : 'flex'};
  font-size: 11px;
  font-weight: 500;
  line-height: 1.5;
  letter-spacing: -0.1px;
  color: var(--text-color);
`;

const Edit = styled(SVGInline)`
  margin-right: 12px;

  svg {
    cursor: pointer;
    width: 14px;
    height: 14px;

    g {
      stroke: #A0A0A0;
    }
  }
`;

const Remove = styled(SVGInline)`
  svg {
    cursor: pointer;
    width: 14px;
    height: 14px;
    stroke: #A0A0A0;

    g {
      stroke: #A0A0A0;
    }
  }
`;

ResponseItem.propTypes = {
  item: PropTypes.object.isRequired,
  onEditSubmit: PropTypes.func.isRequired,
  onDeleteClick: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  isAnalytics: PropTypes.bool.isRequired,
  isOwnersResponse: PropTypes.bool.isRequired,
  ownerId: PropTypes.string.isRequired,
};

export default memo(ResponseItem);
