import React from 'react'
import styled from '@emotion/styled'

const Button = ({ color, children, ...rest }) => {
  return (
    <Element color={color} {...rest}>
      {children}
    </Element>
  )
}

const Element = styled.button`
  border-radius: 20px;
  background-color: ${props => props.color};
  height: 38px;
  padding: 0 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--text-color);

  &:not(:last-child) {
    margin-right: 12px;
  }

  @media only screen and (max-width: 768px) {
    margin-top: 12px;
    height: 32px;
    padding: 0 22px;
    padding: 0 18px;
  }
`

export default Button
