import React from 'react'
import styled from '@emotion/styled'

const MobileMenuItem = ({ onClick, toggleMenu, children, ...rest }) => {
  const handleClick = () => {
    onClick()
    toggleMenu()
  }

  return (
    <Wrapper onClick={handleClick} {...rest}>
      {children}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  height: 46px;
  width: 100%;
  font-size: 12px;
  color: var(--text-color);
  font-weight: 500;
  border: none;
  border-bottom: 1px solid var(--pane-border);
  padding-left: 20px;
  display: flex;
  align-items: center;
  cursor: pointer;

  &:last-child {
    border-bottom: none;
  }

  svg {
    margin-right: 10px;
  }
`

export default MobileMenuItem
