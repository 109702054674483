import { useState, useContext } from 'react'
import axios from 'axios'
import { subscriptionContext } from 'src/context'

export const usePublicLink = (initial = { url: null, linkId: null }) => {
  const [url, setUrl] = useState(initial.url)
  const [linkId, setLinkId] = useState(initial.linkId)
  const { tryExtendTrial } = useContext(subscriptionContext)

  return [
    { url, linkId },
    async (documentId, {
      burnTimer,
      pin,
      expirationDate,
      requiresEmailCapture,
      commentsEnabled,
      reactionsEnabled,
      downloadsEnabled
    }) => {
      const res = await axios.post('/api/links/public', {
        type: 'public',
        documentId,
        requiresEmailCapture,
        expirationDate,
        pin,
        burnTimer,
        commentsEnabled,
        reactionsEnabled,
        downloadsEnabled
      }, {
        responseType: 'json'
      })
      if (res.status !== 200) {
        throw Error('Failed to create link')
      }
      setUrl(res.data.url)
      setLinkId(res.data.linkId)
      await tryExtendTrial('ShareDocument')
      return { url: res.data.url, linkId: res.data.linkId }
    }]
}

export const useStaticLink = (initial = { url: null, linkId: null }) => {
  const [url, setUrl] = useState(initial.url)
  const [linkId, setLinkId] = useState(initial.linkId)
  const { tryExtendTrial } = useContext(subscriptionContext)

  return [
    { url, linkId },
    async (documentId, { pin, burnTimer, expirationDate, recipientId, commentsEnabled, reactionsEnabled, downloadsEnabled }) => {
      const res = await axios.post('/api/links/static', {
        type: 'public',
        documentId,
        pin,
        burnTimer,
        expirationDate,
        recipientId,
        commentsEnabled,
        reactionsEnabled,
        downloadsEnabled
      }, { responseType: 'json' })
      if (res.status !== 200) {
        throw Error('Failed to create link')
      }
      setUrl(res.data.url)
      setLinkId(res.data.linkId)
      await tryExtendTrial('ShareDocument')
      return { url: res.data.url, linkId: res.data.linkId }
    }]
}

export const useEmailLink = (initial = { url: null, linkId: null }) => {
  const [url, setUrl] = useState(initial.url)
  const [linkId, setLinkId] = useState(initial.linkId)
  const { tryExtendTrial } = useContext(subscriptionContext)

  return [
    { url, linkId },
    async (documentId, { pin, burnTimer, expirationDate, recipients, downloadsEnabled, commentsEnabled, reactionsEnabled }) => {
      const res = await axios.post('/api/links/email', {
        type: 'public',
        documentId,
        pin,
        burnTimer: burnTimer || undefined,
        expirationDate: expirationDate || undefined,
        recipients,
        commentsEnabled,
        reactionsEnabled,
        downloadsEnabled
      }, {
        responseType: 'json'
      })
      if (res.status !== 200) {
        throw Error('Failed to create link')
      }
      setUrl(res.data.url)
      setLinkId(res.data.linkId)
      await tryExtendTrial('ShareDocument')
      return { url: res.data.url, linkId: res.data.linkId }
    }]
}
