import React, { createContext } from 'react'
import axios from 'axios'
import { useAuth } from 'oidc-react'

export const locationContext = createContext()

export const LocationProvider = ({ children }) => {
  const { userDetails } = useAuth()
  const getLocation = async () => {
    if (localStorage.getItem('location')) return localStorage.getItem('location')
    if (navigator.geolocation && (localStorage.getItem('isUserAgreedOnCookies') === 'true' || userDetails)) {
      return new Promise((resolve) => navigator.geolocation.watchPosition(async (position) => {
        const response = await axios.get(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${position.coords.latitude},${position.coords.longitude}&key=${import.meta.env.VITE_GOOGLE_MAPS_API_KEY}`)
        const { data: { status, results } } = response

        if (status === 'OK' && results.length) {
          let country = null
          let city = null

          try {
            results[0].address_components.forEach(component => {
              if (component.types.includes('country')) {
                country = component.long_name
              } else if (component.types.includes('locality')) {
                city = component.long_name
              } else if (component.types.includes('postal_town') && !city) {
                city = component.long_name
              } else if (component.types.includes('administrative_area_level_2') && !city) {
                city = component.long_name
              } else if (component.types.includes('administrative_area_level_1') && !city) {
                city = component.long_name
              }
            })

            if (country && city) {
              saveLocation(`${city}, ${country}`)
              resolve(`${city}, ${country}`)
            } else if (country) {
              saveLocation(country)
              resolve(country)
            } else {
              console.warn('Unable to interpret sensible location result')
              resolve('N/A')
            }
          } catch (error) {
            console.error('Failure when parsing location results', error)
            resolve('N/A')
          }
        } else {
          console.warn('Location result provided non OK status', status)
          resolve('N/A')
        }
      }, (e) => {
        console.error(e)
        resolve('N/A')
      }))
    } else {
      console.warn('Geolocation not available in navigator')
      return 'N/A'
    }
  }

  const saveLocation = (newLocation) => {
    localStorage.setItem('location', newLocation)
  }

  return (
    <locationContext.Provider value={{ getLocation }}>
      {children}
    </locationContext.Provider>
  )
}
