import React, { useState, useEffect, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  Modal,
  ModalContent,
  ModalOverlay,
  ModalCloseButton,
  Button,
  Flex,
  Box,
  Text
} from '@chakra-ui/core'
import { toastr } from 'react-redux-toastr'
import Lottie from 'react-lottie-player'
import { useTranslation } from 'react-i18next'
import { workspaceContext } from 'src/context'
import inviteAnimation from 'src/design-system/animations/invite-inbox.json'
import { useModal } from 'src/modules/modals'

export default function WorkspaceInvitationModal ({
  dismissModal
}) {
  const navigate = useNavigate()
  const { workspaces, acceptInvite, invites } = useContext(workspaceContext)
  const [isUpdatingWorkspaceInvitation, setUpdatingWorkspaceInvitation] = useState(false)
  const [lastJoined, setLastJoined] = useState(null)
  const { t } = useTranslation()
  const { setModal } = useModal()

  useEffect(() => {
    const joinedWorkspace = (workspaces || []).findIndex(w => w.workspace_id === lastJoined)
    if (joinedWorkspace !== -1 && lastJoined) {
      navigate(`/s/${joinedWorkspace}/presentations`)
      if (!invites || invites.length === 0) {
        setModal(null)
      }
    }
  }, [workspaces, invites, navigate, lastJoined, setModal])

  const onAcceptWorkspaceInvitation = async () => {
    try {
      setUpdatingWorkspaceInvitation(true)
      await acceptInvite(invites[0].invite_id, true)
      setLastJoined(invites[0].workspace_id)
      toastr.info(`You have joined '${invites[0].invite_details.workspace.name}' workspace`)
    } catch (e) {
      toastr.error('That invitation has been revoked.')
    } finally {
      setUpdatingWorkspaceInvitation(false)
    }
  }

  const onDeclineWorkspaceInvitation = async () => {
    try {
      setUpdatingWorkspaceInvitation(true)
      acceptInvite(false)
      await acceptInvite(invites[0].invite_id, false)
    } catch (e) {
      toastr.error('That invitation has already been revoked.')
    } finally {
      setUpdatingWorkspaceInvitation(false)
    }
  }

  return (
    <Modal
      size='sm'
      isCentered
      closeOnOverlayClick={false}
      closeOnEsc
      isOpen
      onClose={dismissModal}
    >
      <ModalOverlay />
      <ModalContent rounded='lg' pb={5}>
        <ModalCloseButton color='primary' top='0' right='0' />
        <Flex backgroundColor='#F2F1FE' flexDirection='column' textAlign='center' p='4' borderTopLeftRadius='lg' borderTopRightRadius='lg'>
          <Text fontSize='20px' color='primary'>{t('receiveInviteModal.header.0')} <br />{t('receiveInviteModal.header.1')}</Text>
        </Flex>
        <Flex width='100%' alignItems='center' justifyContent='center'>
          <Lottie play animationData={inviteAnimation} style={{ width: '60%' }} />
        </Flex>

        {
          invites[0] &&
            <Box p='10' pt='6' pb='6' textAlign='center'>
              {invites[0]?.invite_details.sender.name} ({invites[0]?.invite_details.sender.email}) {t('receiveInviteModal.from.0')} <strong>{invites[0].invite_details.workspace.name}</strong>, {t('receiveInviteModal.from.1')}
            </Box>
        }

        <Flex mt={1} direction='column' alignItems='center'>
          <Button
            type='button'
            size='md'
            variantColor='brand'
            minWidth='136px'
            minHeight='38px'
            borderRadius='8px'
            fontWeight='medium'
            mb={3}
            flex='0 0 auto'
            width='164px'
            isLoading={isUpdatingWorkspaceInvitation}
            onClick={onAcceptWorkspaceInvitation}
          >{t('receiveInviteModal.Join workspace')}
          </Button>
          {
            !isUpdatingWorkspaceInvitation &&
              <Button variant='link' color='#d92f10' onClick={onDeclineWorkspaceInvitation}>{t('receiveInviteModal.Decline this invitation')}</Button>
          }
        </Flex>
      </ModalContent>
    </Modal>
  )
}
