import { combineReducers, createStore } from 'redux'
import { reducer as toastrReducer } from 'react-redux-toastr'

// @TODO: Remove when we no longer use redux toastr
export default function configureStore () {
  const rootReducer = combineReducers({
    toastr: toastrReducer
  })

  return createStore(rootReducer)
}
