import { useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import { useAuth } from 'oidc-react';

export const useResponses = (
  leadId: string | null = null,
  sampleData = false,
  fetchResponses = true
) => {
  const { userData } = useAuth();
  const [loading, setLoading] = useState(false);
  const [responses, setResponses] = useState<any[]>([]);

  const getResponses = useCallback(async (leadId: string) => {
    setLoading(true);
    const result = await axios.get(`/api/leads/${leadId}/responses`);
    setResponses(result.data.responses);
    setLoading(false);
  }, []);

  const getSampleResponses = useCallback(async () => {
    const result = await axios.get(`/api/sample_data/responses/${leadId}`, {
      headers: { 'Content-Type': 'application/json' },
    });
    if (result?.data) {
      const { presentation_responses } = result.data;
      setResponses(presentation_responses);
    } else {
      setResponses([]);
    }
  }, [leadId]);

  useEffect(() => {
    if (leadId && !sampleData && fetchResponses) getResponses(leadId);
    else if (sampleData) getSampleResponses();
    else setResponses([]);
  }, [fetchResponses, getResponses, getSampleResponses, leadId, sampleData]);

  const getResponseCount = useCallback(
    (linkId: string) => {
      // Assuming you have a way to get responses based on linkId.
      // You may want to replace this logic with actual data retrieval.
      const responsesForLink = responses.filter(response => response.linkId === linkId);
      return responsesForLink.length;
    },
    [responses]
  );

  return {
    loading,
    responses,
    getResponseCount,
    editResponse: useCallback(
      async (id, text) => {
        if (!sampleData) {
          const { data } = await axios({
            url: `/api/responses/${id}`,
            data: { response: text },
            method: 'PUT',
            withCredentials: true,
          });
          setResponses(data.responses);
        } else {
          setResponses(
            responses.map((r) => {
              if (r.id === id) r.text = text;
              return r;
            })
          );
        }
      },
      [sampleData, responses, setResponses]
    ),
    deleteResponse: useCallback(
      async (id) => {
        if (!sampleData) {
          const { data } = await axios({
            url: `/api/responses/${id}`,
            method: 'DELETE',
            withCredentials: true,
          });
          setResponses(data.responses);
        } else {
          setResponses(responses.filter((r) => r.id !== id));
        }
      },
      [sampleData, responses]
    ),
    addResponse: useCallback(
      async (text) => {
        if (userData) {
          const { data } = await axios({
            url: `/api/leads/${leadId}/responses`,
            method: 'POST',
            data: { response: text },
            withCredentials: true,
          });
          setResponses(data.responses);
        } else {
          const { data } = await axios({
            url: `/api/leads/${leadId}/responses/public`,
            method: 'POST',
            data: { response: text },
            withCredentials: true,
          });
          setResponses(data.responses);
        }
      },
      [userData, leadId]
    ),
  };
};
