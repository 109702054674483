import toast from 'react-hot-toast'

const style = {
  fontFamily: 'Work Sans',
  border: '1px solid #635BFF',
  padding: '20px',
  borderRadius: '8xp',
  color: '#FFFAEE',
  backgroundColor: '#635BFF',
  fontSize: '16px',
  fontWeight: '400'
}

export const success = (text) => {
  toast.success(text, {
    style,
    iconTheme: {
      primary: '#635BFF',
      secondary: '#FFFAEE'
    }
  })
}

export const error = (text) => {
  toast.error(text, {
    style: {
      ...style,
      borderColor: '#e55a90',
      backgroundColor: '#e55a90'
    },
    iconTheme: {
      primary: '#e55a90',
      secondary: '#FFFAEE'
    }
  })
}

export const info = (text) => {
  toast(text, {
    style
  })
}
