import React, { useContext, useEffect, useState, useRef } from 'react'
import { toastr } from 'react-redux-toastr'
import { useTranslation } from 'react-i18next'
import { Pane, CheckoutForm } from 'src/modules/billing'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { subscriptionContext } from 'src/context'

const ELEMENTS_OPTIONS = { fonts: [{ cssSrc: 'https://fonts.googleapis.com/css?family=Roboto' }] }
const stripePromise = loadStripe(import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY)

export const PaymentMethodForm = ({ includePromoField }: {includePromoField: boolean}) => {
  const { t } = useTranslation()
  const { paymentMethod, updatePaymentDetails } = useContext(subscriptionContext)
  const [expand, toggleExpand] = useState(false)

  const submitPaymentDetails = async ({ token }: {token: string}) => {
    const message = await updatePaymentDetails(token)
    toastr.info(t(message))
    toggleExpand(false)
  }
  return (
    <div className={`payment-method-form ${expand && 'expanded'}`}>
      <button onClick={() => toggleExpand(!expand)} className='btn secondary'>Update payment method</button>
      <Elements stripe={stripePromise} options={ELEMENTS_OPTIONS}>
        <CheckoutForm
          onSubmit={submitPaymentDetails}
          actionText={t('billing.Update payment details')}
          includePromoField={includePromoField}
        />
      </Elements>
      {paymentMethod && (
        <label className='label small faint'>
          {t('billing.Current payment method')}:{' '}{paymentMethod}{' '}
        </label>
      )}
      <br></br>
      <label className='label big'>Need help? Speak to our friendly support team<a className='link' href='https://sizle.io/support'> here</a></label>
    </div>
  )
}
