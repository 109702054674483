import React, { useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import GooglePicker from 'react-google-picker';
import { sendAmplitudeData } from 'src/utils/amplitude';
import { useLocation } from 'react-router-dom';
import { MenuList, MenuItem } from '@chakra-ui/core';
import { ModeContext, useUserContext, workspaceContext } from 'src/context';
import styles from 'src/styles/components/upload-dropdown.module.scss';

const UploadDropdown = ({ onOpen, onClose, onCreateNewFolder, folderId }) => {
  const { importDocument, uploadDocument, createDraftPresentation, hasWorkspacePermission } = useContext(workspaceContext);
  const { hideSizleBrand } = useContext(ModeContext);

  const location = useLocation();

  useEffect(() => {
    if (location.hash === '#create-document') {
      setTimeout(() => onOpen(), 1);
    }
  }, [location, onOpen]);

  const onClickCreateFolder = (e) => {
    onClose();
    onCreateNewFolder(folderId);
  };

  const onFileUpload = async (event) => {
    try {
      onClose();
      if (event.target.files[0]) await uploadDocument(event.target.files[0]);
    } catch (e) {
      if (e.error === 'Document limit exceeded' || e.error === 'Storage limit exceeded') {
        console.error('Storage limit exceeded!');
      }
    }
  };

  const onChooseDropboxFile = () => {
    if (window.Dropbox && window.Dropbox.isBrowserSupported()) {
      window.Dropbox.choose({
        success: async (file) => {
          await importDocument({
            url: file[0].link,
            title: file[0].name,
          });
          sendAmplitudeData('IMPORTING_DROPBOX_DOCUMENT');
          onClose();
        },
        cancel: () => {},
        linkType: 'direct',
        multiselect: false,
        extensions: import.meta.env.VITE_VIEWER_FILE_FORMATS.split(','),
        folderselect: false,
        sizeLimit: 200000000, // 200 Mb
      });
    }
  };

  return (
    <>
      <MenuList className={styles['menu-list']} placement='bottom-start'>
        {onCreateNewFolder && hasWorkspacePermission('Manage Folders') && (
          <MenuItem onClick={onClickCreateFolder} className={styles['menu-item']}>
            New folder
          </MenuItem>
        )}
        {hasWorkspacePermission('Add & Edit Documents') && (
          <>
            <MenuItem as='label' htmlFor='upload-dropdown-file-upload' className={styles['menu-item']}>
              Upload a file
            </MenuItem>
            <MenuItem 
              isDisabled={hideSizleBrand} 
              onClick={onChooseDropboxFile} 
              className={styles['menu-item']}
              title={hideSizleBrand ? 'Dropbox is not currently supported while using the site on a custom domain' : ''}
            >
              Select from Dropbox
            </MenuItem>
          </>
        )}
      </MenuList>
      <input hidden id='upload-dropdown-file-upload' type='file' onChange={onFileUpload} />
    </>
  );
};

UploadDropdown.propTypes = {
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
  onCreateNewFolder: PropTypes.func,
  folderId: PropTypes.number,
};

export default UploadDropdown;