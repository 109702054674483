import gql from 'graphql-tag'

export const CREATE_NEW_WORKSPACE_INVITE = gql`
  mutation create_new_workspace_invite(
    $workspaceId: uuid!,
    $emailAddress: String!,
    $inviteDetails: jsonb!
  ) {
    insert_workspace_invites(
      objects: {
        email_address: $emailAddress,
        workspace_id: $workspaceId,
        invite_details: $inviteDetails,
        is_seen: false
      }
    ) {
      returning {
        workspace_id
        email_address
      }
    }
  }
`

export const DELETE_WORKSPACE_INVITE = gql`
  mutation delete_workspace_invite(
    $inviteId: uuid!
  ) {
    delete_workspace_invites_by_pk(invite_id: $inviteId) {
      invite_id
      email_address
    }
  }
`

export const CHECK_INVITATION = gql`
  query getWorkspaceInvitation($email: String!) {
    workspace_invites(
      where: {
        email_address: {_ilike: $email},
        is_seen: {_neq: true},
        has_accepted: {_is_null: true}
      }
    ) {
      email_address
      invite_id
      is_sent
      is_seen
      invite_details
      workspace_id
    }
  }
`
