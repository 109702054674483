import React, { useEffect, useContext } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { GET_PRESENTATION } from '../graphql/presentations.js'
import { useUserContext, workspaceContext } from '../context'
import { useQuery } from '@apollo/client'

export const SlugPage = () => {
  const { slug } = useParams()
  const { workspaces } = useContext(workspaceContext)
  const navigate = useNavigate()
  const { setActiveFolder } = useUserContext()
  const { data: presentationResult } = useQuery(GET_PRESENTATION, {
    variables: { presentationId: slug }
  })

  useEffect(() => {
    const presentation = presentationResult?.presentations?.[0]
    if (presentation && workspaces.length > 0) {
      const workspaceId = workspaces.findIndex((w) => w.workspace_id === presentation.workspaceId)
      setActiveFolder(presentation.folderId)
      navigate(`/s/${workspaceId}/presentation/${presentation.presentationId}/analytics`)
    }
  }, [presentationResult, workspaces, navigate, setActiveFolder])
  return <div>Slug page</div>
}
