import React, { useContext, useState } from 'react'
import styled from '@emotion/styled'
import { toastr } from 'react-redux-toastr'
import { useNavigate } from 'react-router-dom'

import { workspaceContext, subscriptionContext } from 'src/context'

export const InvitationLink = ({ onAddSeats }) => {
  const { workspace, createInvitationLink, currentWorkspacePlan } = useContext(workspaceContext)
  const { userEligibleForProTrial, beginProTrial } = useContext(subscriptionContext)
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [expanded, setExpanded] = useState(false)

  const handleClickCreate = async () => {
    if (currentWorkspacePlan?.allowInvites && !workspace?.isFilled) {
      setLoading(true)
      try {
        await createInvitationLink()
        toastr.info('Invitation link was created')
      } catch (error) {
        toastr.error('There was an error. Please try later')
      }
      setLoading(false)
    } else {
      setExpanded(true)
    }
  }

  const submitProTrial = async () => {
    setLoading(true)
    await beginProTrial()
    setLoading(false)
    setExpanded(false)
  }

  const goToBilling = (_: React.MouseEvent) => {
    navigate('../billing')
  }

  return (
    <Wrapper>
      <Expandable expanded={expanded}>
        {!currentWorkspacePlan?.allowInvites && userEligibleForProTrial && <span>Your plan includes <strong>1 seat</strong> for this workspace, activate a free <strong>Pro Trial</strong> now to invite more users.</span>}
        {!currentWorkspacePlan?.allowInvites && !userEligibleForProTrial && <span>Your plan includes <strong>1 seat</strong> for this workspace, purchase a <strong>Pro plan</strong> to invite more users.</span>}
        {currentWorkspacePlan?.allowInvites && workspace?.isFilled && <span>Your workspace has a limit of <strong>{workspace.availableSeats} seat{workspace.availableSeats !== 1 ? 's' : ''}</strong>, please purchase additional seats to invite more users.</span>}
      </Expandable>
      {expanded && !currentWorkspacePlan?.allowInvites && userEligibleForProTrial && (
        <div className='btn-group compact'>
          <button className='btn primary' onClick={submitProTrial} loading={loading ? 'loading' : undefined}>Begin pro trial</button>
          <button className='btn' onClick={() => setExpanded(false)}>Cancel</button>
        </div>
      )}
      {expanded && !currentWorkspacePlan?.allowInvites && !userEligibleForProTrial && (
        <div className='btn-group compact'>
          <button className='btn primary' onClick={goToBilling}>Go to billing</button>
          <button className='btn' onClick={() => setExpanded(false)}>Cancel</button>
        </div>
      )}
      {expanded && currentWorkspacePlan?.allowInvites && workspace.isFilled && (
        <div className='btn-group compact'>
          <button className='btn primary' onClick={onAddSeats}>Add seats</button>
          <button className='btn' onClick={() => setExpanded(false)}>Cancel</button>
        </div>
      )}
      {!expanded && (
        <button className='btn secondary' onClick={handleClickCreate} loading={loading ? 'loading' : undefined}>
          Create invite link
        </button>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  margin-top: 12px;

  @media only screen and (max-width: 699px) {
    flex-direction: column;
  }
`

const Expandable = styled.div`
  overflow: hidden;
  color: var(--text-color);
  transition: max-width 0.2s ease-out, max-height 0.3s ease-out;
  max-width: ${props => props.expanded ? '300px' : 0};
  max-height: ${props => props.expanded ? '54px' : 0};
  margin-right: ${props => props.expanded ? '3px' : 0};

  @media only screen and (max-width: 699px) {
    width: 100%;
    max-width: 100%;
    margin-bottom: 8px;
  }
`

export default InvitationLink
