import React, { useContext } from 'react'
import { WithStore, Slider, Slide } from 'pure-react-carousel'
import SVGInline from 'react-svg-inline'
import styled from '@emotion/styled'
import { checkCircle } from '@sizle-icons'
import useUserContext from 'src/context/UserContext'

const OnboardingSlider = ({ dismissModal }) => {
  const { user } = useUserContext()

  return (
    <SliderContainer name='onboarding-slider'>
      <Slider
        isIntrinsicHeight={true}
        isPlaying={false}
        infinite={false}
        dragEnabled={false}
        touchEnabled={false}
      >
        <Slide index={0}>
          <div className='slide'>
            <h2 className='slide-title'>Get started</h2>
            <center>
              <h3>Sizle powers projects with smart document sharing, tracking, and insights at your fingertips.</h3> 
            </center>
            <div className='slide-body'>
              <RecommendationList>
                <Recommendation>
                  <SVGInline className='check' svg={checkCircle} />
                  Upload or import a document
                </Recommendation>
                <Recommendation>
                  <SVGInline className='check' svg={checkCircle} />
                  Share it with a secure link
                </Recommendation>
                <Recommendation>
                  <SVGInline className='check' svg={checkCircle} />
                  Get live analytics and collect leads
                </Recommendation>
                <Recommendation>
                  <SVGInline className='check' svg={checkCircle} />
                  Track who views your document
                </Recommendation>
              </RecommendationList>
            </div>
            <div className='btn-group center'>
              <br></br>
              <button className='btn xl primary' onClick={dismissModal}>Go to dashboard</button>
            </div>
          </div>
        </Slide>
      </Slider>
    </SliderContainer>
  )
}

const SliderContainer = styled.div`
  .carousel__slide-focus-ring {
    outline: none;
    border: none;
  }
  .carousel__slider--horizontal {
    outline: none;
    height: 480px;
  }
  .analytics-svg {
    justify-content: center;
    padding-bottom: 35px;
    svg {
      height: 280px;
      width: 280px;
      padding-bottom: 35px;
    }
  }
  .slide {
    height: 90%;
    display: flex;
    flex-direction: column;
  }
  .slide-body {
    padding: 0px 0px 0px 50px;
    font-size: 1.2em;
    font-weight: 400;
    text-align: left;
    flex-grow: inherit;
  }
  .slide-footer {
    margin-top: 10px;
    margin-left: 40px;
    margin-right: 40px;
  }
  .slide-header {
    padding: 0 20px;
    font-size: 18px;
    font-weight: 500;
    text-align: center;
  }
  .check {
    padding-right: 5px;
    padding-top: 5px;
    opacity: 100%;
    scale: 1.3;
  }
  .slide-title {
    padding-top: 0px;
    text-align: center;
    font-size: 32px;
  }
  .onboardingDots {
    position: absolute;
    bottom: 25px;
    left: 50%;
    transform: translateX(-50%);
    .carousel__dot {
      border: 0;
      background-color: var(--primary-color);
      border-radius: 50%;
      width: 12px;
      height: 12px;
      margin-right: 5px;
      background-color: #d8d8d8;
      margin-right: 6px;
      &.carousel__dot--selected {
        background-color: var(--primary-color);
      }
      &:last-child {
        margin-right: 0px;
      }
    }
  }

  @media screen and (max-width: 860px) {
    height: 100%;
    .slide-header {
      font-size: 14px;
      padding: 0 15px;
    }
    .slide-body {
      font-size: 1em;
      padding: 0 15px;
    }
    .slide-title {
      font-size: 26px;
    }
  }
`

const RecommendationList = styled.div`
  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 20px;
`

const Recommendation = styled.div`
  margin-top: 15px;
  &:first-child {
    margin-top: 15px;
  }
  display: flex;
  flex-direction: row;
  align-items: start;
  padding-right: 15px;
`

export default WithStore(OnboardingSlider, state => ({
  currentSlide: state.currentSlide,
  totalSlides: state.totalSlides
}))
