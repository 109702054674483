import React, { useContext, useState } from 'react'
import { workspaceContext } from 'src/context/WorkspaceContext'
import { CreateCustomDomainForm } from './CreateCustomDomainForm'
import { trash } from '../../../design-system/icons/index.js'
import SVGInline from 'react-svg-inline'

export const CustomDomainForm = () => {
  const { workspacePermissions, toggleCustomDomain, deleteCustomDomain, customDomains } = useContext(workspaceContext)

  const [deleting, setDeleting] = useState<string[]>([])
  const [toggling, setToggling] = useState<string[]>([])
  const loading = (domain: {deploying: boolean, domain: string}) => deleting.some(d => d === domain.domain) || toggling.some(d => d === domain.domain) || domain.deploying
  const modifyPermitted = workspacePermissions.includes('Workspace Settings')

  const deleteDomain = async (domain: string) => {
    setDeleting(d => [...d, domain])
    await deleteCustomDomain(domain)
    setDeleting(d => d.filter(d => d !== domain))
  }

  const toggleDomain = async (domain: string, state: boolean) => {
    setToggling(d => [...d, domain])
    await toggleCustomDomain(domain, state)
    setToggling(d => d.filter(d => d !== domain))
  }

  return (
    <div className='custom-domain-form'>
      <div>
        {customDomains.map((domain: any) => (
          <div key={domain.domain}>
            <h2 className='domain-address'>
              <div className={`status ${loading(domain) ? 'loading-spinner' : ''}`} />
              <span>{domain.domain}</span>
              {!domain.enabled && !domain.deploying && (
                <button className='remove-cname' disabled={!modifyPermitted || loading(domain)} onClick={() => deleteDomain(domain.domain)}>
                  <SVGInline alt='delete' svg={trash} />
                </button>
              )}
              {domain.enabled && (
                <a href={`https://${domain.domain}`} className='btn primary activate-btn'>Activate Now</a>
              )}
              <button onClick={() => toggleDomain(domain.domain, !domain.enabled)} disabled={!modifyPermitted || !domain.acm_verification_status || !domain.cloudfront_domain_status || domain.deploying} className={`enable-cname switch ${domain.enabled ? 'on' : ''} ${toggling.some(d => d === domain.domain) ? 'loading' : ''}`} />
            </h2>
            <div className='cname-records-header'>
              <div>TYPE</div>
              <div>NAME</div>
              <div>VALUE</div>
            </div>
            <div className='cname-table'>
              <div className={`cname-record ${domain.cloudfront_domain_status ? 'validated' : ''}`}>
                <div>CNAME</div>
                <div>{domain.domain}.</div>
                <div>{domain.cloudfront_domain}.</div>
              </div>
              <div className={`cname-record ${domain.acm_verification_status ? 'validated' : ''}`}>
                <div>CNAME</div>
                <div>{domain.acm_verification_name}</div>
                <div>{domain.acm_verification_value}</div>
              </div>
            </div>
          </div>
        ))}
        <div>
          <div className='pane help'>
            <strong>To finish setup, create two CNAME records in your DNS host, using the NAME and VALUE above:</strong>   
<p></p>1. Create a <strong>first</strong> CNAME record
<p></p>2. Create a <strong>second</strong> CNAME record
<p></p>3. Please allow a few minutes for the new records to propagate and verify
<p></p>4. Once the records change from RED to GREEN above, you can activate your Custom URL.

<p></p>Need help? For support and troubleshooting, visit the <a href='https://support.sizle.io/help/en-us/16-workspaces/51-how-to-set-up-a-custom-domain-cname'> knowledge bsae</a>
          </div>
        </div>
        <br />
        <CreateCustomDomainForm />
      </div>
    </div>
  )
}
