import React, { useCallback, useState, useEffect } from 'react'
import axios from 'axios'
import sampleData from './sample-data.json'
export type Lead = {
  lead_id: string,
  link_id: string,
  raw_activity: {[key: string]: any}[],
  reaction: string,
  last_activity: string,
  recipient: string,
  organisation: string,
  open_count: number,
  first_activity: string,
  downloaded_timestamp: string,
  responses: {[key: string]: any}[],
  avgpagetime: {avg: number, count: number, name: number}[]
}
export type Link = {
  link_id: string,
  leads: Lead[],
  expiration_date: string,
  burn_timer: number,
  comments_enabled: boolean,
  reactions_enabled: boolean,
  downloads_enabled: boolean,
  pin: string,
  url: string,
  created_at: string,
  email_delivered: string,
  email_opened: string,
  type: string
}
export default (presentationId: string, enableSampleData: boolean): {
  publicLinks: Link[],
  uniqueLinks: Link[],
  emailLinks: Link[],
  sampleMode: boolean,
  updateLink: (
    linkId: string,
    config: {
      burnTimer: number | null,
      expirationDate: Date | null,
      pin: string | null
      commentsEnabled: boolean | null
      reactionsEnabled: boolean | null
      downloadsEnabled: boolean | null
    }) => Promise<void>
} => {
  const [publicLinks, setPublicLinks] = useState([])
  const [emailLinks, setEmailLinks] = useState([])
  const [uniqueLinks, setUniqueLinks] = useState([])
  const [sampleMode, setSampleMode] = useState(false)
  useEffect(() => {
    if (presentationId) {
      const events = new EventSource(`/api/documents/${presentationId}/leads`)
      events.onmessage = event => {
        let parsed = JSON.parse(event.data)
        setSampleMode(false)
        if (
          parsed.publicLinks.length === 0 &&
          parsed.uniqueLinks.length === 0 &&
          parsed.emailLinks.length === 0 &&
          enableSampleData
        ) {
          parsed = sampleData
          setSampleMode(true)
        }
        setPublicLinks(parsed.publicLinks)
        setEmailLinks(parsed.emailLinks)
        setUniqueLinks(parsed.uniqueLinks)
      }
      return () => events.close()
    } else {
      setPublicLinks([])
      setEmailLinks([])
      setUniqueLinks([])
    }
  }, [presentationId])
  const updateLink = useCallback(async (linkId: string, {
    pin,
    expirationDate,
    burnTimer,
    commentsEnabled,
    reactionsEnabled,
    downloadsEnabled
  }) => {
    await axios({
      url: `/api/links/${linkId}`,
      method: 'POST',
      data: { pin, expirationDate, burnTimer, commentsEnabled, reactionsEnabled, downloadsEnabled },
      withCredentials: true
    })
  }, [])
  return { updateLink, sampleMode, publicLinks, emailLinks, uniqueLinks }
}
