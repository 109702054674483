import React, { PureComponent } from 'react'
import styled from '@emotion/styled'
import Error500Page from 'src/pages/others/Error500Page'

class ErrorBoundary extends PureComponent {
  constructor (props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError (error) {
    console.error(error)
    return { hasError: true }
  }

  componentDidCatch (error, errorInfo) {
    console.error(error)
  }

  render () {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <Wrapper>
          <Error500Page />
        </Wrapper>
      )
    }

    return this.props.children
  }
}

const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
`

export default ErrorBoundary
