import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { toastr } from 'react-redux-toastr';
import { useTranslation } from 'react-i18next';
import TreeMenu from 'react-simple-tree-menu';
import { home, sizleCompanies, settings, team, plusCircle, creditCard } from '@sizle-icons';
import ListItem from './TreeMenu';
import { workspaceContext, useUserContext } from 'src/context';
import { sendAmplitudeData } from 'src/utils/amplitude';
import WorkspaceDropdown from './WorkspaceDropdown';
import { useResponsive } from 'react-hooks-responsive';
import SidebarNavLink from './SidebarNavLink';
import Footer from './Footer';
import FooterCreateIcon from './FooterCreateIcon';
import { FiChevronRight, FiChevronLeft } from 'react-icons/fi';
import StorageLimit from 'src/modules/navigation/main-sidebar/components/StorageLimit';

const Sidebar = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [openTreeNodes, setOpenTreeNodes] = useState([]);
  const [focusKey, setFocusNodeKey] = useState(null);
  const [applyingCreateLoader, setApplyingCreateLoader] = useState(false);
  const { setActiveFolder, activeFolder, setNotificationView, notificationView } = useUserContext();
  const { workspaceIndex, hasWorkspacePermission } = useContext(workspaceContext);
  const { t } = useTranslation();
  const breakpoints = { mobile: 0, desktop: 855 };
  const { screenIsAtMost, screenIsAtLeast } = useResponsive(breakpoints);
  const isMobile = screenIsAtLeast('mobile') && screenIsAtMost('desktop');
  const [compactNav, setCompactNav] = useState(isMobile);
  const getWidth = () => window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
  const [width, setWidth] = useState(getWidth());
  const { folders, addFolder } = useContext(workspaceContext);

  useEffect(() => {
    let timeoutId = null;
    const resizeListener = () => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        setWidth(getWidth());
        const elm = document.getElementById('mainContentContainer');

        if (getWidth() <= 855 && notificationView === true) {
          setNotificationView(!notificationView);
        }

        if (getWidth() <= 855 && !compactNav) {
          if (elm != null && !elm.classList.contains('compacted-nav')) {
            elm.classList.add('compacted-nav');
            setCompactNav(true);
          }
        } else if (getWidth() > 855 && compactNav) {
          if (elm != null && elm.classList.contains('compacted-nav')) {
            elm.classList.remove('compacted-nav');
            setCompactNav(false);
          }
        }
      }, 150);
    };
    window.addEventListener('resize', resizeListener);

    return () => {
      window.removeEventListener('resize', resizeListener);
    };
  }, [width, notificationView, compactNav]);

  useEffect(() => {
    const elm = document.getElementById('mainContentContainer');
    if (compactNav && elm && !elm.classList.contains('compacted-nav')) {
      elm.classList.add('compacted-nav');
    }
  }, [compactNav]);

  const toggleNav = (forceClose) => {
    const elm = document.getElementById('mainContentContainer');

    if (forceClose === 'close' && !compactNav) return;

    setCompactNav(!compactNav);

    if (elm != null) {
      elm.classList.toggle('compacted-nav');
    }

    if (isMobile) {
      document.querySelector('.sidebar').classList.toggle('sidebar-expanded');
    }
  };

  useEffect(() => {
    if (folders?.length > 0) {
      const findFolder = (folder, key) => {
        if (parseInt(folder.folderId) === parseInt(key)) return `${key}`;
        const found = folder.nodes.map(f => findFolder(f, key)).find(a => a);
        return found ? `${folder.folderId}/${found}` : null;
      };
      const newFocusKey = folders.map(f => findFolder(f, activeFolder)).find(a => a);
      if (newFocusKey) {
        const openedFolder = newFocusKey.split('/').slice(0, -1).join('/');
        setOpenTreeNodes([...openTreeNodes, openedFolder]);
        setFocusNodeKey(`${newFocusKey}`);
      } else {
        setActiveFolder(folders[0]?.folderId);
      }
    }
  }, [activeFolder, folders]);

  const onClickFolder = ({ key }) => {
    if (pathname.indexOf('/presentations') === -1) {
      navigate(`/s/${workspaceIndex}/presentations`);
    }

    const activatedFolderId = key.split('/').pop();
    if (key !== focusKey) {
      setActiveFolder(activatedFolderId);
    } else {
      setOpenTreeNodes(openTreeNodes.includes(key) ? openTreeNodes.filter(node => node !== key) : [...openTreeNodes, key]);
    }

    if (isMobile) toggleNav();
  };

  const onClickCreateFolder = async (parentFolderId) => {
    setApplyingCreateLoader(true);
    try {
      const newFolder = await addFolder('Untitled Folder', parentFolderId);
      setActiveFolder(newFolder.folderId);
      toastr.info('New folder created.');
      sendAmplitudeData('NEW_FOLDER_CREATED');
      setOpenTreeNodes([...openTreeNodes, `${parentFolderId}`]);
    } catch (e) {
      console.error(e);
      toastr.error('Error creating new folder.');
    }
    setApplyingCreateLoader(false);
  };

  return (
    <div className={`sidebar ${compactNav ? 'compacted' : ''}`}>
      <div className='sidebar-inner'>
        <div className='toggle-nav-btn' onClick={() => toggleNav()}>
          {compactNav ? <FiChevronRight /> : <FiChevronLeft />}
        </div>
        <div className='mobile-toggle-nav-btn' onClick={() => toggleNav()}>
          {compactNav ? <FiChevronRight /> : <FiChevronLeft />}
        </div>
        <WorkspaceDropdown toggleNav={toggleNav} />
        <div className='sidebar-links'>
        <SidebarNavLink mini={compactNav} to={`/s/${workspaceIndex}/settings`} icon={settings}>{t('Settings & Branding')}</SidebarNavLink> 
        <SidebarNavLink mini={compactNav} to={`/s/${workspaceIndex}/billing#monthly`} icon={creditCard} iconName='credit-card'>{t('Account')}</SidebarNavLink>
        </div>
        <h6 className='tree-menu-header folders-box'>{t('Folders')}</h6>
        <div className='tree-menu-container folders-box'>
          {folders.length > 0 && focusKey &&
            <TreeMenu data={folders} onClickItem={onClickFolder} openNodes={openTreeNodes} initialFocusKey={focusKey} focusKey={focusKey} debounceTime={125} resetOpenNodesOnDataUpdate={false}>
              {({ items }) => (
                <div>
                  {items.map(({ key, ...props }) =>
                    (<ListItem key={key} onCreateNewFolder={onClickCreateFolder} folderId={props.folderId} isOriginal={props.is_original} {...props} />)
                  )}
                </div>
              )}
            </TreeMenu>}
          {hasWorkspacePermission('Manage Folders') && (
            <a className='footer-link' onClick={() => onClickCreateFolder()}>
              <FooterCreateIcon icon={plusCircle} />
              <span>{t('Create folder')}</span>
            </a>
          )}
        </div>
        {/* Conditionally render StorageLimit based on compactNav */}
        <Footer compactNav={compactNav} />
        {!compactNav && <StorageLimit className="storage-limit" />}
      </div>
    </div>
  );
};

Sidebar.propTypes = {};

export default Sidebar;
