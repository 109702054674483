import React, { useContext, useEffect, useState, useRef } from 'react'
import { format, fromUnixTime } from 'date-fns'
import BeatLoader from 'react-spinners/BeatLoader'
import { toastr } from 'react-redux-toastr'
import { BaseInput } from 'src/design-system/elements'
import styled from '@emotion/styled'
import { FaKey } from 'react-icons/fa'
import { useTranslation } from 'react-i18next'
import { useNavigate, useLocation } from 'react-router-dom'
import { sendAmplitudeData } from 'src/utils/amplitude'
import { subscriptionContext } from 'src/context'
import { Pane, SubscriptionForm } from 'src/modules/billing'
import { FAQSection } from 'src/modules/billing/faq-section'
import { PaymentMethodForm } from 'src/modules/billing/PaymentMethod'

const BillingPage = () => {
  const { cancelSubscription, soloMonthlyPlan, soloAnnualPlan, advMonthlyPlan, advAnnualPlan, monthlyPlan, annualPlan, invoices, redeemLicenseKey, nextInvoiceDate, nextInvoiceAmount, subscriptionCadence, currentPlan, hasPro } = useContext(subscriptionContext)
  const { t } = useTranslation()
  const [licenseKey, setLicenseKey] = useState('')
  const [submittingLicenseKey, setSubmittingLicenseKey] = useState(false)
  const [upgradingPlan, setUpgradingPlan] = useState(null)
  const [cancellingSubscription, setCancellingSubscription] = useState(false)
  const [confirmCancelPopup, setConfirmCancelPopup] = useState(false)
  const [viewingPlan, setViewingPlan] = useState(null)
  const [cadence, setCadence] = useState('monthly')
  const navigate = useNavigate()
  const { hash } = useLocation()
  const formRef = useRef(null)

  useEffect(() => {
    if (hash === '#update-payment') {
      setTimeout(window.requestAnimationFrame(() => {
        formRef.current.scrollIntoView({ behavior: 'smooth' })
        navigate('#')
      }), 0)
    }
  }, [hash, navigate])

  useEffect(() => {
    sendAmplitudeData('PAGE_VIEW_BILLING')
  }, [])

  const submitLicenseKey = async () => {
    setSubmittingLicenseKey(true)
    try {
      await redeemLicenseKey(licenseKey)
      toastr.info('License key activated')
    } catch (e) {
      toastr.error('There was an issue while activating your license key, please contact support.')
      throw e
    }
    setSubmittingLicenseKey(false)
  }

  const handleCancelPlan = async (e) => {
    e.stopPropagation()
    e.preventDefault()
    setCancellingSubscription(true)
    try {
      await cancelSubscription()
      toastr.info('Your account has been downgraded to a Free account')
    } catch (e) {
      toastr.error('There was an issue while downgrading your account, please contact support.')
      throw e
    }
    setCancellingSubscription(false)
    setViewingPlan(null)
    setConfirmCancelPopup(false)
  }

  return (
    <div className='billing-page'>
      <Wrapper>
        <PlanBody>
          <h2>Choose a plan below</h2>
          <br></br>
          <PlanDescription className='plan-description'>
            {t('You are currently on a')}{' '}
            <BlueText>{currentPlan?.planTier}{' '}</BlueText>
            {currentPlan?.planType?.toLowerCase()}
          </PlanDescription>
          <br></br>

          <div className='cadence-selector'>
            <span className={`${cadence === 'monthly' ? 'active' : ''}`}><h5>{t('billing.Billed monthly')}</h5></span>
            <button onClick={() => setCadence(c => c === 'monthly' ? 'annual' : 'monthly')} className={`switch always-primary ${cadence === 'annual' ? 'on' : ''}`} />
            <span className={`${cadence === 'annual' ? 'active' : ''}`}><h5>Annual (30% off)</h5></span>
          </div>

          <div className='pane-container'>
            <Pane viewingPlan={viewingPlan} cancel={() => setConfirmCancelPopup(true)} activate={setUpgradingPlan} plan={cadence === 'monthly' ? soloMonthlyPlan : soloAnnualPlan} />
            <Pane viewingPlan={viewingPlan} cancel={() => setConfirmCancelPopup(true)} activate={setUpgradingPlan} plan={cadence === 'monthly' ? monthlyPlan : annualPlan} />
            <Pane viewingPlan={viewingPlan} cancel={() => setConfirmCancelPopup(true)} activate={setUpgradingPlan} plan={cadence === 'monthly' ? advMonthlyPlan : advAnnualPlan} />
          </div>
          {/* Feature Comparison Table */}
          <br></br>
          <br></br>
          <h2>Compare plan features</h2>
          <TableWrapper>
            <ComparisonTable>
              <thead>
                <tr>
                  <th>Feature</th>
                  <th>Solo</th>
                  <th>Pro</th>
                  <th>Advanced</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Data Rooms</td>
                  <td>2</td>
                  <td>Unlimited</td>
                  <td>Unlimited</td>
                </tr>
                <tr>
                  <td>Storage per Room</td>
                  <td>100GB</td>
                  <td>200GB</td>
                  <td>350GB</td>
                </tr>
                <tr>
                  <td>Unlimited Visitors</td>
                  <td>✔</td>
                  <td>✔</td>
                  <td>✔</td>
                </tr>
                <tr>
                  <td>Shareable Links</td>
                  <td>✔</td>
                  <td>✔</td>
                  <td>✔</td>
                </tr>
                <tr>
                  <td>Page-by-page Analytics</td>
                  <td>✔</td>
                  <td>✔</td>
                  <td>✔</td>
                </tr>
                <tr>
                  <td>Document Passwords</td>
                  <td>✔</td>
                  <td>✔</td>
                  <td>✔</td>
                </tr>
                <tr>
                  <td>Approval Workflows</td>
                  <td>✔</td>
                  <td>✔</td>
                  <td>✔</td>
                </tr>
                <tr>
                  <td>Reactions to Documents</td>
                  <td>✔</td>
                  <td>✔</td>
                  <td>✔</td>
                </tr>
                <tr>
                  <td>Comments on Documents</td>
                  <td>✔</td>
                  <td>✔</td>
                  <td>✔</td>
                </tr>
                <tr>
                  <td>Restrict Downloads</td>
                  <td>✔</td>
                  <td>✔</td>
                  <td>✔</td>
                </tr>
                <tr>
                  <td>Import from Dropbox</td>
                  <td>✔</td>
                  <td>✔</td>
                  <td>✔</td>
                </tr>
                <tr>
                  <td>Link Self-destruct</td>
                  <td>✔</td>
                  <td>✔</td>
                  <td>✔</td>
                </tr>
                <tr>
                  <td>Document Expiry Settings</td>
                  <td>✔</td>
                  <td>✔</td>
                  <td>✔</td>
                </tr>
                <tr>
                  <td>Custom User Roles</td>
                  <td>✔</td>
                  <td>✔</td>
                  <td>✔</td>
                </tr>
                <tr>
                  <td>Unlimited Uploads</td>
                  <td>—</td>
                  <td>✔</td>
                  <td>✔</td>
                </tr>
                <tr>
                  <td>Email Lead Capture</td>
                  <td>—</td>
                  <td>✔</td>
                  <td>✔</td>
                </tr>
                <tr>
                  <td>Unlimited Lead Captures</td>
                  <td>—</td>
                  <td>✔</td>
                  <td>✔</td>
                </tr>
                <tr>
                  <td>Use Your Own Logo</td>
                  <td>—</td>
                  <td>—</td>
                  <td>✔</td>
                </tr>
                <tr>
                  <td>Custom URLs for Links</td>
                  <td>—</td>
                  <td>—</td>
                  <td>✔</td>
                </tr>
              </tbody>
            </ComparisonTable>
          </TableWrapper>

          <PaymentMethodForm />
          {(invoices?.length && (
            <BillingSep>
              <InvoiceSection>
                <label className='label giant regular'>Invoices</label>
                <InvoiceList>
                  {invoices.map((invoice) => (
                    <Invoice key={invoice.created}>
                      <div className='invoice-details'>
                        <div>{format(fromUnixTime(invoice.created), 'do MMM yyyy')}</div>
                      </div>
                      <div className='invoice-buttons'>
                        <a href={invoice.invoiceUrl} className='btn secondary'>{t('billing.View Invoice')}</a>
                      </div>
                    </Invoice>
                  ))}
                </InvoiceList>
              </InvoiceSection>
            </BillingSep>
          )) || undefined}
        </PlanBody>

        {(upgradingPlan && upgradingPlan.id !== currentPlan.planId && (
          <PopupContainer onClick={() => setUpgradingPlan(false)}>
            <Popup onClick={(e) => e.stopPropagation()}>
              <PopupContent>
                <SubscriptionForm onComplete={() => setUpgradingPlan(null)} cancel={() => setUpgradingPlan(null)} plan={upgradingPlan} />
              </PopupContent>
            </Popup>
          </PopupContainer>
        )) || undefined}

        {(confirmCancelPopup && (
          <PopupContainer onClick={() => setConfirmCancelPopup(false)}>
            <Popup onClick={(e) => e.stopPropagation()}>
              <PopupContent>
                <h2 className='title'>{t('Are you sure?')}</h2>
                <p className='intro'>
                  {t('billing.cancelPopup.You are cancelling your')}{' '}
                  {currentPlan?.billingCadence}{' '}
                  {t('billing.cancelPopup.plan as well as any additional paid workspace seats, effective immediately')}
                </p>
                <div className='btn-group center'>
                  <button className='btn xl' onClick={() => setConfirmCancelPopup(false)}>{t('Go back')}</button>
                  <button className='btn danger xl' onClick={handleCancelPlan} disabled={cancellingSubscription ? 'disabled' : undefined} loading={cancellingSubscription ? 'loading' : undefined}>{t('Cancel Subscription')}</button>
                </div>
              </PopupContent>
            </Popup>
          </PopupContainer>
        )) || undefined}
      </Wrapper>

      <InvoiceDescription>
        {nextInvoiceAmount && (
          <div>{t('billing.Your next')}{' '}
            <b>{subscriptionCadence}{' '}</b>
            {t('billing.payment of')}{' '}
            <BlueText>${nextInvoiceAmount}{' '}</BlueText>
            {t('billing.will be due on')}{' '}
            <BlueText>{nextInvoiceDate}</BlueText>
          </div>
        )}
      </InvoiceDescription>
    </div>
  )
}

const TableWrapper = styled.div`
  margin-top: 40px;
  width: 70%;
  overflow-x: auto;
  padding: 20px;  /* Uniform padding on all sides */
  border: 1px solid #ddd;  /* Border around the box */
  border-radius: 8px;  /* Rounded corners */
  background-color: var(--background-color);
`;

const ComparisonTable = styled.table`
  width: 100%;
  border-collapse: collapse;

  th {
    background-color: --background-alt-color;
    font-weight: bold;
    padding: 12px 15px;
    border-bottom: 1px solid #ddd;
    text-align: center;  /* Center align other headers */
  }

  th:first-of-type {
    text-align: left;  /* Left align the 'Feature' header */
  }

  td {
    padding: 12px 15px;
    border-bottom: 1px solid #ddd;
  }

  td:first-of-type {
    text-align: left;  /* Left align the first column */
  }

  td:not(:first-of-type) {
    text-align: center;  /* Center align other columns */
  }

  tr:nth-of-type(even) {
    background-color: var (--background-alt-color);
  }

  tr:last-child td {
    border-bottom: 0;
  }
`;


const Wrapper = styled.div`
  color: var(--text-color);
  padding-bottom: 40px;

  .downgrade-btn {
    color: var(--background-inverted);
    background-color: var(--background-color);
    border: 1px solid var(--primary-color);

    &:hover,
    &:focus {
      color: var(--background-inverted);
      background-color: var(--background-color);
      border: 1px solid var(--primary-color);
    }
  }
`;

const PlanBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 5px;
`;

const PlanDescription = styled.div`
  font-size: 14px;
  font-weight: 500;
  padding-bottom: 20px;
  background-color: var(--background-color);
  border: 1px solid #ccc;
  border-radius: 12px;
  padding: 10px 20px;
  display: inline-block;
`;

const InvoiceDescription = styled.div`
  font-size: 14px;
  line-height: 1.8em;
  padding-bottom: 20px;
  text-align: center;
`;

const BlueText = styled.span`
  color: var(--primary-color-dark);
`;

const BillingSep = styled.div`
  border-top: 1px solid var(--border-alt);
  width: 100%;
  margin-left: -20px;
  padding-left: 20px;
  margin-right: 20px;
  margin-top: 40px;
  padding-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const LicenseEntry = styled.div`
  position: relative;
  width: min(420px,90%);
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: left;
  svg {
    position: absolute;
    margin-top: 11px;
    margin-left: 12px;
  }
`;

const Field = styled.div`
  position: relative;
  display: flex;
`;

const Input = styled(BaseInput)`
  background-color: var(--input-background);
  color: var(--input-color);
  border-width: 2px;
  border-color: var(--input-border-color);
  width: 300px;
  padding-left: 30px;

  &:hover,
  &:focus {
    border-color: var(--input-focus);
  }
`;

const Loader = styled.div`
  pointer-events: none;
  position: absolute;
  margin: auto;
  min-width: 100%;
  min-height: 100%;
  display: flex;
  background-color: ${props => props.isSubmitting ? '#fff9' : 'unset'};

  &>div {
    margin: auto;
  }
`;

const InvoiceSection = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const InvoiceList = styled.div`
  max-height: 145px;
  overflow: auto;
  align-items: center;
  justify-content: center;
  max-width: 100%;
`;

const Invoice = styled.div`
  display: flex;
  align-items: space-between;
  flex-direction: row;
  margin-bottom: 10px;
  .invoice-details,.invoice-buttons {
    align-items: center;
    div {
      width: 150px;
    }
    flex-direction: row;
    display: flex;
  }
  .invoice-details {
    width: 55%;
    padding-right: 10px;
    display: flex;
  }

  @media screen and (max-width: 550px) {
    border-bottom: 1px solid #eeeeee;
    padding-bottom: 8px;
    padding-top: 8px;

    button {
      margin: 0;
    }
    .invoice-details {
      flex-direction: column;
      div {
        width: auto;
      }
    }
    .invoice-buttons {
      justify-content: space-around;
    }
  }
`;

const PopupContainer = styled.div`
  z-index: 99;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 0.1s;
  position: fixed;
  top: 0px;
  left: 0px;
  background: #0005;
  opacity: ${props => props.hidden ? 0 : 1};
  pointer-events: ${props => props.hidden ? 'none' : 'auto'};
`;

const Popup = styled.div`
  width: 32%;
  z-index: 3;
  position: absolute;
  box-shadow: rgb(0 0 0 / 20%) 0px 0px 300px 10px;
  color: var(--text-color);
  font-weight: 500;
  font-size: 1em;
`;

const PopupContent = styled.div`
  text-align: center;
  padding: 50px;
  padding-bottom: 50px;
  background: var(--background-color);
  border-radius: 5px;
`;

export default BillingPage;
