import React, { useState, useRef, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { ProPopup } from 'src/modules/modals/components/ProPopup'

const generatePin = (number) => {
  return btoa(`sizle:${number}`)
}
const extractPin = (value) => {
  return atob(value).match(/sizle:(.+)/)[1]
}

const PinInputComponent = ({
  label = null,
  isDisabled = false,
  activeLinkPin = false,
  defaultValue = '',
  handleAccessPinChange = (p) => {},
  ...props
}) => {
  const [pin, setPin] = useState(defaultValue && extractPin(defaultValue))
  const linkInputRef = useRef()
  const { t } = useTranslation()
  const [enabled, toggle] = useState(!!defaultValue)

  const handleLinkPinChange = (e) => {
    const pinValue = e.target.value
    toggle(true)
    setPin(pinValue)
  }

  useEffect(() => {
    handleAccessPinChange((enabled && pin) ? generatePin(pin) : '')
  }, [enabled, pin])

  useEffect(() => {
    if (activeLinkPin) {
      linkInputRef.current && linkInputRef.current.focus()
    }
  }, [activeLinkPin])

  return (
    <>
      <ProPopup requiredFeature='pin'>
        <div {...props} className='pin-input'>
          <button disabled={isDisabled} type='button' onClick={() => toggle(v => !v)} className={`switch ${enabled && 'on'}`} />
          <div className='input-container'>
            <label className='label small tooltip tooltip-top' data-tooltip={t('setupPopup.Access PIN tooltip')}>{label || t('PIN')}</label>
            <div className='input-wrap'>
              <input placeholder='eg. 1234' className='input-text' type='text' name='linkPin' value={pin} disabled={isDisabled ? 'disabled' : undefined} onChange={handleLinkPinChange} />
            </div>
          </div>
        </div>
      </ProPopup>
    </>
  )
}

PinInputComponent.defaultProps = {
  isDisabled: false
}

export default PinInputComponent
