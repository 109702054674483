import React, { useState, useContext, useEffect } from 'react';
import styled from '@emotion/styled';
import useSound from 'use-sound';
import { NavLink } from 'react-router-dom';
import { parseISO, differenceInSeconds } from 'date-fns';
import { Menu, useDisclosure } from '@chakra-ui/core';
import { ProTrialBanner } from 'src/modules/account/ProtrialBanner';
import UploadDropdown from 'src/modules/controls/components/UploadDropdown';
import { workspaceContext, useUserContext } from 'src/context';
import HelpButton from 'src/design-system/elements/buttons/HelpButton';
import notificationSound from 'src/design-system/sizle-notification.mp3';

const PageTitle = () => {
  const { user, workspaceIndex, hideCreateTooltip, setHideCreateTooltip, avatarCacheTimestamp } = useUserContext();
  const { workspacePermissions, notifications } = useContext(workspaceContext);
  const [hasRecentNotification, setHasRecentNotification] = useState(false);
  const [previousNotifications, setPreviousNotifications] = useState([]);
  const { isOpen: isMenuOpen, onToggle, onOpen: onOpenMenu, onClose: onMenuClose } = useDisclosure();
  const [play] = useSound(notificationSound);

  // Combine notification effect
  useEffect(() => {
    if (notifications?.length > 0) {
      const latestNotification = notifications[0];
      const seconds = Math.abs(differenceInSeconds(parseISO(latestNotification.created_at), new Date()));
      if (seconds < 10) {
        setHasRecentNotification(true);
        if (previousNotifications[0] !== latestNotification) {
          setPreviousNotifications(notifications);
          play();
        }
      } else {
        setHasRecentNotification(false);
      }
    }
  }, [notifications, previousNotifications, play]);

  const handleMenuToggle = () => {
    onToggle();
    setHideCreateTooltip(true);
  };

  return (
    <div className='page-title'>
      <div className='mobile-protrial-banner-container'>
        <ProTrialBanner />
      </div>

      <div className='page-title-container'>
        <div name='upload-dropdown' className={`hide-hint upload-dropdown ${hideCreateTooltip ? 'immediate' : ''}`}>
          <Menu closeOnBlur closeOnSelect={false} autoSelect={false} isOpen={isMenuOpen}>
            <Cover hidden={!isMenuOpen} onClick={onMenuClose} />
            <button
              className='btn extra-big upload-button primary'
              onClick={handleMenuToggle}
              disabled={!workspacePermissions.includes('Add & Edit Documents')}
              title='Click to upload or import a file'
            >
              Upload file
            </button>
            <UploadDropdown onOpen={onOpenMenu} onClose={onMenuClose} />
          </Menu>
        </div>
        <div className='protrial-banner-container'>
          <ProTrialBanner />
        </div>
        <HelpButton />
        <button
          className='btn extra-big upload-button primary'
          disabled={!workspacePermissions.includes('Add & Edit Documents')}
          title='Click to view plan details'
        >
          <a href={`/s/${workspaceIndex}/settings`} style={{ color: 'white', textDecoration: 'none' }}>
            Add team
          </a>
        </button>
        <StyledUserProfile>
          <StyledUserProfileLink to={workspaceIndex !== null ? `/s/${workspaceIndex}/account` : '/account'}>
            <img
              className='avatar-small'
              src={`/api/users/${user?.sub}/avatar?timestamp=${avatarCacheTimestamp.getMilliseconds()}`}
              alt='User Profile'
            />
          </StyledUserProfileLink>
        </StyledUserProfile>
      </div>
    </div>
  );
};

const StyledUserProfile = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end; // Align the avatar to the far right
`;

const StyledUserProfileLink = styled(NavLink)`
  display: flex;
  align-items: center;
  justify-content: center;
  > img {
    width: 40px; // Ensure the avatar fits within the layout
    height: 40px;
    border-radius: 50%; // Make sure the avatar remains circular
  }
`;

const Cover = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100vw; // Ensured full screen coverage
  z-index: 10; // Brought to the front for interactivity
`;

export default PageTitle;
