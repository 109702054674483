import React, { useState, createContext } from 'react'
import { sendAmplitudeData } from 'src/utils/amplitude'

const getMode = () => {
  return localStorage.getItem('mode') || 'dark'
}

export const ModeContext = createContext({
  hideSizleBrand: false,
  mode: 'light',
  setMode: (_mode: string): void => { throw new Error('Attempted to set light/dark mode before modecontext is initialized (Also possible that there is no reachable provider)') }
})

export const ModeProvider: React.FunctionComponent<{ children: React.ReactNode | React.ReactNode[]}> = ({ children }) => {
  const [mode, _setMode] = useState(getMode())
  const [hideSizleBrand] = useState(!window.location.origin.includes('sizle'))
  return (
    <ModeContext.Provider
      value={{
        hideSizleBrand,
        mode,
        setMode: (mode = 'dark') => {
          _setMode(mode)
          localStorage.setItem('mode', mode)
          sendAmplitudeData('USER_CHANGED_THEME', { theme: mode })
        }
      }}
    >
      {children}
    </ModeContext.Provider>
  )
}

export default ModeProvider
