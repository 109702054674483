import React, { useCallback, useContext, useEffect, useState } from 'react'
import SVGInline from 'react-svg-inline'
import { zoomIn, zoomOut } from 'src/design-system/icons'
import styled from '@emotion/styled'
import { useAuth } from 'oidc-react'
import { useNavigate } from 'react-router-dom'
import { presentationContext, workspaceContext } from 'src/context'
import { ProTrialWidget } from 'src/modules/protrial'
import { ReactionsWidget } from 'src/modules/reactions'
import { error } from 'src/utils/toastr'
import PreviewHeader from '../viewer/PreviewHeader'
import EditorHeader from './EditorHeader'
import { Document, Page } from 'react-pdf/dist/esm/entry.vite'
import { useWindowSize } from 'src/modules/controls/hooks/useWindowSize'

const EditorPage = () => {
  const navigate = useNavigate()
  const { isLoading } = useAuth()
  const [_, height] = useWindowSize()
  const { workspace, hasWorkspacePermission } = useContext(workspaceContext)
  const { presentation, presentationTitle, updatePresentationFile } = useContext(presentationContext)
  const [addPageTut, setAddPageTut] = useState(false)
  const [showingFormDisclaimer, showFormDisclaimer] = useState(0)
  const [previewing, setPreviewing] = useState(false)
  const [page, setPage] = useState(1)
  const [numPages, setNumPages] = useState(0)
  const [docZoom, setDocZoom] = useState(1)
  const handleZoom = useCallback((e) => {
    if (e.scale) {
      setDocZoom(zoom => zoom * ((e.scale + 10) / 11))
    }
    console.log(e)
  }, [setDocZoom])

  const handleSlideZoom = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setDocZoom(parseFloat(e.currentTarget.value))
  }, [setDocZoom])

  const handleArrowKeys = useCallback((e) => {
    if (e.key === 'ArrowRight') {
      setPage(p => Math.min((numPages || 99), p + 1))
    } else if (e.key === 'ArrowLeft') {
      setPage(p => Math.max(1, p - 1))
    }
    console.log(e)
  }, [numPages])

  useEffect(() => {
    console.log('adding event listener')
    window.addEventListener('keyup', handleArrowKeys)
    return () => {
      window.removeEventListener('keyup', handleArrowKeys)
    }
  }, [handleArrowKeys])

  useEffect(() => {
    if (workspace && !hasWorkspacePermission('Add & Edit Documents')) {
      navigate('/')
    }
  }, [workspace, navigate, hasWorkspacePermission])

  const onDocumentLoadFailure = async ({ status }) => {
    if (status === 400) {
      error('Document may not be served from this custom domain.')
    } else {
      error('Unable to load document, please contact support!')
      console.error('Failed to load document')
    }
  }

  function onDocumentLoadSuccess ({ numPages }) {
    setNumPages(numPages)
  }

  const stopPreviewing = useCallback(() => {
    setPreviewing(false)
  }, [navigate])

  return (
    <Wrapper>
      {previewing
        ? <PreviewHeader
            presentation={presentation}
            openPinScreen={() => {}}
            openEmailScreen={() => {}}
            stopPreviewing={stopPreviewing}
          />
        : <EditorHeader previewDocument={() => setPreviewing(true)} />}
      <>
        {presentation?.presentationId && (
          <>
            <Document file={`/api/documents/${presentation?.presentationId}/file`} onLoadError={onDocumentLoadFailure} onLoadSuccess={onDocumentLoadSuccess}>

              <Page height={height - 40} scale={docZoom} pageNumber={page} />
            </Document>
          </>
        )}
        <div className='page-btns' hidden={isLoading || numPages === 1 ? 'hidden' : undefined}>
          <div className='btn-group center'>
            <button className='btn' disabled={page === 1} onClick={() => setPage(page - 1)}>Prev</button>
            <button className='btn' disabled={page >= numPages} onClick={() => setPage(page + 1)}>Next</button>
          </div>
        </div>
        <div className='mag'>
          <button className='btn mag-small' onClick={() => handleZoom({ scale: 0.6 })}>
            <SVGInline svg={zoomOut} />
          </button>   
          <button className='btn mag-big' onClick={() => handleZoom({ scale: 1.4 })}>
            <SVGInline svg={zoomIn} />
          </button>
        </div>
      </>
      <ReactionsWidget isPreview />
      <ProTrialWidget />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  height: 100%;
  background: #515459;

  @media print {
    display: none;
  }
  .viewer-doc {
    overflow: auto;
    height: calc(100vh);
    position: relative;
  }
  .page-btns {
    bottom: 20px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    flex-direction: row;
    justify-content: center;
    opacity: 1; 
    transition: opacity 0.3s ease;
  }
  /* Assuming this is your parent container */
  .parent-container {
    position: relative;
    /* Add other styles specific to your parent container here */
  }
  
  /* Styling for the .mag class */
  .mag {
    height: 30px;
    width: 30px;
    display: flex;
    position: absolute;
    bottom: 25px;
    right: 9%;
    align-items: center;
    justify-content: center;
  }
  
  .mag .mag-small svg {
    height: 18px;
  }
  
  .mag .mag-big svg {
    height: 18px;
  }
  }
`

const Popup = styled.div`
      position: fixed;
      top: 0px;
      left: 0px;
      min-width: 100%;
      min-height: 100%;
      background: #0000;
      pointer-events: none;
      opacity: 0;
      transition: opacity 0.2s;
      &.open {
        pointer - events: initial;
      opacity: 1;
  }
      .popup {
        position: absolute;
      display: flex;
      flex-direction: column;
      background: #21154f;
      box-shadow: 0px 0px 10px rgb(0 0 0 / 30%);
      z-index: 2010;
      padding: 20px 10px;
      border-radius: 8px;
      width: 300px;
      padding-top: 20px;
      text-align: center;
      font-size: 14px;
      h1 {
        font - weight: 500;
      margin-bottom: 10px;
      color: ${props => props.theme.typo.colorWhite};
      font-size: 1.5em;
    }
      p {
        color: ${props => props.theme.typo.colorWhite};
      font-weight: 500;
      padding: 0px 10px 0px 10px;
    }
      cursor: pointer;
  }
      `

const FormDisclaimerPopup = styled(Popup)`
      .popup {
        top: 80px;
      right: 0px;
      left: min(calc(50% + 450px), calc(100% - 320px));
  }
      `

const AddPagePopup = styled(Popup)`
      .popup {
        top: 150px;
      left: 300px;

      @media only screen and (max-width: 768px) {
        left: 0;
    }
  }
      `

export default EditorPage
