import React, { useContext, useState } from 'react'
import { Link } from 'react-router-dom'
import styled from '@emotion/styled'
import { subscriptionContext } from 'src/context'
import { differenceInDays } from 'date-fns'

export const ProTrialBanner = ({ ...props }) => {
  const { currentPlan, trial } = useContext(subscriptionContext)

  const currentPlanType = (currentPlan?.planType || '').toLowerCase() || 'plan'
  const currentPlanTier = (currentPlan?.planTier || '').toLowerCase() || 'expired solo'
  const daysRemaining = differenceInDays(trial?.expiry, new Date())

  return (currentPlanType === 'trial')
    ? (
      <ProTrialContainer className='protrial-banner' {...props}>
        <span>Free trial with {daysRemaining} day{daysRemaining !== 1 ? 's' : ''} remaining. <Link className='click-text' to='/billing'>Upgrade now</Link>.</span>
      </ProTrialContainer>
    )
    : (currentPlanTier === 'expired solo')
      ? (
        <ProTrialContainer className='protrial-banner' {...props}>
          <span>Need more space? <Link className='click-text' to='/billing'><u>View plans</u></Link></span>
        </ProTrialContainer>
      )
      : (currentPlanTier === 'solo')
        ? (
          <ProTrialContainer className='protrial-banner' {...props}>
            <span>You are on a Solo plan. <Link className='click-text' to='/billing'>Upgrade to Pro</Link>.</span>
          </ProTrialContainer>
        )
        : (<div />)
}

const ProTrialContainer = styled.div`
  padding: 12px 20px 12px 20px;
  font-size: 1.5em;
  border-radius: 12px;
  border-width: 1.5px;
  line-height: 14px;
  background-color: #F9E4E9;
  border-color: rgba(224, 30, 90, .4);
  display: flex;
  flex-direction: row;
  color: #000000;
  .close-button {
    width: 14px;
    height: 14px;
    margin-left: 20px;
    margin-top: -1px;
    margin-bottom: -2px;
    &:not(.loading-spinner) {
      &::after,&::before {
        transform: translateY(-6px) rotate(45deg);
        border-left: 1px solid #F9F9F9;
        content: '';
        height: 14px;
        width: 1px;
        position: absolute;
      }
      &::after {
        transform: translateY(-6px) rotate(-45deg);
      }
    }
  }
  .click-text {
    font-weight: 600;
    &:hover {
      text-decoration: underline;
    }

  }
  `
