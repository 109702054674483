import gql from 'graphql-tag'

export const GET_PRESENTATIONS = gql`
  query getPresentations($userId: String, $folderId: String) {
    presentations(order_by: {updated_at: desc}, where: {userId: {_eq: $userId}}) {
      presentationId
      title
      url
      content
      created_at
      updated_at
      emailNotificationsEnabled
      openTrackingEnabled
      slideAnalyticsEnabled
      formEnabled
      originalFileUrl
      originalFileType
      thumbnail
      status
      filesize
    }
  }
`

export const GET_PRESENTATIONS_IN_FOLDER = gql`
  query getPresentations($folderId: Int) {
    presentations(order_by: {updated_at: desc}, where: {folderId: {_eq: $folderId}}) {
      presentationId
      title
      url
      content
      created_at
      updated_at
      emailNotificationsEnabled
      openTrackingEnabled
      slideAnalyticsEnabled
      formEnabled
      originalFileUrl
      originalFileType
      thumbnail
      status
      owner {
        name
        updated_at
        family_name
        given_name
        picture
        auth0_id
      }
      links {
        linkId
        pin
        type
        staticLead {
          leadId
          recipient {
            recipientName
            recipientEmail
          }
        }
        emailLead {
          leadId
          leadUuId
          to
        }
      }
    }
  }
`

export const GET_PRESENTATION = gql`
  query getPresentation($presentationId: String) {
    presentations(where: {presentationId: {_eq: $presentationId}}) {
      content
      created_at
      emailNotificationsEnabled
      folderId
      formEnabled
      formFields
      openTrackingEnabled
      originalFileType
      originalFileUrl
      presentationId
      settings
      slideAnalyticsEnabled
      status
      thumbnail
      pagesPreviews
      title
      updated_at
      url
      workspaceId 
      filesize
    }
  }
`

export const CLONE_ALL_PRESENTATIONS_FIELDS = gql`
  mutation updateAllPresentationFields(
    $presentationId: String!, 
    $title: String!, 
    $folderId: Int,
    $content: json,
    $formFields: jsonb,
    $emailNotificationsEnabled: Boolean,
    $formEnabled: Boolean,
    $slideAnalyticsEnabled: Boolean,
    $openTrackingEnabled: Boolean,
    $originalFileUrl: String,
    $originalFileType: String,
    $thumbnail: String,
    $filesize: Int,
    $workspaceId: uuid) {
    insert_presentations(
      objects: {
        presentationId: $presentationId,
        title: $title,
        folderId: $folderId,
        workspaceId: $workspaceId,
        content: $content,
        formFields: $formFields,
        emailNotificationsEnabled: $emailNotificationsEnabled,
        formEnabled: $formEnabled,
        slideAnalyticsEnabled: $slideAnalyticsEnabled,
        openTrackingEnabled: $openTrackingEnabled,
        originalFileUrl: $originalFileUrl,
        originalFileType: $originalFileType,
        thumbnail: $thumbnail,
        filesize: $filesize
      }
    ) {
      returning {
        presentationId,
        title,
        formFields,
        content
      }
    }
  }
`

export const CREATE_DEFAULT_PRESENTATION = gql`
  mutation createDefaultPresentation(
    $presentationId: String!,
    $title: String!,
    $url: String!,
    $folderId: Int!,
    $originalFileUrl: String,
    $originalFileType: String,
    $thumbnail: String,
    $workspaceId: uuid) {
    insert_presentations(
      objects: {
        presentationId: $presentationId,
        title: $title,
        url: $url,
        folderId: $folderId,
        originalFileUrl: $originalFileUrl,
        originalFileType: $originalFileType,
        thumbnail: $thumbnail,
        workspaceId: $workspaceId
      }
    ) {
      returning {
        presentationId,
        originalFileType,
        originalFileUrl,
        title
      }
    }
  }
`

export const CREATE_PRESENTATION = gql`
  mutation createPresentation(
    $presentationId: String!,
    $title: String!,
    $url: String!,
    $folderId: Int!,
    $originalFileUrl: String,
    $originalFileType: String,
    $thumbnail: String,
    $filesize: Int!,
    $workspaceId: uuid) {
    insert_presentations(
      objects: {
        presentationId: $presentationId,
        title: $title,
        url: $url,
        folderId: $folderId,
        originalFileUrl: $originalFileUrl,
        originalFileType: $originalFileType,
        thumbnail: $thumbnail,
        workspaceId: $workspaceId,
        filesize: $filesize
      }
    ) {
      returning {
        presentationId,
        originalFileType,
        originalFileUrl,
        title
      }
    }
  }
`

export const DELETE_QUERY = gql`
  mutation deletePresentation($presentationId: String!) {
    update_presentations(
      where: {presentationId: {_eq: $presentationId}},
      _set: {deleted_at: "now()"}
    ) {
      affected_rows
    }
  }
`

export const UPDATE_QUERY = gql`
  mutation UpdatePresentationTitle($presentationId: String!, $userId: String, $title: String!) {
    update_presentations(where: {presentationId: {_eq: $presentationId}}, _set: {title: $title}) {
      returning {
        presentationId
        title
        userId
        content
        url
      }
    }
  }
`

export const MOVE_PRESENTATION_QUERY = gql`
  mutation MovePresentation($presentationId: String!, $newFolderId: Int) {
    update_presentations(where: {presentationId: {_eq: $presentationId}}, _set: {folderId: $newFolderId}) {
      returning {
        presentationId
        folderId
      }
    }
  }
`

export const UPDATE_ADDONS_QUERY = gql`
  mutation UpdatePresentationAddons($presentationId: String!, $userId: String!, $openTrackingEnabled: Boolean!, $formEnabled: Boolean!, $emailNotificationsEnabled: Boolean!) {
    update_presentations(where: {presentationId: {_eq: $presentationId}}, _set: {emailNotificationsEnabled: $emailNotificationsEnabled, formEnabled: $formEnabled, openTrackingEnabled: $openTrackingEnabled}) {
      returning {
        presentationId
        title
        userId
        content
        url
        emailNotificationsEnabled
        openTrackingEnabled
        formEnabled
        thumbnail
      }
    }
  }
`
export const UPDATE_PRESENTATION_FILE = gql`
  mutation UpdatePresentationFile($presentationId: String!,$userId: String!,$originalFileUrl: String!, $filesize: Int, $originalFileType: String) {
    update_presentations(
      where: {
        presentationId: {_eq: $presentationId}, 
        userId: {_eq: $userId}
      }, _set: {
        filesize: $filesize, 
        originalFileUrl: $originalFileUrl,
        originalFileType: $originalFileType
      }) {
      returning {
        presentationId
        title
        userId
        content
        url
        originalFileUrl
      }
    }
  }
`

export const UPDATE_PRESENTATION_TITLE = gql`
  mutation UpdatePresentationTitle($presentationId: String!,$userId: String!,$title: String!) {
    update_presentations(where: {presentationId: {_eq: $presentationId}, userId: {_eq: $userId}}, _set: {title: $title}) {
      returning {
        presentationId
        title
        userId
        content
        url
      }
    }
  }
`
