import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';

const LoginToComment = ({ isOpen, dismiss, goToLogin, commentAnonymously }) => {
  const [visible, setVisible] = useState(isOpen);
  const [email, setEmail] = useState('');

  useEffect(() => {
    setVisible(isOpen);
  }, [isOpen]);

  const handleDismiss = () => {
    setVisible(false);
    if (dismiss) dismiss();
  };

  const handleLogin = () => {
    goToLogin(email);  // Assume `goToLogin` handles login with email
  };

  if (!visible) return null;

  return (
    <PopupContainer>
      <Popup>
        <PopupContent>
          <CloseButton onClick={handleDismiss}>&times;</CloseButton>
          <ContentWrapper>
            <Title>Want to leave a comment?</Title>
            <Description>
              Sign up so the sender knows who left the comment, or cancel to comment anonymously.
            </Description>
            <EmailInput
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="user@example.com"
            />
            <ButtonGroup>
              <CancelButton onClick={handleDismiss}>Cancel</CancelButton>
              <ContinueButton onClick={handleLogin}>Continue</ContinueButton>
            </ButtonGroup>
            <LoginInfo>
              Already use Sizle? <LoginLink onClick={handleLogin}>Log in</LoginLink>
            </LoginInfo>
          </ContentWrapper>
        </PopupContent>
      </Popup>
    </PopupContainer>
  );
};

// Styled components

const PopupContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.75);
  z-index: 1000;
`;

const Popup = styled.div`
  background-color: #fff;
  border-radius: 10px;
  padding: 30px;
  width: 25%;
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.1);
  position: relative;
  text-align: center;
`;

const PopupContent = styled.div`
  position: relative;
`;

const CloseButton = styled.button`
  position: absolute;
  top: -45px;
  right: -65px;
  background: none;
  border: none;
  font-size: 1.5em;
  color: #888;
  cursor: pointer;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Title = styled.h2`
  margin-bottom: 16px;
  font-size: 1.7em;
  color: #333;
`;

const Description = styled.p`
  margin-bottom: 20px;
  font-size: 1.1em;
  color: #666;
`;

const EmailInput = styled.input`
  width: 100%;
  padding: 12px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #f9f9f9;
  font-size: 1em;
  outline: none;

  ::placeholder {
    color: #aaa;
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const CancelButton = styled.button`
  padding: 10px 20px;
  background-color: #f5f5f5;
  color: #333;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  &:hover {
    background-color: #e0e0e0;
  }
`;

const ContinueButton = styled.button`
  padding: 10px 20px;
  background-color: #4f46e5;
  color: #fff;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;

  &:hover {
    background-color: #4338ca;
  }
`;

const LoginInfo = styled.p`
  margin-top: 16px;
  font-size: 0.9em;
  color: #666;
`;

const LoginLink = styled.span`
  color: #4f46e5;
  cursor: pointer;
  text-decoration: underline;

  &:hover {
    color: #4338ca;
  }
`;

export default LoginToComment;
