import React, { useContext, useMemo, useCallback, useState } from 'react'
import { workspaceContext } from '../../../context'
import SVGInline from 'react-svg-inline'
import { penEdit } from '../../../design-system/icons'
import styles from './RoleEditPopup.module.scss'
export const RoleEditPopup = ({ close, open, isOpen, roleId, roleName, ...props }) => {
  const { setDefaultRole, renameRole, addRolePermission, removeRolePermission, currentWorkspaceRoles, permissions } = useContext(workspaceContext)
  const [loading, setLoading] = useState({})
  const [loadingDefault, setLoadingDefault] = useState(false)
  const [renaming, setRenaming] = useState(false)
  const editable = useMemo(() => currentWorkspaceRoles.find(r => r.role_id === roleId)?.is_editable, [roleId, currentWorkspaceRoles])
  const isDefault = useMemo(() => currentWorkspaceRoles.find(r => r.role_id === roleId)?.is_default, [roleId, currentWorkspaceRoles])
  const closePopup = useCallback((e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault()
    e.stopPropagation()
    close()
    console.log('closing')
  }, [close])
  const openPopup = useCallback((e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault()
    e.stopPropagation()
    if (!props.disabled) open()
  }, [open, props?.disabled])
  const role = useMemo(() => currentWorkspaceRoles.find(r => r.role_id === roleId), [roleId, currentWorkspaceRoles])

  const eatEvent = useCallback((e) => {
    e.stopPropagation()
  }, [])
  const enablePermission = useCallback(async (permission) => {
    setLoading((l) => ({ ...l, [permission]: true }))
    await addRolePermission(roleId, permission)
    setLoading((l) => ({ ...l, [permission]: false }))
  }, [addRolePermission, roleId])
  const disablePermission = useCallback(async (permission) => {
    setLoading((l) => ({ ...l, [permission]: true }))
    await removeRolePermission(roleId, permission)
    setLoading((l) => ({ ...l, [permission]: false }))
  }, [removeRolePermission, roleId])
  const submitRenameRole = useCallback(async (e) => {
    e.preventDefault()
    setRenaming(true)
    await renameRole(roleId, e.target.name.value)
    setRenaming(false)
  }, [renameRole, roleId])
  const submitDefaultRole = useCallback(async () => {
    setLoadingDefault(true)
    await setDefaultRole(roleId)
    setLoadingDefault(false)
  }, [setDefaultRole, setLoadingDefault])
  return (
    <div {...props} className={styles.anchor} onMouseDown={eatEvent} onClick={eatEvent}>
      <SVGInline title='Customize role permissions' className={styles['pencil-icon']} svg={penEdit} onMouseDown={openPopup} />
      {isOpen &&
        <div className={styles['anchor-child']}>
          <div hidden={!isOpen} onMouseDown={closePopup} className={styles['popup-background']} />
          <div className={styles.popup}>
            <span className={styles.header}>
              <form onSubmit={submitRenameRole}> <div className={renaming ? 'loading-spinner' : ''}><input disabled={!role.is_editable} title={!role.is_editable && 'This role may not be edited'} onKeyDown={eatEvent} name='name' defaultValue={roleName} /></div></form>
              {isDefault
              ? <span title='This is the default role for new members' className={styles['is-default']}>Default role</span>
              : <span onClick={submitDefaultRole} className={`btn ${loadingDefault ? 'loading-spinner' : ''}`}>Make default</span>}
            </span>
            <div className={styles['permission-list']}>
              {permissions.map((permission) => {
              const permissionEnabled = role.permissions.find(mp => mp.permission === permission.name)
              return (
                <button
                  key={`toggle-${permission.name}`}
                  disabled={!editable}
                  title={!editable && 'This role may not be edited'}
                  onClick={() => permissionEnabled ? disablePermission(permission.name) : enablePermission(permission.name)}
                  className={`${styles['permission-control']} }`}
                >
                  <div className={`switch ${permissionEnabled && 'on'}`} />
                  <div className={loading[permission.name] && 'loading-spinner'}>
                    <span>{permission.name}</span>
                    <div>{permission.description}</div>
                  </div>
                </button>
)
            })}
            </div>
          </div>
        </div>}
    </div>
)
}
