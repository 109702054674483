import { workspaceContext } from '../context/WorkspaceContext'
import { useApolloClient } from '@apollo/client'
import { UPDATE_COMPANY, INSERT_COMPANY, DELETE_COMPANIES, GET_RECIPIENTS_QUERY } from '../graphql/organisations'
import { UPDATE_RECIPIENT, INSERT_RECIPIENT, DELETE_RECIPIENTS } from '../graphql/recipients'
import React, { useEffect, useState, useContext } from 'react'
export const organisationContext = React.createContext({})
export const OrganisationProvider = ({ children }) => {
  const { workspace } = useContext<any>(workspaceContext)
  const gqlClient = useApolloClient()

  const [organisations, setOrganisations] = useState([])
  const [recipients, setRecipients] = useState([])
  const [loading, setLoading] = useState(true)

  const fetchOrganisations = async () => {
    if (!organisations || organisations.length === 0) {
      setLoading(true)
    }
    const { data: { recipients, organisations: newOrganisations }, error } = await gqlClient.query({
      query: GET_RECIPIENTS_QUERY,
      fetchPolicy: 'no-cache',
      variables: {
        workspaceId: workspace.workspace_id
      }
    })
    if (error) {
      setLoading(false)
      throw error
    }
    setOrganisations(newOrganisations)
    setRecipients(recipients)
    setLoading(false)
  }

  const createOrganisation = async (orgName: string) => {
    const { data: { insert_organisations: { returning: [org] } } } = await gqlClient.mutate({
      mutation: INSERT_COMPANY,
      variables: {
        orgName,
        workspaceId: workspace.workspace_id
      }
    })
    await fetchOrganisations()
    return org
  }

  const updateOrganisationName = async (orgId: string, orgName: string) => {
    await gqlClient.mutate({
      mutation: UPDATE_COMPANY,
      variables: {
        orgId,
        orgName
      }
    })
    await fetchOrganisations()
  }

  const deleteOrganisations = async (orgIds = []) => {
    await gqlClient.mutate({
      mutation: DELETE_COMPANIES,
      variables: {
        orgIds
      }
    })
    await fetchOrganisations()
  }

  const createRecipient = async (recipientName: string, recipientEmail: string, recipientContact: string, orgId?: string) => {
    const { data: { insert_recipients: { returning: [recipient] } } } = await gqlClient.mutate({
      mutation: INSERT_RECIPIENT,
      variables: {
        recipientName,
        recipientEmail,
        recipientContact,
        workspaceId: workspace.workspace_id,
        orgId
      }
    })
    await fetchOrganisations()
    return recipient
  }

  const updateRecipient = async (recipientId: string, recipientName: string, recipientEmail: string, recipientContact: string, orgId: string) => {
    await gqlClient.mutate({
      mutation: UPDATE_RECIPIENT,
      variables: {
        recipientId,
        recipientName,
        recipientEmail,
        recipientContact,
        orgId
      }
    })
    await fetchOrganisations()
  }

  const deleteRecipients = async (recipientIds = []) => {
    await gqlClient.mutate({
      mutation: DELETE_RECIPIENTS,
      variables: {
        recipientIds
      }
    })
    await fetchOrganisations()
  }

  useEffect(() => {
    if (workspace?.workspace_id) {
      fetchOrganisations()
    }
  }, [workspace?.workspace_id])

  return (
    <organisationContext.Provider value={{
    organisations,
    recipients,
    fetchOrganisations,
    updateOrganisationName,
    deleteOrganisations,
    createRecipient,
    updateRecipient,
    createOrganisation,
    deleteRecipients,
    loading
  }}
    >
      {children}
    </organisationContext.Provider>
)
}
