import React from 'react'

import { useModal } from 'src/modules/modals'

import { UpgradeModal } from './UpgradeModal'
import { OnboardingModal } from './onboarding-modal'
import ShareModal from './ShareModal'
import WorkspaceInvitationModal from './WorkspaceInvitationModal'
import CreateNewWorkspaceContainerModal from './CreateNewWorkspaceContainerModal'
import CreateNewDocumentModal from './CreateNewDocumentModal'
import WorkspaceOverfilledModal from './WorkspaceOverfilledModal'

const Modals = ({ user }) => {
  const {
    modal: { type, data },
    setModal
  } = useModal()

  if (!type) {
    return null
  }

  const dismissModal = () => setModal(null)

  const baseProps = {
    dismissModal,
    user,
    ...data
  }

  if (type === 'upgrade') return <UpgradeModal {...baseProps} />
  if (type === 'onboarding') return <OnboardingModal {...baseProps} />
  if (type === 'share_presentation') return <ShareModal {...baseProps} />
  if (type === 'workspace_invintation') return <WorkspaceInvitationModal {...baseProps} />
  if (type === 'create_workspace') return <CreateNewWorkspaceContainerModal {...baseProps} />
  if (type === 'create_document') return <CreateNewDocumentModal {...baseProps} />
  if (type === 'overfilled') return <WorkspaceOverfilledModal {...baseProps} />

  return null
}

export default Modals
