import { useCallback, useEffect } from 'react'
import { useApolloClient, useQuery, gql } from '@apollo/client'

const GET_MODAL = gql`
  query getModal {
    modal @client {
      type
      data
      __typename
    }
  }
`

const defaultModal = {
  type: null,
  data: null,
  __typename: 'modal'
}

const useModal = (initial) => {
  const client = useApolloClient()

  const setModal = useCallback(
    (type, data) => {
      if (!type) {
        return client.writeQuery({
          query: GET_MODAL,
          data: { modal: defaultModal }
        })
      }

      client.writeQuery({
        query: GET_MODAL,
        data: {
          modal: {
            type,
            data: data ? JSON.stringify(data) : null,
            __typename: 'modal'
          }
        }
      })
    },
    [client]
  )

  useEffect(() => {
    if (initial) {
      setModal(initial)
    }
  }, [initial, setModal])

  const { data: { modal = defaultModal } = {} } = useQuery(GET_MODAL)

  const formattedModal = modal.type
    ? {
      ...modal,
      data: modal.data ? JSON.parse(modal.data) : null
    }
    : modal

  return { modal: formattedModal, setModal }
}

export default useModal
