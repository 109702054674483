import { GraphQLWsLink } from '@apollo/client/link/subscriptions'
import { SentryLink } from 'apollo-link-sentry'
import { setContext } from '@apollo/client/link/context'
import {
  ApolloClient, ApolloLink,
  ApolloProvider, HttpLink, InMemoryCache, split
} from '@apollo/client'
import { createClient } from 'graphql-ws'
import { UserManager } from 'oidc-react'
import React, { useState } from 'react'

const cache = new InMemoryCache({ addTypename: false })

const createApolloClient = (userManager: UserManager) => {
  const wsUrl = `wss://${window.location.host}/v1/graphql`
  const httpUrl = `https://${window.location.host}/v1/graphql`

  const wsLink = new GraphQLWsLink(createClient({
    url: wsUrl,
    connectionParams: async () => {
      const user = await userManager.getUser()
      return {
        headers: {
          Authorization: user ? `Bearer ${user.access_token}` : undefined
        }
      }
    }
  }))
  const httpLink = new HttpLink({
    uri: httpUrl
  })

  const authLink = setContext(async (_, { headers, connectionParams }) => {
    const user = await userManager.getUser()
    if (!user) {
      return { headers }
    } else {
      return {
        headers: {
          ...headers,
          authorization: `Bearer ${user.access_token}`
        }
      }
    }
  })

  const sentryLink = new SentryLink({
    attachBreadcrumbs: {
      includeQuery: true,
      includeVariables: true,
      includeError: true,
      includeFetchResult: true
    }
  })

  const link = ApolloLink.from([
    sentryLink,
    authLink,
    split(
      // split based on operation type
      ({ query }) => {
        const { kind, operation } = query.definitions[0]
        return kind === 'OperationDefinition' && operation === 'subscription'
      },
      wsLink,
      httpLink
    )
  ])

  if (wsLink && wsLink.subscriptionClient) {
    wsLink.subscriptionClient.maxConnectTimeGenerator.duration = () => wsLink.subscriptionClient.maxConnectTimeGenerator.max
  }

  return new ApolloClient({
    link,
    connectToDevTools: process.env.NODE_ENV === 'development',
    cache
  })
}

export const AuthApolloProvider: React.FunctionComponent<{userManager: UserManager}> = ({ userManager, children }) => {
  const [client] = useState(createApolloClient(userManager))

  return (
    <ApolloProvider client={client}>
      {children}
    </ApolloProvider>
  )
}
