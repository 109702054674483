import React, { useEffect, ReactNode, useMemo, useCallback, useState, useContext } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useQuery, useMutation, useLazyQuery } from '@apollo/client'
import axios from 'axios'
import { customAlphabet } from 'nanoid'

import {
  GET_USER_DETAILS,
  UPDATE_WORKSPACE_NAME,
  DELETE_WORKSPACE_MEMBER,
  CREATE_INVITATION_LINK,
  DELETE_INVITATION_LINK,
  UPDATE_INVITATION_LINK
} from 'src/graphql/workspaces'
import { CREATE_FOLDER_QUERY, GET_FOLDERS_QUERY } from 'src/graphql/folders'
import { DELETE_WORKSPACE_INVITE } from 'src/graphql/workspace-invites'
import { getUserType } from 'src/utils/user'
import { subscriptionContext } from './SubscriptionContext'
import { ADD_WORKSPACE_EVENT } from 'src/graphql/workspace-events'
import { sendAmplitudeData } from 'src/utils/amplitude'
import { userContext } from './UserContext'
import { OrganisationProvider } from '.'
import { useModal } from 'src/modules/modals'
import { toastr } from 'react-redux-toastr'

type Permission = 'Workspace Settings' | 'Manage Folders' | 'Add & Edit Documents' | 'Delete Documents' | 'Analytics & Reports' | 'Share Documents' | 'Manage Contacts'

type WorkspaceContext = {
  workspaces: any[],
  folders: any[],
  workspaceIndex: (null | number),
  currentWorkspaceSeatPlan: null,
  permissions: any[],
  workspacePermissions: Permission[],
  currentWorkspaceRoles: any[],
  currentWorkspacePlan: null,
  addCustomDomain: (domain: string) => Promise<void>,
  createWorkspaceRole: (roleName: string) => Promise<void>,
  hasWorkspacePermission: (permission: Permission) => boolean,
  addRolePermission: (role: string, permission: Permission) => Promise<void>,
  removeRolePermission: (role: string, permission: Permission) => Promise<void>,
  customDomains: string[],
  deleteCustomDomain: (url: string) => Promise<void>,
  toggleCustomDomain: (url: string, enabled: boolean) => Promise<void>,
  updateWorkspaceAvatar: (file: File) => Promise<void>,
  updateWorkspaceName: (name: string) => Promise<void>,
  renameDocument: (documentId: string, title: string) => Promise<void>,
  deleteDocument: (documentId: string) => Promise<void>,
  invites: any[]
}

const defaultWorkspaceContext: WorkspaceContext & {[key: string]: any} = {
  workspace: null,
  workspaces: [],
  folders: [],
  workspaceIndex: null,
  currentWorkspaceSeatPlan: null,
  permissions: [],
  workspacePermissions: [],
  currentWorkspaceRoles: [],
  currentWorkspacePlan: null,
  addCustomDomain: async () => {},
  createWorkspaceRole: async () => {},
  hasWorkspacePermission: () => { return false },
  addRolePermission: async () => {},
  removeRolePermission: async () => {},
  customDomains: [],
  deleteCustomDomain: async () => {},
  toggleCustomDomain: async () => {},
  updateWorkspaceAvatar: async () => {},
  updateWorkspaceName: async () => {},
  renameDocument: async () => {},
  deleteDocument: async () => {},
  invites: []
}

export const workspaceContext = React.createContext(defaultWorkspaceContext)

export const WorkspaceProvider = ({ children }: {children: ReactNode | ReactNode[]}) => {
  const [workspaceIdentifier, setWorkspaceIdentifier] = useState(null)
  const { user, activeFolder, setUserWorkspaces, setCurrentWorkspace: setCurrentUserWorkspace } = useContext(userContext)
  const workspaceIndex = workspaceIdentifier ? parseInt(workspaceIdentifier) : 0
  const { purchaseWorkspaceSubsription, tryExtendTrial, trialPlan, seatPlan, freePlan } = useContext(subscriptionContext)
  const [workspaces, setWorkspaces] = useState<any[]>([])
  const [presentations, setPresentations] = useState<any[]>([])
  const [currentWorkspace, setCurrentWorkspace] = useState<any>(null)
  const refetchQueries = { refetchQueries: [{ query: GET_USER_DETAILS, variables: { email: user?.email, userId: user?.sub, activeFolder } }] }
  const { refetch: refetchWorkspaces, loading: workspacesLoading, data: getWorkspacesResult } = useQuery(GET_USER_DETAILS, { variables: { userId: user?.sub, email: user?.email, activeFolder } })
  const [updateWorkspaceNameGql] = useMutation(UPDATE_WORKSPACE_NAME, refetchQueries)
  const [removeInviteGql] = useMutation(DELETE_WORKSPACE_INVITE)
  const [removeUserGql] = useMutation(DELETE_WORKSPACE_MEMBER, refetchQueries)
  const [addWorkspaceEvent] = useMutation(ADD_WORKSPACE_EVENT)
  const [addFolderMtn] = useMutation(CREATE_FOLDER_QUERY)
  const [getFoldersQuery, { data: folders, refetch: refetchFolders }] = useLazyQuery(GET_FOLDERS_QUERY)
  useEffect(() => currentWorkspace?.workspace_id && getFoldersQuery({ variables: { workspaceId: currentWorkspace?.workspace_id } }), [getFoldersQuery, currentWorkspace?.workspace_id])
  const [createLink] = useMutation(CREATE_INVITATION_LINK)
  const [deleteLink] = useMutation(DELETE_INVITATION_LINK)
  const [updateLink] = useMutation(UPDATE_INVITATION_LINK)
  const [storageLimitExceededPromptVisible, toggleStorageLimitExceededPrompt] = useState(false)
  const permissions = useMemo(() => getWorkspacesResult?.permissions || [], [getWorkspacesResult])
  const userPermissions = useMemo(() => ((currentWorkspace?.workspace_members?.find((wm: any) => wm.user_id === user?.sub)?.role?.permissions || []).map((p: any) => p.permission)), [user, currentWorkspace])
  const invites = useMemo(() => getWorkspacesResult?.workspace_invites || [], [getWorkspacesResult])
  const { setModal } = useModal()
  const { pathname } = useLocation()
  const navigate = useNavigate()

  const updateWorkspaceAvatar = async (file: File) => {
    const formData = new FormData()
    formData.append('avatar', file)
    await axios(`/api/workspaces/${currentWorkspace.workspace_id}/avatar`, {
      method: 'POST',
      data: formData
    })
    await refetchWorkspaces()
  }

  const updateWorkspaceName = async (name: string) => {
    if (user?.sub) {
      await updateWorkspaceNameGql({
        variables: { userId: user.sub, workspaceId: currentWorkspace.workspace_id, workspaceName: name }
      })
    } else {
      throw new Error('Attempt to update workspace name before user is authed')
    }
  }

  const removeInvite = async (inviteId: string) => {
    await removeInviteGql({
      variables: { inviteId }
    })
    await refetchWorkspaces()
  }

  const removeUser = async (userId: string) => {
    await removeUserGql({
      variables: { userId, workspaceId: currentWorkspace.workspace_id }
    })
    addWorkspaceEvent({
      variables: {
        workspace_id: currentWorkspace.workspace_id,
        type: 'remove',
        info: { userId }
      }
    })
  }

  const acceptInvite = async (inviteId: string, accepted: boolean) => {
    const invite = invites.find((i: any) => i.invite_id === inviteId)
    await axios.post('/api/self/invites', {
      inviteId,
      emailAddress: user?.email,
      accepted
    }, {
      headers: {
        'Content-Type': 'application/json'
      }
    })
    sendAmplitudeData('USER_JOINED_WORKSPACE')
    addWorkspaceEvent({
      variables: {
        workspace_id: invite.workspace_id,
        type: 'join',
        info: {
          userId: user?.sub
        }
      }
    })
    await refetchWorkspaces()
  }

  const inviteUser = async (email: string, workspaceId = currentWorkspace.workspace_id) => {
    await axios.post(`/api/workspaces/${workspaceId}/invites`, { email }, { withCredentials: true })
    addWorkspaceEvent({
      variables: {
        workspace_id: workspaceId || currentWorkspace.workspace_id,
        type: 'invite',
        info: {
          email
        }
      }
    })
    await tryExtendTrial('InviteTeamMember')
    await refetchWorkspaces()
  }

  const updateWorkspaceSeats = useCallback(async (seatCount: number) => {
    await purchaseWorkspaceSubsription(currentWorkspace?.workspace_id, seatCount)
    await refetchWorkspaces({ userId: user?.sub })
  }, [currentWorkspace?.workspace_id, purchaseWorkspaceSubsription, refetchWorkspaces, user?.sub])

  const previewWorkspaceSeatCost = async (seatCount: number) => {
    const res = await axios.get(`/api/workspaces/${currentWorkspace.workspace_id}/subscription?seats=${seatCount}`, {
      headers: {
        'Content-Type': 'application/json'
      }
    })
    return res.data
  }

  const joinWorkspace = async (invitationLinkId: string) => {
    const result = await axios.post(`/api/invites/${invitationLinkId}/join`, {}, {
      headers: {
        'Content-Type': 'application/json'
      }
    })

    await refetchWorkspaces({ userId: user?.sub })
    return result.data
  }

  const createInvitationLink = async () => {
    await createLink({
      variables: {
        workspaceId: currentWorkspace.workspace_id,
        invitationLinkId: customAlphabet('123456789abcdefgh', 10)()
      }
    })
    refetchWorkspaces({ userId: user?.sub })
  }

  const deleteInvitationLink = async (invitationLinkId: string) => {
    await deleteLink({
      variables: {
        invitationLinkId
      }
    })
    refetchWorkspaces({ userId: user?.sub })
  }

  const updateLinkExtensions = async (invitationLinkId: string, emailExtensions: string[]) => {
    await updateLink({
      variables: {
        invitationLinkId,
        emailExtensions
      }
    })
    refetchWorkspaces({ userId: user?.sub })
  }

  const addFolder = async (folderName: string, parentId = null) => {
    const res = await addFolderMtn({ variables: { folderName, workspaceId: currentWorkspace.workspace_id, parentId } })
    await refetchFolders({ workspaceId: currentWorkspace.workspace_id })
    return res.data.insert_folders.returning[0]
  }

  useEffect(() => {
    if (workspaceIndex > 0 && !workspaces[workspaceIndex]) {
      navigate('/s/0/presentations')
    }
    setCurrentWorkspace(workspaces[workspaceIndex || 0])
  }, [workspaceIndex, workspaces])

  const uploadDocument = useCallback(async (document: File) => {
    setModal('create_document')
    const formData = new FormData()
    formData.append('document', document)
    try {
      await axios({
        url: `/api/folder/${activeFolder}/documents`,
        method: 'POST',
        withCredentials: true,
        data: formData
      })
      await refetchWorkspaces()
    } catch (e) {
      if ((e as any).response.data.error === 'Document limit exceeded' || (e as any).response.data.error === 'Storage limit exceeded') {
        toggleStorageLimitExceededPrompt(true)
      } else if ((e as any).response.status === 401) {
        toastr.error('Access denied', { timeout: 1000 })
      } else {
        toastr.error('Failed to upload document, please contact support!')
      }
      console.log((e as any).response)
    }
    setModal(null)
    tryExtendTrial('CreateDocument')
  }, [activeFolder, setModal, refetchWorkspaces, tryExtendTrial])

  useEffect(() => {
    const updateWorkspaces = async () => {
      if (getWorkspacesResult) {
        const userWorkspaceDetails = getWorkspacesResult.workspace_members || []
        const workspaces = await Promise.all(userWorkspaceDetails.map(async (memberDetails: any) => {
          const workspacePlan =
            (getUserType(memberDetails.workspace.owner).isProTrial && trialPlan) ||
            memberDetails.workspace.owner.subscription?.plan ||
            freePlan
          const isPro =
            // Does the owner have a non-FREE plan
            (memberDetails?.workspace?.owner?.subscription?.plan &&
              !memberDetails?.workspace?.owner?.subscription?.plan.planId.includes('FREE')) ||
            // Or a protrial
            getUserType(memberDetails.workspace?.owner).isProTrial
          return {
            ...memberDetails.workspace,
            baseSeatCount: workspacePlan.seatCount,
            availableSeats: workspacePlan.seatCount + (memberDetails?.workspace?.subscription?.purchasedSeats || 0),
            isAdmin: user?.sub === memberDetails?.workspace?.owner.auth0_id,
            isProWorkspace: isPro,
            isOverfilled: memberDetails.workspace.workspace_members.length > (workspacePlan.seatCount || 999) + (memberDetails.workspace?.subscription?.purchasedSeats || 0),
            isFilled: memberDetails.workspace.workspace_members.length >= (workspacePlan.seatCount || 999) + (memberDetails.workspace?.subscription?.purchasedSeats || 0),
            isOverStorage: workspacePlan.storageLimit && memberDetails.workspace.presentations_aggregate.aggregate.sum.filesize > workspacePlan.storageLimit,
            fileCount: memberDetails.workspace?.presentations_aggregate?.aggregate?.count,
            isOverFileLimit: workspacePlan.documentLimit && memberDetails?.workspace?.presentations_aggregate?.aggregate?.count >= workspacePlan.documentLimit
          }
        }))
        setWorkspaces(workspaces)
        setCurrentUserWorkspace && setCurrentUserWorkspace(workspaces[workspaceIndex]?.workspace_id)
        setUserWorkspaces && setUserWorkspaces(workspaces)
        setPresentations(getWorkspacesResult.presentations)
      }
    }
    updateWorkspaces()
  }, [getWorkspacesResult, workspaceIndex, trialPlan, freePlan])

  return (
    <workspaceContext.Provider
      value={{
        workspaces,
        presentations,
        workspace: currentWorkspace,
        workspacesLoading,
        currentWorkspaceRoles: useMemo(() => (currentWorkspace?.roles || []), [currentWorkspace]),
        currentWorkspacePlan: (getUserType(currentWorkspace?.owner).isProTrial && trialPlan) || currentWorkspace?.owner?.subscription?.plan || freePlan,
        currentWorkspaceSeatPlan: currentWorkspace?.subscription?.plan || seatPlan,
        workspaceIndex,
        updateWorkspaceAvatar,
        updateWorkspaceName,
        removeInvite,
        removeUser,
        inviteUser,
        invites,
        acceptInvite,
        permissions,
        workspacePermissions: userPermissions,
        updateWorkspaceSeats,
        previewWorkspaceSeatCost,
        showStorageFilledPrompt: () => toggleStorageLimitExceededPrompt(true),
        hideStorageFilledPrompt: () => toggleStorageLimitExceededPrompt(false),
        showingStorageFilledPrompt: storageLimitExceededPromptVisible,
        joinWorkspace,
        createInvitationLink,
        deleteInvitationLink,
        updateLinkExtensions,
        hasWorkspacePermission: useCallback((p: string) => userPermissions.includes(p), [userPermissions]),
        folders: folders?.folders || [],
        addFolder,
        customDomains: currentWorkspace?.custom_domains || [],
        refetchWorkspaces,
        theme: currentWorkspace?.theme,
        setWorkspaceIdentifier,
        createWorkspaceRole: useCallback(async (roleName: string) => {
          await axios({
            method: 'POST',
            withCredentials: true,
            url: `/api/workspaces/${currentWorkspace?.workspace_id}/roles`,
            data: { role: roleName }
          })
          await refetchWorkspaces()
        }, [currentWorkspace?.workspace_id, refetchWorkspaces]),
        setUserRole: useCallback(async (userId: string, roleId: string) => {
          await axios({
            method: 'POST',
            withCredentials: true,
            url: `/api/workspaces/${currentWorkspace?.workspace_id}/members/${userId}/role`,
            data: { role: roleId }
          })
          await refetchWorkspaces()
        }, [currentWorkspace?.workspace_id, refetchWorkspaces]),
        setInviteRole: useCallback(async (inviteId: string, roleId: string) => {
          await axios({
            method: 'POST',
            withCredentials: true,
            url: `/api/invites/${inviteId}/role`,
            data: { roleId }
          })
          await refetchWorkspaces()
        }, [refetchWorkspaces]),
        removeRolePermission: useCallback(async (roleId: string, permission: string) => {
          await axios({
            method: 'DELETE',
            withCredentials: true,
            url: `/api/roles/${roleId}/permissions/${encodeURIComponent(permission)}`
          })
          await refetchWorkspaces()
        }, [refetchWorkspaces]),
        addRolePermission: useCallback(async (roleId: string, permission: string) => {
          await axios({
            method: 'POST',
            withCredentials: true,
            url: `/api/roles/${roleId}/permissions/${encodeURIComponent(permission)}`
          })
          await refetchWorkspaces()
        }, [refetchWorkspaces]),
        renameRole: useCallback(async (roleId: string, name: string) => {
          await axios({
            method: 'POST',
            withCredentials: true,
            url: `/api/roles/${roleId}/rename`,
            data: { name }
          })
          await refetchWorkspaces()
        }, [refetchWorkspaces]),
        deleteRole: useCallback(async (roleId: string) => {
          await axios({
            method: 'DELETE',
            withCredentials: true,
            url: `/api/roles/${roleId}`
          })
          await refetchWorkspaces()
        }, [refetchWorkspaces]),
        createWorkspace: useCallback(async (workspaceName: string, avatarFile: string) => {
          const formData = new FormData()
          formData.append('avatar', avatarFile)
          formData.append('name', workspaceName)
          await axios({
            method: 'POST',
            url: '/api/workspaces',
            data: formData,
            withCredentials: true
          })
          await tryExtendTrial('CreateWorkspace')
          await refetchWorkspaces()
        }, [tryExtendTrial, refetchWorkspaces]),
        setDefaultRole: useCallback(async (roleId: string) => {
          await axios({
            method: 'POST',
            url: `/api/workspaces/${currentWorkspace.workspace_id}/roles/default`,
            data: { roleId },
            withCredentials: true
          })
          await refetchWorkspaces()
        }, [refetchWorkspaces, currentWorkspace?.workspace_id]),
        addCustomDomain: useCallback(async (domain: string) => {
          try {
            await axios({
              method: 'POST',
              url: `/api/workspaces/${currentWorkspace.workspace_id}/customDomain`,
              data: { domain },
              withCredentials: true
            })
          } catch (e: any) {
            if (e.response.status === 400 && e.response.data.error === 'Domain in use') { throw new Error('Domain in use') } else { throw e }
          }
          await refetchWorkspaces()
        }, [refetchWorkspaces, currentWorkspace?.workspace_id]),
        deleteCustomDomain: useCallback(async (domain: string) => {
          await axios({
            method: 'DELETE',
            url: `/api/workspaces/${currentWorkspace.workspace_id}/customDomain/${domain}`,
            withCredentials: true
          })
          await refetchWorkspaces()
        }, [refetchWorkspaces, currentWorkspace?.workspace_id]),
        toggleCustomDomain: useCallback(async (domain: string, state: boolean) => {
          await axios({
            method: 'POST',
            url: `/api/workspaces/${currentWorkspace.workspace_id}/customDomain/${domain}/toggle`,
            data: { state },
            withCredentials: true
          })
          await refetchWorkspaces()
          if (window.location.hostname === domain) {
            window.location.href = `${import.meta.env.VITE_ROOT_DOMAIN}${pathname}`
          }
        }, [pathname, refetchWorkspaces, currentWorkspace?.workspace_id]),
        updateTheme: useCallback(async (banner: File) => {
          const formData = new FormData()
          formData.append('banner', banner)
          await axios({
            method: 'POST',
            url: `/api/workspaces/${currentWorkspace.workspace_id}/theme`,
            data: formData,
            withCredentials: true
          })
          await refetchWorkspaces()
        }, [refetchWorkspaces, currentWorkspace?.workspace_id]),
        renameDocument: useCallback(async (documentId: string, title: string) => {
          await axios({
            method: 'POST',
            url: `/api/documents/${documentId}/rename`,
            data: { title },
            withCredentials: true
          })
          await refetchWorkspaces()
        }, [refetchWorkspaces]),
        deleteDocument: useCallback(async (documentId: string) => {
          await axios({
            method: 'DELETE',
            url: `/api/documents/${documentId}`,
            withCredentials: true
          })
          await refetchWorkspaces()
        }, [refetchWorkspaces]),
        uploadDocument,
        importDocument: useCallback(async ({ url, title, authorization }: {url: string, title: string, authorization: string}) => {
          setModal('create_document')
          try {
            await axios({
              method: 'POST',
              url: `/api/folder/${activeFolder}/import`,
              withCredentials: true,
              data: { url, title, authorization },
              headers: { 'Content-Type': 'application/json' }
            })
            await refetchWorkspaces()
          } catch (e) {
            if ((e as any).response.data.error === 'Document limit exceeded' || (e as any).response.data.error === 'Storage limit exceeded') {
              toggleStorageLimitExceededPrompt(true)
            }
          }
          setModal(null)
        }, [activeFolder, setModal, refetchWorkspaces]),
        createDraftPresentation: useCallback(async () => {
          setModal('create_document')
          try {
            await axios({
              method: 'POST',
              url: `/api/folder/${activeFolder}/draft`,
              withCredentials: true,
              headers: { 'Content-Type': 'application/json' }
            })
            await tryExtendTrial('CreateDocument')
            await refetchWorkspaces()
          } catch (e) {
            if ((e as any).response.data.error === 'Document limit exceeded' || (e as any).response.data.error === 'Storage limit exceeded') {
              toggleStorageLimitExceededPrompt(true)
            }
          }
          setModal(null)
        }, [activeFolder, refetchWorkspaces, setModal, tryExtendTrial])
      }}
    >
      <OrganisationProvider>
        {children}
      </OrganisationProvider>
    </workspaceContext.Provider>
  )
}
