import React from 'react'

const WorkspaceOverfilledModal = ({ adminEmail, dismissModal }) => {

  return (
    <div className='popup-container fullscreen dark'>
      <div className='popup modal'>
        <div className='popup-content'>
          <button className='popup-close'/>
          <h2>Reactivate workspace</h2>
          <p>This workspace has exceeded its seat limit.</p>
          <p>Contact the workspace administrator {adminEmail && (<a href={`mailto:${adminEmail}`}>{adminEmail}</a>)} to reactivate the workspace.</p>
          <center>
            <button className='btn primary' onClick={dismissModal}>Close</button>
          </center>
        </div>
      </div>
    </div>
  )
}

export default WorkspaceOverfilledModal
