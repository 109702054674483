import React from 'react'
import styled from '@emotion/styled'

const BaseInput = React.forwardRef((props, ref) => {
  return (
    <Element ref={ref} {...props} />
  )
})

const Element = styled.input`
  border-radius: ${props => props.square ? '3px' : '8px'};
  height: 30px;
  line-height: 12px;
  padding-left: 10px;
  border: solid 1px #97979755;
  background: #f7f7f5;
  font-size: 14px;
  color: ${props => props.disabled ? props.theme.brand.labelDark : 'unset'};

  &:invalid:focus {
    border-color: red;
  }
`

export default BaseInput
