import React from 'react'
import { Link } from 'react-router-dom'

// NOTE: https://github.com/ReactTraining/react-router/issues/2319
// `Link`s in react-router-dom unfortunately does not support any kind
// of placeholder syntax, so we need to wrap it with our own.
export const OptionalLink = React.forwardRef(({ children, ...props }, ref) => {
  return props.to
    ? <Link ref={ref} {...props}>{children}</Link>
    : <a ref={ref} {...props}>{children}</a>
})
