import React, { useState } from 'react'

const ReactionToggle: React.FunctionComponent<{subtitle?: string, title: string, defaultChecked: boolean, isDisabled: boolean, onChange: React.ChangeEventHandler<HTMLInputElement>}> = ({ isDisabled, subtitle, title, ...props }) => {
  const [id] = useState(Math.random().toString(16).slice(-4))

  return (
    <div className='labeled-toggle disabled'>
      <input id={id} disabled={isDisabled} type='checkbox' className='switch' {...props} />
      <div>
        <label htmlFor={id} className='label small'>{title}</label>
        <label htmlFor={id} className='label small'>{subtitle}</label>
      </div>
    </div>
  )
}


export default ReactionToggle
