import React, { useEffect } from 'react';
import SVGInline from 'react-svg-inline';
import { useAnalytics } from 'use-analytics';
import { useNavigate } from 'react-router-dom';
import { sizlePlace, logo } from '@sizle-icons';

const Error404Page = () => {
  const { page } = useAnalytics();
  const navigate = useNavigate();
  const workspaceIndex = 'yourWorkspaceIndex'; // Replace this with the actual workspace index or a dynamic value

  useEffect(() => {
    page();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleBackOrDashboard = () => {
    if (window.history.length > 1) {
      navigate(-1); // Go back to the previous page
    } else {
      navigate(`/s/${workspaceIndex}/presentations`); // Fallback to the dashboard
    }
  };

  return (
    <div className='page-404'>
      <SVGInline className='sizle-logo' svg={logo} />
      <h2>Looks like nobody is home!</h2>
      <p>The page you're looking for doesn't exist.</p>
      
      <button className="btn secondary" onClick={handleBackOrDashboard}>
        Go back
      </button>
      
      <div className='hero-container'>
        <h4>404</h4>
        <SVGInline svg={sizlePlace} />
      </div>
    </div>
  );
};

export default Error404Page;
