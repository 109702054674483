import React, { useContext, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

import { workspaceContext } from 'src/context'
import { humanFileSize } from 'src/utils'
// TODO: Move this component to design-system
import styles from './StorageLimit.module.scss'

const StorageLimit = () => {
  const { workspace, currentWorkspacePlan } = useContext(workspaceContext)
  const allocatedFilesize = workspace?.presentations_aggregate?.aggregate?.sum?.filesize
  const allocatedFilesizeRatio = Math.min(1, allocatedFilesize / currentWorkspacePlan?.storageLimit)
  const visible = !!(workspace && currentWorkspacePlan.storageLimit)
  const navigate = useNavigate()
  const docLimit = currentWorkspacePlan?.documentLimit
  const curDocs = workspace?.fileCount
  const atLimit = curDocs >= docLimit ? 'true' : 'false'
  const almostAtLimit = curDocs === docLimit - 1 ? 'true' : 'false'
  const docLimitRatio = Math.min(curDocs / docLimit, 1)

  const fileLimitTooltip = curDocs === docLimit
    ? `Free includes ${docLimit} document${docLimit > 1 ? 's' : ''}, go Pro for unlimited`
    : `${curDocs}/${docLimit} Documents Available`

  const heading = docLimit && (curDocs === docLimit)
    ? 'Upload limit reached'
    : !docLimit || ((curDocs / docLimit) < allocatedFilesizeRatio)
      ? `${Math.floor((1 - allocatedFilesizeRatio) * 100)}% space remaining`
      : `${docLimit - curDocs} ${(docLimit - curDocs) > 1 ? '' : ''} uploads left`

  const goToBilling = useCallback(() => { navigate('/billing') }, [navigate])

  return (
    <div>
      {visible && (
        <div onClick={goToBilling} className={styles['filesize-limit']}>
          <span className={styles['storage-available']}>
            <div data-tooltip={`Your plan includes ${humanFileSize(currentWorkspacePlan?.storageLimit)} per workspace`}>
              <strong>{heading}</strong>
            </div>
            <div data-tooltip={`Free includes ${docLimit} documents, upgrade to Pro for unlimited`}>
              {docLimit && (
                <span className={styles['doc-limit']} atlimit={atLimit} almostatlimit={almostAtLimit}>({curDocs}/{docLimit})</span>
              )}
            </div>
          </span>
          <div className={styles['bar-container']}>
            <div data-tooltip={`${humanFileSize(allocatedFilesize)}/${humanFileSize(currentWorkspacePlan.storageLimit)}`}>
              <div style={
                {
                  width: `${allocatedFilesizeRatio * 100}%`,
                  height: '100%',
                  background: (allocatedFilesizeRatio >= 0.9 && '#FF7575') || (allocatedFilesizeRatio >= 0.7 && '#FFD857') || '#7FF498'
                }
              }
              />
            </div>
            {!!docLimit && (
              <div data-tooltip={fileLimitTooltip}>
                <div style={
                  {
                    width: `${docLimitRatio * 100}%`,
                    height: '100%',
                    background: (docLimitRatio >= 0.8 && '#FF7575') || (docLimitRatio >= 0.6 && '#FFD857') || '#7FF498'
                  }
                }
                />
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  )
}

export default StorageLimit
