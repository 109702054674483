import React from 'react'
import styled from '@emotion/styled'
import { PropTypes } from 'prop-types'

const ShadowedTextarea = ({ placeholder, error, onBlur, onChange, name, ...rest }) => {
  const handleBlur = () => {
    if (onBlur) onBlur()
  }

  const handleTextChange = (e) => {
    if (onChange) onChange(e.target.value, name)
  }

  return (
    <Textarea
      {...rest}
      placeholder={placeholder}
      name={name}
      onChange={handleTextChange}
      onBlur={handleBlur}
    />
  )
}

ShadowedTextarea.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  error: PropTypes.string
}

const Textarea = styled.textarea`
  height: 110px;
  padding: 10px 12px;
  border: none;
  border-radius: 8px;
  box-shadow: ${props => props.theme.inputs.boxShadow};
  background: ${props => props.theme.colors.lightGray};
  transition: 0.2s;
  opacity: ${props => props.disabled ? 0.4 : 1};
  cursor: ${props => props.disabled ? 'not-allowed' : 'text'};
  font-family: ${props => props.theme.typo.fontDefault};
  font-size: ${props => props.large ? '19px' : 'inherit'};
  resize: none;
  &::placeholder {
    color: ${props => props.theme.colors.labelDark};
  }
  &:focus {
    box-shadow: inset 0 5px 6px 0 rgba(0, 0, 0, 0.12);
  }
`

export default ShadowedTextarea
