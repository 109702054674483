import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { useNavigate } from 'react-router-dom';
import { parseISO, formatDistanceToNow, differenceInSeconds } from 'date-fns';
import { Box, Text, PseudoBox } from '@chakra-ui/core';
import { FiChevronRight, FiBell, FiX } from 'react-icons/fi';
import useSound from 'use-sound';
import { useMutation } from '@apollo/client';
import { withCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { getDateLocale } from 'src/locales/i18n';

import { NOTIFICATION_READ_UPDATE } from '../../../../graphql/notifications';

import useUserContext from 'src/context/UserContext';
import notificationSound from '../../../../design-system/sizle-notification.mp3';

const LatestActivity = ({ user }) => {
  const [toPlaySound, setPlaySound] = useState(true);
  const [hasRecentNotification, setHasRecentNotification] = useState(false);
  const [previousNotifications, setPreviousNotifications] = useState([]);
  const [isPaneVisible, setIsPaneVisible] = useState(true);
  const { notificationView } = useUserContext();
  const userContext = useUserContext();
  const notifications = userContext.notifications?.filter(el => el.details.type !== 'STATUS-CHANGED');

  const [play] = useSound(notificationSound);

  useEffect(() => {
    if ((notifications.length > 0 && previousNotifications.length === 0) ||
      (notifications.length > 0 && previousNotifications.length > 0 && previousNotifications[0] !== notifications[0])) {
      const latestNotification = notifications[0];
      const seconds = Math.abs(differenceInSeconds(parseISO(latestNotification.created_at), new Date()));
      setHasRecentNotification(seconds < 10);
      setPreviousNotifications(notifications);
    } else {
      setHasRecentNotification(false);
    }
  }, [notifications, previousNotifications]);

  useEffect(() => {
    if (hasRecentNotification) {
      if (toPlaySound) {
        play();
      }
      setPlaySound(true);
    }
  }, [hasRecentNotification, toPlaySound, play]);

  const togglePane = () => {
    setIsPaneVisible(!isPaneVisible);
  };

  return (
    <>
      <ToggleButton onClick={togglePane}>
        {isPaneVisible ? <FiX size={20} /> : <FiBell size={20} />}
      </ToggleButton>
      {isPaneVisible && (
        <Wrapper className={notificationView ? '' : 'no-notifications'}>
          <Box maxHeight="100%" position="relative" overflow="auto" overflowX="hidden">
            <HeaderBox>
              <Text>Notifications</Text>
            </HeaderBox>
            {notifications.length > 0 && notifications.map((notification, index) => (
              <NotificationItem key={`notification-${index}`} data={notification} user={user} setPlaySound={setPlaySound} />
            ))}
            {notifications.length === 0 && (
              <Text padding="10px" margin="5px 2px 5px 13px;">
                No new notifications. Alerts from documents will appear here.
              </Text>
            )}
            <NotificationFade />
          </Box>
        </Wrapper>
      )}
    </>
  );
};

const NotificationItem = withCookies(({ data, user, setPlaySound, cookies }) => {
  const navigate = useNavigate();
  const details = data.details;
  const [markAsRead, markAsReadResult] = useMutation(NOTIFICATION_READ_UPDATE);
  const [isCookiesSet, toggleCookiesSet] = useState(false);
  const { t } = useTranslation();

  const setCookiesForPresentationModal = () => {
    if (!isCookiesSet) {
      toggleCookiesSet(true);

      cookies.set('presentationSetup', 'true', {
        path: '/',
        domain: `${import.meta.env.VITE_COOKIE_STORAGE_DOMAIN}`,
        secure: import.meta.env.VITE_COOKIE_SECURED,
        sameSite: 'lax',
      });

      cookies.set('showingFileUploadOptions', 'false', {
        path: '/',
        domain: `${import.meta.env.VITE_COOKIE_STORAGE_DOMAIN}`,
        secure: import.meta.env.VITE_COOKIE_SECURED,
        sameSite: 'lax',
      });
    }
  };

  const onClickNotificationItem = () => {
    const hasError = !details.presentationId;

    if (!hasError) {
      setPlaySound(false);
    }

    if (!data.isRead) {
      markAsRead({
        variables: {
          userId: user.sub,
          notificationId: data.notificationId,
        },
      });
      setCookiesForPresentationModal();
    }

    if (!hasError) {
      if (details.type === 'STATUS-CHANGED' || details.type === 'RESPONSE-CREATED') {
        navigate(`/presentation/${details.presentationId}/analytics`);
      } else {
        const presentationUrl = `/edit/${details.presentationId}`;
        setTimeout(() => {
          window.open(presentationUrl, '_blank');
        }, 500);
      }
    }
  };

  const dateTimestamp = formatDistanceToNow(parseISO(data.created_at), {
    includeSeconds: true,
    locale: getDateLocale(),
  });

  return (
    <NotificationContainer
      as="button"
      display="flex"
      flexDirection="row"
      fontSize="13px"
      padding="12px 16px"
      borderBottom="1px"
      fontWeight="500"
      textAlign="left"
      backgroundColor="transparent"
      cursor="pointer"
      onClick={onClickNotificationItem}
      tabIndex="0"
      alignItems="center"
    >
      {/* Notification Content based on the type */}
    </NotificationContainer>
  );
});

const ToggleButton = styled.button`
  opacity: 0;
  position: absolute;
  top: 15px;
  right: 15px;
  background-color: var(--background-color);
  border: none;
  border-radius: 50%;
  padding: 8px;
  cursor: pointer;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);

  transition: background-color 0.3s ease, box-shadow 0.3s ease;

  &:hover {
    background-color: var(--background-highlight-color);
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.1), 0 3px 4px rgba(0, 0, 0, 0.08);
  }

  svg {
    color: var(--text-color);
    transition: color 0.3s ease;
  }

  @media (max-width: 768px) {
    display: none; /* Hide toggle button on mobile */
  }
`;

const NotificationContainer = styled(PseudoBox)`
  border-bottom: 1px solid var(--pane-border);
  &:hover {
    background-color: var(--background-highlight-color);
  }
`;

const HeaderBox = styled.div`
  position: relative;
  height: 56px;
  color: var(--text-color);
  font-size: 12px;
  border-bottom: 1px solid var(--pane-border);
  font-weight: 500;
  padding-left: 22px;
  padding-top: 16px;
  font-size: 15px;

  .SVGInline {
    display: inline-block;
    position: absolute;
    margin-left: 6px;

    > svg {
      width: 20px;
      height: 20px;
    }
  }
`;

const NotificationFade = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 0;
  box-shadow: 53px 0 40px 50px var(--analytics-shadow);
  transition: all 0.3s ease-in-out;
`;

const NotificationContent = styled.div`
  opacity: 0;
  width: 100%;
  clear: both;
  font-size: 12px;
  line-height: 16px;
  padding-bottom: 5px;
  position: relative;
  color: var(--text-color-lighter);

  .date-time {
    font-size: 12px;
    line-height: 15px;
    margin-top: 2px;
  }

  &.is-read {
    &::after {
      content: "✓";
      position: absolute;
      bottom: 6px;
      right: -10px;
      color: var(--primary-color);
      opacity: 0.8;
    }
  }
`;

const Wrapper = styled.div`
  opacity: 0;
  width: 220px;
  display: flex;
  flex-direction: row;
  min-height: 100%;
  box-sizing: content-box;
  background-color: var(--background-color);
  transition: all 0.3s ease-in-out;

  &.no-notifications {
    margin-right: -220px;
  }

  @media (max-width: 768px) {
    display: none; /* Hide notifications pane on mobile */
  }
`;

export default LatestActivity;
