import React, { useState } from 'react'
import ReactDOM from 'react-dom'
import styled from '@emotion/styled'
import BeatLoader from 'react-spinners/BeatLoader'
import { toastr } from 'react-redux-toastr'
import { Popover, PopoverTrigger, PopoverContent, PopoverBody, PopoverArrow } from '@chakra-ui/core'
import { AiOutlineClose } from 'react-icons/ai'

import ReactionsButtons from './ReactionsButtons'
import Button from './Button'
import { useReactions } from 'src/modules/reactions/hooks/useReactions.ts'
import { useWindowSize } from 'src/modules/controls/hooks/useWindowSize'

const ReactionsWidget = ({ leadId, isPreview }) => {
  const { addReaction } = useReactions(leadId)
  const [width] = useWindowSize()
  const [isPopoverOpen, setPopoverOpen] = useState(false)
  const [isLoading, setLoading] = useState(false)

  const handleClickReaction = async (reaction) => {
    if (!['thumbUp', 'thumbDown', 'approve', 'decline'].includes(reaction)) {
      throw new Error(`Invalid reaction ${reaction}`)
    }

    setLoading(true)

    try {
      if (isPreview) await new Promise(resolve => setTimeout(resolve, 1000))
      else await addReaction(reaction)
      toastr.info('Reaction sent')
    } catch (err) {
      console.error(err)
      toastr.error('Error sending reaction. Please try again.')
    } finally {
      setLoading(false)
    }
  }

  const handleClose = () => {
    setPopoverOpen(false)
  }

  if (width <= 768) {
    return (
      <Wrapper>
        <Popover placement='top' closeOnBlur={false} isOpen={isPopoverOpen} onClose={handleClose}>
          <PopoverTrigger>
            <TriggerContent>
              {isLoading
                ? (
                  <LoaderContainer>
                    <BeatLoader size={10} margin={3} color='var(--primary-color)' loading />
                  </LoaderContainer>
                )
                : (
                  <MobileButton
                    color='var(--reaction-thumbs)'
                    onClick={() => setPopoverOpen(!isPopoverOpen)}
                  >
                    Reaction
                  </MobileButton>
                )}
            </TriggerContent>
          </PopoverTrigger>
          <Content>
            <PopoverArrow />
            <CloseIcon size={10} color='var(--text-color-lighter)' onClick={handleClose} />
            <Body>
              <ReactionsButtons onClick={handleClickReaction} />
            </Body>
          </Content>
        </Popover>
      </Wrapper>
    )
  }

  return ReactDOM.createPortal(
    <Wrapper>
      {isLoading
        ? (
          <LoaderContainer>
            <BeatLoader
              size={10}
              margin={3}
              color='var(--primary-color)'
              loading
            />
          </LoaderContainer>
        )
        : (
          <ReactionsButtons onClick={handleClickReaction} />
        )}
    </Wrapper>, document.body
  )
}

const Wrapper = styled.div`
  position: fixed;
  bottom: 20px;
  left: 20px;
  display: flex;
  border-radius: 6px;

  @media only screen and (max-width: 768px) {
    right: 20px;
    left: unset;
  }
`

const LoaderContainer = styled.div`
  display: flex;
  align-items: center;
  height: 44px;
  width: 122px;
`

const TriggerContent = styled.div`
  display: flex;
`

const MobileButton = styled(Button)`
  margin-top: 0;
`

const Content = styled(PopoverContent)`
  background-color: var(--background-color) !important;
  box-shadow: var(--editor-shadow);
  border: 0;
  min-width: 300px;

  &:focus {
    box-shadow: var(--editor-shadow) !important;
  }
`

const CloseIcon = styled(AiOutlineClose)`
  position: absolute;
  top: 5px;
  right: 5px;
  cursor: pointer;
`

const Body = styled(PopoverBody)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  padding: 0 16px 12px 16px;
`

export default ReactionsWidget
