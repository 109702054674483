import googleAnalytics from '@analytics/google-analytics'
import Analytics from 'analytics'
import React from 'react'
import { CookiesProvider } from 'react-cookie'
import { render } from 'react-dom'
import { I18nextProvider } from 'react-i18next'
import { Provider } from 'react-redux'
import ReduxToastr from 'react-redux-toastr'
import { BrowserRouter } from 'react-router-dom'
import { AnalyticsProvider } from 'use-analytics'
import App from './App'

import configureStore from './store'
import { ModeProvider } from './context'
import i18n from './locales/i18n'

const store = configureStore()

/* Initialize analytics & load plugins */
const analytics = Analytics({
  app: 'sizle-dashboard',
  debug: process.env.NODE_ENV !== 'production',
  plugins: [
    googleAnalytics({
      trackingId: 'UA-141127736-2'
    })
  ]
})
// TODO replace redux-toastr with hooks
export default function bootstrap () {
  render(
    <React.StrictMode>
      <BrowserRouter>
        <AnalyticsProvider instance={analytics}>
          <ModeProvider>
            <I18nextProvider i18n={i18n}>
              <Provider store={store}>
                <CookiesProvider>
                  <App />
                </CookiesProvider>
                <ReduxToastr
                  timeOut={2500}
                  newestOnTop
                  preventDuplicates
                  position='bottom-right'
                  className='sizle-toast'
                  getState={(state) => state.toastr}
                  transitionIn='bounceIn'
                  transitionOut='bounceOut'
                  progressBar={false}
                  showCloseButton={false}
                  closeOnToastrClick
                />
              </Provider>
            </I18nextProvider>
          </ModeProvider>
        </AnalyticsProvider>
      </BrowserRouter>
    </React.StrictMode>,
    document.getElementById('root')
  )
}
