import React, { useCallback, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Use useNavigate instead of useHistory
import { workspaceContext } from 'src/context'; // Ensure this import path matches your project structure

export const ProPopup: React.FunctionComponent<{
  title?: React.ReactNode,
  subtitle?: React.ReactNode,
  requiredFeature?: string,
  active?: boolean
}> = ({
  active = false,
  title = 'Unlock feature',
  subtitle = 'Upgrade to Solo to start using this feature now',
  requiredFeature,
  children
}) => {
  const { currentWorkspacePlan, workspaceIndex } = useContext(workspaceContext);
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate(); // useNavigate for navigation

  const preventAccess = (requiredFeature && !currentWorkspacePlan?.[requiredFeature]) || active;

  const closePopup = useCallback(() => {
    setIsOpen(false);
  }, []);

  const goToBillingAndClose = useCallback(() => {
    setIsOpen(false);
    navigate(`/s/${workspaceIndex}/billing`); // Use navigate to change the path
  }, [navigate, workspaceIndex]);

  const openPopup = useCallback(() => {
    if (preventAccess) {
      setIsOpen(true);
    }
  }, [preventAccess]);

  return (
    <div className='propopup'>
      {children}
      {isOpen && (
        <div className='modal-container' onClick={closePopup}>
          <div onClick={(e) => e.stopPropagation()} className='modal'>
            <h1>{title}</h1>
            <h2>{subtitle}</h2>
            <div className='propopup-buttons'>
              {/* Call the goToBillingAndClose function when the Go to Billing button is clicked */}
              <button className='btn extra-big primary' onClick={goToBillingAndClose}>View plans</button>
              {/* Call the closePopup function when the No thanks button is clicked */}
              <button onClick={closePopup} className='btn secondary'>Not now</button>
            </div>
          </div>
        </div>
      )}
      {preventAccess && <button className='anchor' onClick={openPopup} />}
    </div>
  );
};
