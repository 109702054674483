import React from 'react'
import RCreatableSelect from 'react-select/creatable'
import { StylesConfig, Props } from 'react-select'

const getStyles = (): StylesConfig => ({
  singleValue: (provided) => ({
    ...provided,
    color: 'var(--text-color)'
  }),
  control: (provided) => ({
    ...provided,
    minHeight: '30px',
    minWidth: '100px',
    background: 'var(--input-background)',
    border: `1px solid var(--input-border-color)`,
    boxShadow: 'none',
    borderRadius: '8px',
    '&:hover': {
      borderColor: 'var(--input-border-color)',
      boxShadow: 'none'
    },
    '&:focus': {
      borderColor: 'var(--input-border-color)',
      boxShadow: 'none'
    }
  }),
  menuList: (provided) => ({
    ...provided,
    position: 'initial',
    backgroundColor: 'var(--input-background)',
    borderRadius: '2px'
  }),
  menu: (provided) => ({
    ...provided
  }),
  multiValue: (provided) => ({
    ...provided,
    background: 'var(--input-focus)',
    color: 'var(--text-color)'
  }),
  multiValueLabel: (provided) => ({
    ...provided,
    color: 'var(--text-color)'
  }),
  input: (provided) => ({
    ...provided,
    lineHeight: '12px',
    fontSize: '12px',
    minWidth: '10px',
    color: 'var(--text-color)'
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    padding: '4px',
    color: 'var(--text-color)',
    cursor: 'pointer',
    '&:hover': {
      svg: {
        fill: !state.isDisabled && 'var(--primary-color)'
      },
      opacity: 1
    },
    opacity: state.isDisabled ? 0 : 0.5
  }),
  clearIndicator: (provided) => ({
    ...provided,
    padding: '4px',
    cursor: 'pointer',
    '&:hover': {
      svg: {
        fill: 'var(--danger-color)'
      }
    }
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    backgroundColor: 'transparent'
  }),
  placeholder: (provided) => ({
    ...provided,
    color: '#AAAEB3'
  }),
  loadingIndicator: (provided) => ({
    ...provided,
    span: {
      backgroundColor: 'var(--primary-color)'
    }
  }),
  option: (provided) => {
    return {
      ...provided,
      backgroundColor: 'var(--input-background)',
      color: 'var(--text-color)',
      '&:hover': {
        backgroundColor: 'var(--input-background-hover)'
      }
    }
  }
})

export const CreatableSelect = ({ styles, ...rest }: Props) => {
  return (
    <RCreatableSelect {...rest} styles={{ ...getStyles(), ...styles }} />
  )
}
