import React, { useRef, useEffect, useState, useCallback } from 'react'
import { zoomIn, zoomOut } from 'src/design-system/icons'
import SVGInline from 'react-svg-inline'
import styled from '@emotion/styled'
import { useAuth } from 'oidc-react'
import { useNavigate, useParams } from 'react-router-dom'
import { min, addMinutes, differenceInDays, differenceInSeconds } from 'date-fns'
import { toastr } from 'react-redux-toastr'
import axios from 'axios'
import { Document, Page } from 'react-pdf/dist/esm/entry.vite'
import { PinScreen, EmailScreen, useLeadOpenEvents, usePresentationAnalytics } from 'src/modules/player'
import QuickPinchZoom from 'react-quick-pinch-zoom'
import ViewerHeader from './ViewerHeader'
import { sendAmplitudeData } from 'src/utils/amplitude'
import { useUserContext } from 'src/context'
import SizleLoader from 'src/modules/controls/components/SizleLoader'
import { ReactionsWidget } from 'src/modules/reactions'
import { useWindowSize } from 'src/modules/controls/hooks/useWindowSize'

function usePrevious(value: any) {
  const ref = useRef()
  useEffect(() => {
    ref.current = value
  }, [value])
  return ref.current
}

const ViewerPage = () => {
  const navigate = useNavigate()
  const { linkId } = useParams()
  const { user } = useUserContext()
  const { isLoading } = useAuth()
  const [currentLeadId, setCurrentLeadId] = useState<any>(null)
  const { createPublicLead, updateLeadOpenEvents } = useLeadOpenEvents()
  const { presentationStart, viewSlide, presentationEnded } = usePresentationAnalytics()
  const [_, height] = useWindowSize()

  const oldPresentationEnded = usePrevious(presentationEnded)
  const [showingPinScreen, setShowingPinScreen] = useState(false)
  const [showingEmailScreen, setShowingEmailScreen] = useState(false)
  const [loadingDocument, setLoadingDocument] = useState(false)
  const [failedPinCount, setFailedPinCount] = useState(0)
  const [documentLoaded, setDocumentLoaded] = useState(false)
  const [linkDetails, setLinkDetails] = useState<any>(null)
  const [page, setPage] = useState(1)
  const [numPages, setNumPages] = useState(null)
  const [docZoom, setDocZoom] = useState(1)
  const [_downloadEnabled, setDownloadEnabled] = useState(true)
  const [pin, setPin] = useState('')

  const fetchLinkDetails = async () => {
    // Get link details
    const { data: linkDetails } = await axios({
      url: `/api/links/${linkId}`
    })
    setLinkDetails(linkDetails)

    setShowingEmailScreen(linkDetails?.requiresEmailCapture && !user?.email)
    // Determine lead id
    let leadId = linkDetails?.emailLead?.leadUuId || linkDetails?.staticLead?.leadId
    if (!leadId && user?.email) leadId = await createPublicLead(linkId, user.email)
    if (!leadId && !linkDetails.requiresEmailCapture && !user?.email) leadId = await createPublicLead(linkId)
    if (leadId) setCurrentLeadId(leadId)
    // Burn timer and expiration date
    const { burnTimer, expirationDate } = linkDetails
    if (burnTimer || expirationDate) {
      const dates = []
      if (burnTimer) dates.push(addMinutes(linkDetails.earliestActivity ? new Date(linkDetails.earliestActivity) : new Date(), burnTimer))
      if (expirationDate) dates.push(new Date(expirationDate))
      const expireAt = min(dates)
      handleExpiry(expireAt)
    }
  }

  const handleExpiry = (expireAt: Date) => {
    const secondsRemaining = differenceInSeconds(expireAt, new Date())
    if (differenceInDays(expireAt, new Date()) < 20) {
      setTimeout(() => navigate(`/expired-link/${linkId}`, { replace: true }), secondsRemaining * 1000)
    } else {
      setTimeout(() => handleExpiry(expireAt), 1440000)
    }
  }

  // Wait for document loaded, link metadat retrieved and lead created, then post analytics
  useEffect(() => {
    if (currentLeadId && linkDetails && documentLoaded) {
      if (!linkDetails?.downloads_enabled) {
        setDownloadEnabled(false)
      }
      updateLeadOpenEvents({ leadId: currentLeadId })
      presentationStart({
        presentationId: linkDetails.presentation.presentationId,
        leadId: currentLeadId,
        linkId,
        workspaceId: linkDetails.presentation.workspace_id
      })
    }
  }, [currentLeadId, linkDetails, documentLoaded, linkId])

  useEffect(() => {
    console.log('should fetch link details', isLoading)
    if (!isLoading) {
      fetchLinkDetails().catch(e => console.error(e))
    }
  }, [isLoading])

  // Add document closed analytics
  oldPresentationEnded && window.removeEventListener('beforeunload', oldPresentationEnded)
  window.addEventListener('beforeunload', presentationEnded)

  // Try load document with newly entered pin
  const handlePinEntry = async (pin: string) => {
    setPin(pin)
  }

  const onDocumentLoadFailure = async ({ status }: {status: number}) => {
    if (status === 400) {
      toastr.error('Document may not be served from this custom domain.')
    } else if (status === 403 || status === 401) {
      setFailedPinCount(p => p + 1)
      setShowingPinScreen(true)
      setLoadingDocument(false)
      setDocumentLoaded(true)
    } else {
      toastr.error('Unable to load document, please contact support!')
      console.error('Failed to load document')
    }
  }

  function onDocumentLoadSuccess ({ numPages }: {numPages: number}) {
    setNumPages(numPages)
    sendAmplitudeData('VIEWING_DOCUMENT')
    setDocumentLoaded(true)
    setShowingPinScreen(false)
  }

  // Use viewer entered email to create public link
  const handleEmailEntry = async (email: string) => {
    const leadId = await createPublicLead(linkId, email)
    setCurrentLeadId(leadId)
    setShowingEmailScreen(false)
  }
  const handleZoom = useCallback((e) => {
    if (e.scale) {
      setDocZoom(zoom => zoom * ((e.scale + 10) / 11))
    }
    console.log(e)
  }, [setDocZoom])

  const handleSlideZoom = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setDocZoom(parseFloat(e.currentTarget.value))
  }, [setDocZoom])

  const handleArrowKeys = useCallback((e) => {
    if (e.key === 'ArrowRight') {
      setPage(p => Math.min((numPages || 99), p + 1))
    } else if (e.key === 'ArrowLeft') {
      setPage(p => Math.max(1, p - 1))
    }
    console.log(e)
  }, [numPages])

  useEffect(() => {
    console.log('adding event listener')
    window.addEventListener('keyup', handleArrowKeys)
    return () => {
      window.removeEventListener('keyup', handleArrowKeys)
    }
  }, [handleArrowKeys])

  useEffect(() => {
    viewSlide(page)
  }, [page])

  // console.log(height)

  return (
    <Wrapper onKeyUp={handleArrowKeys}>
      {!documentLoaded && <SizleLoader />}
      <ViewerWrapper hidden={!documentLoaded}>
        {linkDetails && <ViewerHeader linkId={linkId} linkDetails={linkDetails} currentLeadId={currentLeadId} />}

        <ViewerContainer>
          {document !== null && (
            <>
              <div className='viewer-doc'>
                <QuickPinchZoom onUpdate={handleZoom}>
                  <Document file={`/api/links/${linkId}/file${pin ? `?pin=${pin}` : ''}`} onLoadError={onDocumentLoadFailure} onLoadSuccess={onDocumentLoadSuccess}>
                    <Page height={height - 200} scale={docZoom} pageNumber={page} />
                  </Document>
                </QuickPinchZoom>
              </div>
              <div className='page-btns' hidden={isLoading || numPages === 1 ? 'hidden' : undefined}>
                <div className='btn-group center'>
                  <button className='btn' disabled={page === 1} onClick={() => setPage(page - 1)} title='Previous Page'>Prev</button>
                  <button className='btn' disabled={page >= numPages} onClick={() => setPage(page + 1)} title='Next Page'>Next</button>
                </div>
              </div>
              <div className='mag'>
                <button className='btn mag-small' onClick={() => handleZoom({ scale: 0.02 })} title='Zoom Out'>
                  <SVGInline svg={zoomOut} />
                </button>
                <button className='btn mag-big' onClick={() => handleZoom({ scale: 4 })} title='Zoom In'>
                  <SVGInline svg={zoomIn} />
                </button>
              </div>
            </>
          )}
        </ViewerContainer>
        {linkDetails?.reactions_enabled && documentLoaded && (
          <><ReactionsWidget leadId={currentLeadId} /></>
        )}
      </ViewerWrapper>
      {showingPinScreen && (
        <PinScreen checkPinInput={(p) => handlePinEntry(p)} incorrectPinAttempted={failedPinCount} loading={loadingDocument} />)}
      {showingEmailScreen && <EmailScreen linkDetails={linkDetails} onSubmit={handleEmailEntry} />}
      {/* <CookieBanner /> */}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  height: 100%;
  background: #10100f;

  @media print {
    display: none;
  }
  .viewer-doc {
    overflow: auto;
    height: calc(100vh);
    position: relative;
  }
  
  .page-btns {
    bottom: 20px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    flex-direction: row;
    justify-content: center;
    opacity: 1; 
    transition: opacity 0.3s ease;
    z-index: 10;
  }
  .page-btns .btn {
  background-color: #000;
  border: 2px solid white;
  color: white;
    border-radius: 8px;
    padding: 8px 16px;
    cursor: pointer;
    transition: background-color 0.2s ease;
    margin: 0 8px;
  }

  .page-btns .btn:hover {
    background-color: #4631e9;
  }

  /* Assuming this is your parent container */
  .parent-container {
    position: relative;
    /* Add other styles specific to your parent container here */
  }
  
  /* Styling for the .mag class */
  .mag {
    height: 30px;
    width: 50px;
    display: flex;
    position: absolute;
    bottom: 25px;
    right: 4%;
    align-items: center;
    justify-content: space-between;
    z-index: 10;
  
    /* Hide on mobile */
    @media (max-width: 1500px) {
      display: none;
    }
  }
  
  .mag .btn {
    background-color: transparent;
    color: white;
    border-radius: 15%;
    padding: 10px;
    cursor: pointer;
    border: none;
    transition: background-color 0.2s ease;
  }
  
  .mag .btn:hover {
    background-color: #4631e9;
  }

  .mag .mag-small svg {
    height: 30px;
  }
  
  .mag .mag-big svg {
    height: 30px;
  }

  /* Background shadow for active comment */
  .viewer-doc {
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.5);
  }
`

const ViewerWrapper = styled.div`
  height: calc(100% - 40px);
`

const ViewerContainer = styled.div`
  height: 100%;
`

export default ViewerPage
