import React, { useContext } from 'react'
import { format } from 'date-fns'
import { ModeContext, workspaceContext } from 'src/context'
import { useParams } from 'react-router-dom'

const MainFooter = ({ previewMode = false }) => {
  const { hideSizleBrand, mode } = useContext(ModeContext)
  const { workspace, theme } = useContext(workspaceContext)
  const { linkId } = useParams()
  const hideFooter = hideSizleBrand || (previewMode && theme?.has_banner && workspace?.workspace_id)

  return (
    <div className='main-footer'>
      {previewMode
        ? <img src={`/api/workspaces/${workspace?.workspace_id}/banner?mode=${mode}`} alt='Logo' />
        : <img src={`/api/links/${linkId}/banner?mode=${mode}`} alt='Logo' />}
        <br />
      {!hideFooter && <div className='copyright'>Secure Document Sharing & Collaboration | &copy;{format(new Date(), 'yyyy')} Sizle</div>}
    </div>
  )
}

export default MainFooter
