import React, { useCallback, useEffect } from 'react';
import styled from '@emotion/styled';
import { Helmet } from 'react-helmet';
import SVGInline from 'react-svg-inline';

import { sendAmplitudeData } from 'src/utils/amplitude';
import { comment, download } from 'src/design-system/icons';
import HelpButton from 'src/design-system/elements/buttons/HelpButton';
import { ResponseList } from 'src/modules/responses';
import { PoweredByLabel } from 'src/modules/player';

const svgIconStrokeColor = '#FFFFFF';

const setSvgStrokeColor = (svgString, color) => {
  const regex = /stroke="[^"]*"/g;
  return svgString.replace(regex, `stroke="${color}"`);
};

const ViewerHeader = ({
  linkDetails,
  currentLeadId,
  linkId,
  pin,
}) => {
  useEffect(() => {
    const hash = window.location.hash;
    if (hash) {
      window.location.hash = '';
      window.location.hash = hash;
    }
  }, []);

  const handleDownloadClick = useCallback(async () => {
    window.location = `/api/links/${linkId}/file?${pin ? `pin=${pin}` : ''}`;
  }, [pin, linkId]);

  const handleCommentClick = useCallback(() => {
    if (window.location.hash === '#comment-box') window.location.hash = '';
    else window.location.hash = '#comment-box';
  }, []);

  const handleResponseOverlayClick = () => {
    window.location.hash = '';
  };

  const coloredComment = setSvgStrokeColor(comment, svgIconStrokeColor);
  const coloredDownload = setSvgStrokeColor(download, svgIconStrokeColor);

  return (
    <div className='viewer-header'>
      <Helmet>
        {linkDetails?.presentation?.title
          ? <title>{linkDetails.presentation.title}</title>
          : <title>Loading document..</title>}
        <link rel='shortcut icon' href={`/api/links/${linkId}/favicon`} />
      </Helmet>
      <div className='left'>
        <PoweredByLabel linkId={linkId} />
      </div>
      <div className='center'>
        {linkDetails?.comments_enabled && (
          <button 
            className='btn tertiary' 
            onClick={handleCommentClick} 
            aria-label="Want to leave a comment?"
            role="button"
            aria-expanded={window.location.hash === '#comment-box'}
          >
            Leave comment
          </button>
        )}
        {linkDetails?.downloads_enabled && (
          <button 
            className='btn tertiary' 
            onClick={handleDownloadClick} 
            aria-label="Download document"
          >
            Download
          </button>
        )}
        <ResponseContainer id='comment-box'>
          <ResponseOverlay 
            onClick={handleResponseOverlayClick} 
            aria-hidden="true"
          />
          <ResponseList 
            linkDetails={linkDetails} 
            currentLeadId={currentLeadId} 
            tabIndex="-1" 
          />
        </ResponseContainer>
      </div>
      <div className='right'>
        <HelpButton aria-label="Help" />
      </div>
    </div>
  );
};

const ResponseContainer = styled.div`
  position: absolute;
  top: 46px;
  z-index: 10;
  opacity: 0;
  transition: opacity 0.2s;
  display: flex;
  pointer-events: none;

  &:target {
    opacity: 1;
    pointer-events: all;
  }
`;

const ResponseOverlay = styled.div`
  position: fixed;
  left: 0;
  top: 40px;
  z-index: 10;
  width: 100%;
  height: 100%;
  opacity: 0.6;
`;

export default ViewerHeader;
