import React, { useContext, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { workspaceContext, presentationContext, useUserContext } from './'

export const paramContext = React.createContext({})
export const ParamProvider = ({ children }: {children: React.ReactNode | React.ReactNode[]}) => {
  const { setWorkspaceIdentifier } = useContext(workspaceContext)
  const { setWorkspaceIndex: setUserWorkspaceIndex } = useUserContext()
  const { setPresentationId } = useContext(presentationContext)

  const { presentationId, workspaceIdentifier } = useParams()

  useEffect(() => {
    setWorkspaceIdentifier && setWorkspaceIdentifier(workspaceIdentifier)
    setUserWorkspaceIndex && setUserWorkspaceIndex(workspaceIdentifier)
    console.log(workspaceIdentifier)
    if (workspaceIdentifier !== undefined) {
      localStorage.setItem('sizle.activeWorkspace', workspaceIdentifier)
    }
  }, [workspaceIdentifier, setWorkspaceIdentifier, setUserWorkspaceIndex])
  useEffect(() => {
    setPresentationId && setPresentationId(presentationId)
  }, [presentationId, setPresentationId])

  return children
}
