import React, { useState, useContext, useEffect, useCallback, MouseEventHandler } from 'react'
import { ProPopup } from 'src/modules/modals/components/ProPopup'
import { useNavigate, useLocation } from 'react-router-dom'
import { useAnalytics } from 'use-analytics'
import { HiOutlineSelector } from 'react-icons/hi'
import { FiPlusSquare } from 'react-icons/fi'
import { subscriptionContext, workspaceContext } from 'src/context'
import { useModal } from 'src/modules/modals'
import { useResponsive } from 'react-hooks-responsive'

const getTier = w => w.owner.subscription.plan.planTier
const getTierClass = t => {
 switch (getTier(t)) {
    case 'Pro': return 'badge right primary'
    case 'Advanced': return 'badge right advanced'
    case 'Free': return 'badge right'
    case 'Solo': return 'badge right primary-dark'
    default: return 'badge right primary'
}
}

const WorkspaceDropdown = ({ toggleNav }) => {
  const navigate = useNavigate()
  const { hash } = useLocation()
  const { workspaces, workspaceIndex, workspace } = useContext(workspaceContext)
  const { currentPlan } = useContext(subscriptionContext)
  const [workspaceDropdownOpen, toggleWorkspaceDropdown] = useState(false)
  const { track } = useAnalytics()
  const { setModal } = useModal()
  const breakpoints = { mobile: 0, desktop: 855 }
  const { screenIsAtMost, screenIsAtLeast } = useResponsive(breakpoints)
  const isMobile = screenIsAtLeast('mobile') && screenIsAtMost('desktop')

  // limit free user can create more workspace
  const limitCreateMoreWorkspace = workspaces.filter(w => w.isAdmin).length >= (currentPlan?.workspaceCount || 0)

  const navigateWorkspace: (i: number) => MouseEventHandler<unknown> = useCallback((i: number) => (e) => {
    e.stopPropagation()
    toggleWorkspaceDropdown(false)
    if (workspaces[i].isOverfilled && !workspaces[i].isAdmin) {
      setModal('overfilled', { adminEmail: workspaces[i].workspace_members.find(u => u.member_type.value === 'admin').user.email })
    } else {
      navigate(`/s/${i}/presentations`)
      if (isMobile) toggleNav('close')
      track('switch-workspace', {
        from: workspaceIndex,
        to: i
      })
    }
  }, [isMobile, navigate, setModal, toggleNav, track, toggleWorkspaceDropdown, workspaceIndex, workspaces])

  const toggleDropdown = useCallback((val: boolean) => (e: React.MouseEvent<unknown>) => {
    e.stopPropagation()
    toggleWorkspaceDropdown(val)
  }, [toggleWorkspaceDropdown])

  useEffect(() => {
    if (hash === '#create-workspace') {
      // @TODO: Delay so that focus trapping modal has time to close.
      // Later versions of chakra allow you to disable focus trapping.
      setTimeout(() => toggleWorkspaceDropdown(true), 1)
    }
  }, [toggleWorkspaceDropdown, hash])

  const onClickCreateNewWorkspace = (e: React.MouseEvent<unknown>) => {
    e.stopPropagation()
    toggleWorkspaceDropdown(false)
    setModal('create_workspace')
    track('click-create-workspace')
  }

  return workspaces && workspaces.length > 0
    ? (
      <div className='workspace-dropdown' onClick={toggleDropdown(true)}>
        <img className='avatar-small' src={`/api/workspaces/${workspace?.workspace_id}/avatar?timestamp=${workspace?.updated_at}`} />
        <label>
          <span className='name'>{workspace?.name} </span>
          <span className='action'>Switch data room</span>
        </label>
        <HiOutlineSelector className='decoration' />
        {workspaceDropdownOpen && (
          <>
            <div className='cover' onClick={toggleDropdown(false)} />
            <div className='select-list'>
              {workspaces.map((w, i) => (
                <div className='menu-item' key={w.workspace_id} onClick={navigateWorkspace(i)}>
                  <img className='avatar-small' title={w?.name} src={`/api/workspaces/${w?.workspace_id}/avatar?timestamp=${w?.updated_at}`} />
                  <span className='name'>{w?.name}</span>
                </div>
              ))}
              <ProPopup title='Workspace limit reached' subtitle='Upgrade your subscription to unlock more data rooms' active={limitCreateMoreWorkspace}>
                <div className='menu-item create-workspace' onClick={onClickCreateNewWorkspace}>
                  <FiPlusSquare title='Create new workspace' />
                  <span className='name'>Create new</span>
                </div>
              </ProPopup>
            </div>
          </>
        )}
      </div>
    )
    : <></>
}

export default WorkspaceDropdown
