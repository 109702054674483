import React from 'react'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'

const Textarea = ({ value, onChange, ...props }) => {
  return (
    <Element
      value={value}
      onChange={onChange}
      {...props}
    />
  )
}

const Element = styled.textarea`
  resize: none;
  height: 85px;
  padding: 13px 20.4px 13px 22.6px;
  object-fit: contain;
  border-radius: 2.4px;
  box-shadow: 0 0 11px 0 rgba(131, 134, 163, 0.11);
  background-color: ${props => props.theme.colors.background};
  font-size: 12px;
  font-weight: 500;
  line-height: 1.38;
  letter-spacing: -0.1px;
  color: #4a4a4a;
`

Textarea.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func
}

export default Textarea
