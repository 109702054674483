import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { useModal } from 'src/modules/modals';
import {
  Modal,
  ModalContent,
  ModalOverlay,
  ModalCloseButton,
} from '@chakra-ui/core';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import { useAnalytics } from 'use-analytics';
import { toastr } from 'react-redux-toastr';
import { sendAmplitudeData } from 'src/utils/amplitude';
import { useTranslation } from 'react-i18next';

import { workspaceContext } from 'src/context';

const AvatarImage = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  
  .avatar {
    margin-right: 10px;
    width: 85%;
    height: auto;
  }
  
  input[type='file'] {
    display: none;
  }
  
  .secondary {
    background-color: #ddd;
    color: black;
  }
`;

function CreateWorkspaceModal({ isOpen, dismissModal }) {
  const [avatarDataURI, setAvatarDataURI] = useState(null);
  const { workspaces, createWorkspace } = useContext(workspaceContext);
  const [loading, setLoading] = useState(false);
  const { track } = useAnalytics();
  const { t } = useTranslation();
  const { setModal } = useModal();
  const navigate = useNavigate();

  const submitNewWorkspace = async (event) => {
    event.preventDefault();
    try {
      setLoading(true);
      await createWorkspace(event.target.name.value, event.target.avatar.files[0]);
      setLoading(false);
      toastr.success('Your workspace has been created successfully.');
      track('workspace-created');
      sendAmplitudeData('WORKSPACE_CREATED');
      setModal(null);
      setTimeout(() => {
        navigate(`/s/${workspaces.length}/presentations`);
      }, 10);
    } catch (e) {
      toastr.error('An error occurred while creating your workspace.');
      setLoading(false);
      track('workspace-create-error');
    }
  };

  const updatedAvatar = async (event) => {
    const fileReader = new FileReader();
    await new Promise((resolve, reject) => {
      fileReader.addEventListener('load', () => {
        setAvatarDataURI(fileReader.result);
        resolve();
      });
      fileReader.addEventListener('error', reject);
      fileReader.readAsDataURL(event.target.files[0]);
    });
  };

  return (
    <Modal
      size='md'
      isCentered
      closeOnOverlayClick={true}
      closeOnEsc
      isOpen={isOpen}
      onClose={dismissModal}
    >
      <ModalOverlay />
      <ModalContent rounded='lg' backgroundColor='var(--pane-background)' boxShadow='var(--modal-shadow)' style={{ padding: '1.25rem', textAlign: 'center' }}>
        <h3 className='title' style={{ fontWeight: 'bolder' }}>{t('createWorkspace.Create new workspace')}</h3>
        <h5>{t('Create a shared space and invite others.')}</h5>
        <div style={{ padding: '20px' }}>
          <form onSubmit={submitNewWorkspace}>
            <div className='form-control'>
              <input className='lg' type='text' name='name' id='workspaceName' placeholder='Enter workspace name here' />
            </div>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <AvatarImage>
                <img className='avatar' src={avatarDataURI} alt='New Workspace' />
                <div>
                  <input onChange={updatedAvatar} id='workspace-create-avatar' name='avatar' type='file' />
                  <label className='btn secondary' htmlFor='workspace-create-avatar'>Upload</label>
                </div>
              </AvatarImage>
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '5%' }}>
              <button className='btn primary lg' type='submit'>{t('Continue')}</button>
            </div>
          </form>
        </div>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <ModalCloseButton color='primary' />
        </div>
      </ModalContent>
    </Modal>
  );
}

CreateWorkspaceModal.propTypes = {
  isOpen: PropTypes.bool,
  dismissModal: PropTypes.func,
};

export default CreateWorkspaceModal;
