import 'vite/modulepreload-polyfill'
import bootstrap from './bootstrap'
import * as serviceWorker from './serviceWorker'
import {
  BrowserClient,
  Breadcrumbs,
  Dedupe,
  defaultStackParser,
  getCurrentHub,
  GlobalHandlers,
  makeFetchTransport,
  LinkedErrors,
} from "@sentry/browser";
import { CaptureConsole } from '@sentry/integrations'
import { initAmplitude } from './utils/amplitude'

const sentryEnabled = import.meta.env.VITE_SENTRY_ERROR_LOGGING
if (sentryEnabled === 'true') {
  const client = new BrowserClient({
    environment: import.meta.env.VITE_SENTRY_ENV,
    dsn: 'https://7afdd91c67c641eda1e409a7f254bb7c@sentry.io/1818019',
    tracesSampleRate: 0.1,
    transport: makeFetchTransport,
    stackParser: defaultStackParser,
    integrations: [
      new Breadcrumbs(),
      new GlobalHandlers(),
      new LinkedErrors(),
      new Dedupe(),
      new CaptureConsole({
        levels: ['error']
      })
    ]
  })
  getCurrentHub().bindClient(client);
}

initAmplitude()
bootstrap()

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.register()
serviceWorker.unregister()
