import React, { useEffect } from 'react'
import styled from '@emotion/styled'
import { useAnalytics } from 'use-analytics'

import MainFooter from 'src/modules/navigation/MainFooter'

const ExpiredLinkPage = () => {
  const { page } = useAnalytics()
  useEffect(() => { page() }, [page])

  return (
    <div className='page-container centered'>
      <Message>This file has expired or been removed</Message>
      <HomeLink href={`${import.meta.env.VITE_MAIN_URL}`}>Learn more</HomeLink>
      <MainFooter />
    </div>
  )
}

const Message = styled.div`
  margin-bottom: 16px;
  font-size: 20px;
  font-weight: 500;
  color: var(--primary-color);
`

const HomeLink = styled.a`
  font-size: 20px;
  font-weight: 600;
  color: #a9a9a9;
`

export default ExpiredLinkPage
