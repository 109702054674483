import React, { useState, useRef, useEffect, useContext, useCallback } from 'react'
import { sendAmplitudeData } from '../../../src/utils/amplitude'
import { toastr } from 'react-redux-toastr'
import { useNavigate, useLocation } from 'react-router-dom'
import { workspaceContext, subscriptionContext } from '../../../src/context'
import { BaseInput } from '../../../src/design-system/elements'
import { useTranslation } from 'react-i18next'
import styled from '@emotion/styled'

/**
 * Workspace Settings Input, allows a user to enter an email address and send an invite for it
 *
 * Has five basic "modes", in order that they can be seen below they are:
 * * Collapsed
 * * Open, cannot send due to no access, but user could start a trial
 * * Open, cannot send due to no access, and user cannot start a trial
 * * Open, cannot send due to seat limit, but they could add more seats
 * * Open, can send invite
 */
export const InviteWorkspaceInput = ({ onAddSeats }) => {
  const [emailInput, setEmailInput] = useState('')
  const [expanded, toggledExpanded] = useState(false)
  const [highlight, setHighlight] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const [error, setError] = useState(null)
  const { workspace, currentWorkspacePlan, inviteUser } = useContext(workspaceContext)
  const { t } = useTranslation()
  const { beginProTrial, userEligibleForProTrial } = useContext(subscriptionContext)
  const navigate = useNavigate()
  const { hash } = useLocation()
  const ref = useRef(null)

  /**
   * Activate focus immediately after workspace create
   *
   * Will add a shadow, gray out the rest of the screen, and scroll the input into view
   */
  useEffect(() => {
    console.log(ref)
    if (ref.current) {
      ref.current.focus()
      if (hash === '#invite-user') {
        toggledExpanded(true)
        setHighlight(true)
        ref.current.scrollIntoView()
      }
    }
  }, [hash])
  /**
   * Close the highlight
   *
   * When navigating from a workspace create modal, a highlight is shown,
   * clicking anywhere will trigger this and close it
   */
  const stopHighlight = () => {
    setHighlight(false)
    navigate('#')
  }
  /**
   * Invite a user
   *
   * Will add a loading spinner to the element, invite a user, disable the loading
   * spinner, and then depending on success status:
   *
   * If success:
   * * Attempt to extend the trial
   * * Send an amplitud event
   * * Close modal
   * * Clear input
   * * Show toast of success
   *
   * If fail:
   * * Show toast of fail
   */
  const submitInviteUser = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    // another check in case user copypasted an email
    try {
      if (error) setError(null)
      setSubmitting(true)
      await inviteUser(emailInput)
      setEmailInput('')
      toggledExpanded(false)
      setSubmitting(false)
      toastr.success(t('toastr.Your workspace invite has been sent successfully'))
      sendAmplitudeData('WORKSPACE_INVITE_SENT')
    } catch (e) {
      toastr.error('Unable to invite user to workspace. Please try again later')
      throw e
    }
  }
  /**
   * Sign up for a trial
   *
   * Will start the loading spinner and begin a pro-trial, then kill the spinner
   */
  const submitProTrial = async () => {
    setSubmitting(true)
    await beginProTrial()
    setSubmitting(false)
  }
  /**
   * Open form
   *
   * Handler for when the input is minimized, will attempt to focus the input when opening the form
   */
  const openForm = () => {
    toggledExpanded(true)
    if (ref.current) {
      ref.current.focus()
    }
  }
  /**
   * Close the input
   *
   * Will just hide the input, used by 'Cancel' button
   */
  const cancelForm = () => {
    setEmailInput('')
    setError(null)
    toggledExpanded(false)
  }
  /**
   * Go to the billing page
   *
   * Used when the user is free and can't start a trial
   */
  const goToBilling = useCallback((e: React.MouseEvent) => {
    e.stopPropagation()
    navigate('../billing')
  }, [navigate])
  /**
   * Onchange for input
   *
   * Just tracks the contents in a hook
   */
  const updateEmailInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmailInput(e.target.value)

    if (workspace.workspace_members.find((member) => member.user.email === e.target.value)) {
      setError('This user is already a workspace member')
      ref.current.setCustomValidity('invalid')
    } else if (workspace.invites.find((invite) => invite.has_accepted === null && invite.email_address === e.target.value)) {
      setError('This user is already invited')
      ref.current.setCustomValidity('invalid')
    } else if (error) {
      setError(null)
      ref.current.setCustomValidity('')
    }
  }

  return (
    <form onSubmit={submitInviteUser}>
      <InputWrapper onClick={stopHighlight} className={`${expanded ? 'expanded' : ''} ${highlight ? 'highlight' : ''}`}>
        <span onClick={cancelForm} className='highlight-backdrop' />
        <Content className='expanding'>
          {!currentWorkspacePlan?.allowInvites && userEligibleForProTrial && <span>Your plan includes <strong>1 seat</strong> for this workspace, activate a free <strong>Pro Trial</strong> now to invite more users.</span>}
          {!currentWorkspacePlan?.allowInvites && !userEligibleForProTrial && <span>Your plan includes <strong>1 seat</strong> for this workspace, purchase a <strong>Pro plan</strong> to invite more users.</span>}
          {currentWorkspacePlan?.allowInvites && workspace?.isFilled && <span>Your workspace has a limit of <strong>{workspace.availableSeats} seat{workspace.availableSeats !== 1 ? 's' : ''}</strong>, please purchase additional seats to invite more users.</span>}
          {currentWorkspacePlan?.allowInvites && !workspace?.isFilled && <BaseInput ref={ref} required name='recipient-email' type='email' value={emailInput} onChange={updateEmailInput} />}
          {error && <Error>{error}</Error>}
        </Content>
        <div className='btn-group compact'>
          {!expanded && (
            <><button className='btn primary' onClick={openForm}>Invite user</button></>
          )}
          {expanded && !currentWorkspacePlan?.allowInvites && userEligibleForProTrial && (
            <>
              <button className='btn primary' type='button' onClick={submitProTrial} loading={submitting ? 'loading' : undefined}>Begin pro trial</button>
              <button className='btn' onClick={cancelForm}>Cancel</button>
            </>
          )}
          {expanded && !currentWorkspacePlan?.allowInvites && !userEligibleForProTrial && (
            <>
              <button className='btn primary' type='button' onClick={goToBilling}>Go to billing</button>
              <button className='btn' onClick={cancelForm}>Cancel</button>
            </>
          )}
          {expanded && currentWorkspacePlan?.allowInvites && workspace.isFilled && (
            <>
              <button className='btn primary' type='button' onClick={onAddSeats}>Add seats</button>
              <button className='cancel-btn' onClick={cancelForm}>Cancel</button>
            </>
          )}
          {expanded && currentWorkspacePlan?.allowInvites && !workspace?.isFilled && (
            <>
              <button type='submit' className='btn primary' loading={submitting ? 'loading' : undefined}>Invite user</button>
              <button className='btn' onClick={cancelForm}>Cancel</button>
            </>
          )}
        </div>
      </InputWrapper>
    </form>
  )
}

const InputWrapper = styled.div`
  color: var(--text-color);
  input {
    border-color: var(--input-border-color) !important;
    background-color: var(--input-background);
    color: var(--input-color);

    &:focus {
      border-color: var(--input-focus);
    }
  }

  .cancel-btn {
    background-color: var(--background-color);
    border-color: var(--primary-color);
    color: var(--background-inverted);
  }

  display: flex;
  >.expanding {
    overflow: hidden;
    transition: max-width 0.2s ease-out, max-height 0.3s ease-out;
  }
  &.highlight {
    box-shadow: rgba(var(--primary-color), 0.4) 0px 0px 10px 10px;
    background: rgba(var(--primary-color), 0.4);
    .highlight-backdrop {
      background: #0002;
    }
  }
  &:not(.expanded) > .highlight-backdrop {
    display: none;
  }
  &.expanded >* {
    z-index: 999999;
    &.highlight-backdrop {
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      position: fixed;
    }
  }
  @media screen and (max-width: 699px) {
    align-items: stretch;
    flex-direction: column;
    >div:first-of-type { margin-bottom: 3px; }
    >div:last-of-type { margin-top: 3px; }
    >.expanding { max-height: 0px; height: 0px; }
    &.expanded>.expanding { max-height: 50px; height: 54px; }
  }
  @media screen and (min-width: 700px) {
    width: min-content;
    &.expanded >div:first-of-type { margin-right: 3px; }
    &.expanded >div:last-of-type { margin-left: 3px; }
    >.expanding { max-width: 0px; }
    &.expanded>.expanding { max-width: 300px; }
  }
`

const Content = styled.div`
  position: relative;
  @media screen and (min-width: 699px) {
    width: 100%;
  }
  @media screen and (min-width: 700px) {
    width: 300px;
    max-height: 32px;
    height: 32px;
  }
  >input {
    width: 100%;
  }
`

const Error = styled.div`
  color: red;
`
