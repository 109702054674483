import React from 'react';

const HelpButton = () => {
  const handleClick = () => {
    window.open('https://sizle.io/', '_blank', 'noopener,noreferrer');
  };

  return (
    <button 
      className='btn secondary'
      onClick={handleClick}
    >
      Support
    </button>
  );
};

export default HelpButton;
