import React from 'react'
import styled from '@emotion/styled'
import { ResponsiveContainer, BarChart, Bar, Tooltip, XAxis, YAxis, CartesianGrid } from 'recharts'

const formatSeconds = (seconds) => {
  const h = Math.floor(seconds / 60 / 60)
  const m = Math.floor(seconds / 60 % 60)
  const s = Math.floor(seconds % 60)
  return `${(h && `${h}h`) || ''}${(m && ` ${m}m`) || ''}${(s && ` ${s}s`)}`
}

const getFormatter = (dataKey) => {
  switch (dataKey) {
    case 'time': return formatSeconds
  }
}

const Chart = (props) => {
  const { data, chartProps, xDataKey, yDataKey, presentation, onHover } = props
  return (
    <ResponsiveContainer width='99%' height={250}>
      <BarChart
        data={data}
        {...chartProps}
        margin={{
          top: 30,
          left: 5,
          bottom: 10
        }}
      >
        <Tooltip
          allowEscapeViewBox={{ x: true, y: false }}
          content={(e) => {
            if (e.label !== undefined) {
              onHover && onHover(xDataKey, e.label, e.active)
              if (presentation?.pagesPreviews?.length) {
                return (
                  <ThumbnailContainer>
                    <Image alt='Slide Thumbnail' src={`/api/documents/thumbnail/${presentation.presentationId}/${e.label - 1}?timestamp=${presentation.updated_at}`} width='160px' />
                  </ThumbnailContainer>
                )
              }
            }
            return <div />
          }}
        />
        <CartesianGrid vertical={false} strokeDasharray='5' stroke='#DFE2E6' />
        <XAxis tickFormatter={getFormatter(yDataKey)} dataKey={yDataKey} label={{ value: 'Page', offset: -2, position: 'insideBottom' }} domain={[0, (data?.length - 1)]} />
        <YAxis tickFormatter={getFormatter(xDataKey)} dataKey={xDataKey} domain={[0, 'auto']} type='number' />

        <defs>
          <linearGradient
            id='lg1'
            x1='0'
            y1='0'
            x2='0'
            y2='100%'
            gradientUnits='userSpaceOnUse'
          >
            <stop offset='0' stopColor='var(--border-color)' />
            <stop offset='1' stopColor='var(--primary-color)' />
          </linearGradient>
        </defs>

        <Bar
          dataKey={xDataKey}
          maxBarSize={50}
          isAnimationActive={false}
          fill='url(#lg1)'
        />
      </BarChart>
    </ResponsiveContainer>
  )
}

const ThumbnailContainer = styled.div`
  box-shadow: 0 6px 10px 0 var(--analytics-shadow);
  border: solid 1px var(--border-alt);
  border-radius: 8px;
  transform: scale(1.3);
  position: relative;
  right: -40px;
`

const Image = styled.img`
  border: 0;
  border-radius: 4px;
  min-width: 100%;
  min-height: 100%;
`

export default Chart
