import React from 'react'
import { InputGroup, Input, InputRightElement } from '@chakra-ui/core'
import { useTranslation } from 'react-i18next'

const Filter = ({ filterText, onFilter, onClear, placeholder }) => {
  const { t } = useTranslation()

  return (
    <InputGroup size='md' mb='1rem'>
      <Input
        id='search'
        pr='80px'
        width='420px'
        focusBorderColor='primary'
        type='text'
        placeholder={placeholder}
        value={filterText}
        onChange={(e) => onFilter(e.target.value)}
      />
      <InputRightElement width='60px' mr='0.25rem'>
        <button className='btn primary' disabled={!(filterText && filterText !== '') ? 'disabled' : undefined} onClick={onClear}>{t('Clear')}</button>
      </InputRightElement>
    </InputGroup>
  )
}

export default Filter
