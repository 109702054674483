import { useContext } from 'react'
import axios from 'axios'

import { locationContext } from 'src/context'

const useLeadOpenEvents = () => {
  const { getLocation } = useContext(locationContext)

  const updateLeadOpenEvents = async ({ leadId }) => {
    const ipAdress = localStorage.getItem('isUserAgreedOnCookies') === 'true'
          ? await axios('https://api.ipify.org?format=json').then(res => res.json()?.ip).catch(() => null)
          : null
    await axios(`/api/leads/${leadId}/analytics`, {
      method: 'POST',
      headers: { 'content-type': 'application/json' },
      data: JSON.stringify({
        metadata: {
          browser: {
            userAgent: navigator.userAgent,
            platform: navigator.platform,
            language: navigator.language
          },
          location: await getLocation(),
          ipAdress
        }
      })
    })
  }

  const updateLeadDownloadedTimestamp = async (leadId) => {
    await axios(`/api/leads/${leadId}/timestamp`, {
      method: 'POST'
    })
  }

  const createPublicLead = async (linkId, email) => {
    const result = await axios(`/api/links/${linkId}/leads`, {
      method: 'POST',
      headers: { 'content-type': 'application/json' },
      data: JSON.stringify({ email })
    })
    return result.data.leadId
  }

  return { updateLeadOpenEvents, updateLeadDownloadedTimestamp, createPublicLead }
}

export default useLeadOpenEvents
