import React, { useState, useCallback, useEffect, useContext, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import DataTable from 'react-data-table-component'
import { useMutation } from '@apollo/client'
import SizleLoader from 'src/modules/controls/components/SizleLoader'
import { format, parseISO } from 'date-fns'
import { FiPlus, FiEdit, FiEdit2 } from 'react-icons/fi'
import { useAnalytics } from 'use-analytics'
import { useTranslation } from 'react-i18next'
import { toastr } from 'react-redux-toastr'

import { sendAmplitudeData } from 'src/utils/amplitude'
import { getDateLocale } from 'src/locales/i18n'
import { organisationContext, useUserContext, workspaceContext } from 'src/context'
import { RecipientsEditModal } from 'src/modules/recipients'
import { GET_ORGANISATION_QUERY } from 'src/graphql/organisations'
import { DELETE_RECIPIENTS } from 'src/graphql/recipients'

const CompanyPage = () => {
   const { id } = useParams()
  const { hasWorkspacePermission } = useContext(workspaceContext)
  const { user, currentWorkspace } = useUserContext()
  const [isAddingRecipient, setAddingRecipient] = useState(false)
  const [showingRecipientEditingModal, toggleRecipientModal] = useState(false)
  const [selectedRecipient, setSelectedRecipient] = useState(null)
  const [selectedRows, setSelectedRows] = useState([])
  const [toggleCleared, setToggleCleared] = useState(false)
  const [isPopoverOpen, setPopoverOpen] = React.useState(false)
  const { page } = useAnalytics()
  const { t } = useTranslation()
  const { loading: isLoading, recipients, organisations, updateOrganisationName, fetchOrganisations } = useContext(organisationContext)
  const currentCompany = organisations.find(o => o?.orgId === id)
  const [orgRecipients, setOrgRecipients] = useState(recipients.filter(r => r.orgId === currentCompany.orgId))
  const [updatedCompanyName, setCompanyName] = useState(currentCompany?.name)
  
  useEffect(() => {
    setOrgRecipients(recipients.filter(r => r.orgId === currentCompany.orgId))
  }, [currentCompany, recipients])

  const [deleteRecipients] = useMutation(DELETE_RECIPIENTS, {
    refetchQueries: [{
      query: GET_ORGANISATION_QUERY, variables: { workspaceId: currentWorkspace, id }
    }]
  })

  const columns = [
    {
      name: t('Name'),
      selector: 'name',
      sortable: true
    },
    {
      name: t('Contact'),
      center: true,
      sortable: true,
      cell: (item) => {
        return item.contact ? item.contact : '-'
      }
    },
    {
      name: t('Email'),
      center: true,
      sortable: true,
      cell: (item) => {
        return item.email ? item.email : '-'
      }
    },
    {
      name: t('Last Updated'),
      selector: 'updated_at',
      sortable: true,
      width: '180px',
      cell: (item) => {
        return format(parseISO(item.updated_at), 'h:mma do MMM yyyy', { locale: getDateLocale() })
      }
    },
    {
      name: '',
      button: true,
      width: '50px',
      cell: (item) => (
        <button hidden={!hasWorkspacePermission('Manage Contacts')} onClick={() => onEdit(item)}>
          <FiEdit2 />
        </button>
      )
    }
  ]

  const onEdit = useCallback((item) => {
    setSelectedRecipient(item)
    setAddingRecipient(false)
    toggleRecipientModal(true)
  }, [])

  const handleSaveCompanyName = async () => {
    try {
      await updateOrganisationName(currentCompany.orgId, updatedCompanyName)
      toastr.info(t('Company details updated'))
      setPopoverOpen(false)
    } catch (e) {
      console.error('Failed to update company details', e)
      toastr.error(t('There was an issue updating the company\'s details, please contact support'))
    }
  }

  const onClickAddNewRecipient = useCallback(() => {
    setAddingRecipient(true)
    toggleRecipientModal(true)
  }, [])

  const handleRowSelected = useCallback(state => {
    setSelectedRows(state.selectedRows)
  }, [])

  const contextActions = useMemo(() => {
    const handleDelete = async () => {
      const recipientsList = selectedRows.map(r => r.name)
      const recipientPluralizedText = recipientsList.length > 1 ? 'these recipients' : 'this recipient'
      if (window.confirm(t('Are you sure you want to remove', { text: `${recipientPluralizedText}:\r ${recipientsList.join(', ')}` }))) {
        setToggleCleared(!toggleCleared)
        const selectedIds = selectedRecipient.map(r => r.id)
        try {
          const { error } = await deleteRecipients({
            variables: {
              recipientIds: selectedIds
            }
          })
          if (error) {
            throw error
          }
          await fetchOrganisations()
        } catch (e) {
          console.error('Failed to remove recipient', e)
          toastr.error(t('There was an issue removing recipients, please contact support'))
        }
        toastr.info(t('Recipients removed'))
      }
    }
    return <button className='danger' onClick={() => { handleDelete() }}>{t('Delete')}</button>
  }, [deleteRecipients, selectedRows, toggleCleared])

  useEffect(() => {
    page()
    sendAmplitudeData('PAGE_VIEW_COMPANY')
  }, [])

  if (isLoading) { return <SizleLoader /> }
  return (
    <div className='page-section'>
      <div>
        <div>
          <div className='flex'>
            <input type='text' className='input-header' defaultValue={currentCompany?.name} onChange={e => setCompanyName(e.target.value)} />
            <button title='Save Name' onClick={handleSaveCompanyName} className='btn'><FiEdit/></button>
          </div>
          <p>{t('company.Number of active recipients')}: {orgRecipients.length}</p>
        </div>

        <DataTable
          keyField='id'
          columns={columns}
          data={orgRecipients}
          defaultSortFieldId={4} // 'updated_at'
          defaultSortAsc={false}
          selectableRows={hasWorkspacePermission('Manage Contacts')}
          selectableRowsHighlight
          onSelectedRowsChange={handleRowSelected}
          clearSelectedRows={toggleCleared}
          persistTableHead
          noDataComponent={<p className='intro'>{t('company.There is no active recipient for this company')}</p>}
        />

        { showingRecipientEditingModal && (selectedRecipient !== undefined || isAddingRecipient) &&
          <div className='popup-container fullscreen' >
            <div className='popup modal'>
              <div className='popup-content'>
                <button className='popup-close'/>
                <RecipientsEditModal
                  selectedRecipient={selectedRecipient}
                  user={user}
                  isAddingRecipient={isAddingRecipient}
                  hideCompanySelector
                  selectedOrg={currentCompany}
                  dismissModal={() => toggleRecipientModal(false)}
                />
              </div>
            </div>
          </div>
        }
      </div>
      <center className='p1'>
        <button className='btn primary' hidden={!hasWorkspacePermission('Manage Contacts')} onClick={() => onClickAddNewRecipient()}>
          <FiPlus />
          {t('company.Add new recipient to')} {currentCompany?.name}
        </button>
      </center>
    </div>
         )
}

export default CompanyPage
