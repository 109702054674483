import React, { useEffect, useState, useMemo, useCallback } from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import PresentationItem from './PresentationItem';
import { useTranslation } from 'react-i18next';
import useUserContext from 'src/context/UserContext';

const CustomTable = ({ presentations = [] }) => {
  const { t } = useTranslation();
  const { searchPresentations, setSearchPresentations } = useUserContext();
  const [sortOrder, setSortOrder] = useState('title');

  // Memoized function for filtering and sorting presentations
  const filteredPresentations = useMemo(() => {
    return presentations
      .filter(item =>
        !searchPresentations ||
        item.title.toLowerCase().includes(searchPresentations.toLowerCase())
      )
      .sort((a, b) => {
        switch (sortOrder) {
          case 'title':
            return a.title.localeCompare(b.title);
          case 'created_at':
            return new Date(b.created_at) - new Date(a.created_at);
          case 'updated_at':
            return new Date(b.updated_at) - new Date(a.updated_at);
          default:
            return 0;
        }
      });
  }, [presentations, searchPresentations, sortOrder]);

  // Callback functions to avoid unnecessary re-renders
  const handlePresentationSearch = useCallback((event) => {
    setSearchPresentations(event.target.value);
  }, [setSearchPresentations]);

  const handleClearSearch = useCallback(() => {
    setSearchPresentations('');
  }, [setSearchPresentations]);

  const handleSortOrderChange = useCallback((event) => {
    setSortOrder(event.target.value);
  }, []);

  if (presentations.length === 0) {
    return (
      <CenterMessage>
        <h2 className="title">Upload a file to get started</h2>
      </CenterMessage>
    );
  }

  return (
    <div className="custom-table">
      <TableControls>
        <LeftControls>
          <SearchContainer>
            <FormInput
              type="text"
              value={searchPresentations}
              placeholder={t('inputs.Filter by title')}
              onChange={handlePresentationSearch}
              aria-label="Search presentations by title"
            />
            {searchPresentations && (
              <ClearButton onClick={handleClearSearch} aria-label="Clear search">
                X
              </ClearButton>
            )}
          </SearchContainer>
          <SortDropdown
            value={sortOrder}
            onChange={handleSortOrderChange}
            aria-label="Sort presentations"
          >
            <option value="title">Sort by Title</option>
            <option value="created_at">Sort by Date Created</option>
            <option value="updated_at">Sort by Last Edited</option>
          </SortDropdown>
        </LeftControls>
      </TableControls>
      <Table>
        {filteredPresentations.map((presentation) => (
          <PresentationItem
            key={presentation.presentationId}
            presentation={presentation}
            published={presentation.published}
            originalFileUrl={presentation.originalFileUrl}
            originalFileType={presentation.originalFileType}
            owner={presentation.owner}
            createAt={presentation.created_at}
            lastEdited={presentation.updated_at || presentation.created_at}
            presentationId={presentation.presentationId}
            formEnabled={presentation.formEnabled}
            emailNotificationsEnabled={presentation.emailNotificationsEnabled}
            openTrackingEnabled={presentation.openTrackingEnabled}
            thumbnail={`/api/documents/thumbnail/${presentation.presentationId}/0?timestamp=${presentation.updated_at}`}
            url={presentation.url}
            title={presentation.title}
          />
        ))}
      </Table>
    </div>
  );
};

CustomTable.propTypes = {
  presentations: PropTypes.array.isRequired,
};

const TableControls = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

const LeftControls = styled.div`
  display: flex;
  align-items: center;
`;

const SearchContainer = styled.div`
  display: flex;
  align-items: center;
  min-width: 60%;
  background-color: var(--input-background-color);
  border-radius: 14px;
  padding: 5px;
`;

const ClearButton = styled.div`
  margin-left: 10px;
  padding: 4px 10px;
  background-color: var(--primary-color);
  border-radius: 6px;
  color: #fff;
  font-weight: 500;
  cursor: pointer;
  font-size: 14px;
`;

const FormInput = styled.input`
  width: 100%;
  max-width: 300px;
  border-radius: 6px;
  border: solid 1px var(--input-border-color);
  background-color: var(--input-background-color);
  padding: 8px;
  font-size: 14px;
  transition: background-color 0.3s ease-in-out, border-color 0.3s ease-in-out;

  &:focus {
    background-color: var(--input-focus);
    border-color: var(--input-focus-border);
    outline: none;
  }
`;

const SortDropdown = styled.select`
  display: flex;
  align-items: center;
  min-width: 150px;
  border-radius: 18px;
  padding: 8px 10px;
  margin-left: 20px;
  color: var(--text-color);
  font-size: 14px;
  cursor: pointer;
  border: 1.5px solid var(--border-color);
  background-color: var(--input-background-color);

  &:hover {
    background-color: var(--input-focus);
  }
`;

const Table = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  grid-gap: 16px;
  margin-top: 15px;
`;

const CenterMessage = styled.div`
  font-size: 28px;
  font-weight: 700;
  color: var(--text-color);
  text-align: center;
  padding: 60px 0;

  @media screen and (max-width: 390px) {
    font-size: 24px;
  }
`;

export default CustomTable;
