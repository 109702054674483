import React, { useEffect, useContext } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { toastr } from 'react-redux-toastr'

import { useUserContext, workspaceContext } from 'src/context'
import SizleLoader from 'src/modules/controls/components/SizleLoader'

const InvitationLinkPage = () => {
  const params = useParams()
  const navigate = useNavigate()
  const { user } = useUserContext()
  const { joinWorkspace } = useContext(workspaceContext)

  useEffect(() => {
    if (user) join()
  }, [user]) // We must ensure we don't try to join before the user is actually available.

  const join = async () => {
    try {
      const data = await joinWorkspace(params.invitationLinkId)
      toastr.info('You have joined a new workspace')
      navigate(`/s/${data.index}/presentations`)
    } catch (error) {
      toastr.error('This link is either invalid, or you are not eligible to use it.', { timeout: 1000 })
      console.error('Failed to join workspace using link', error)
      navigate('/')
    }
  }

  return (
    <SizleLoader />
  )
}

export default InvitationLinkPage
