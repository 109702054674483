import React, { useState } from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { HiOutlineMenuAlt2 } from 'react-icons/hi'
import { Popover, PopoverTrigger, PopoverContent, PopoverArrow } from '@chakra-ui/core'

import MobileMenuItem from './MobileMenuItem'

const MobileMenu = ({ options, displayWidth = '768px' }) => {
  const [isOpen, setOpen] = useState(false)

  const handleClickHelp = () => {
    window.location.href = `${import.meta.env.VITE_MAIN_URL}/support`
  }

  const toggleMenu = () => {
    setOpen(!isOpen)
  }

  return (
    <Wrapper displayWidth={displayWidth}>
      <Popover
        placement='bottom-right'
        onClose={toggleMenu}
        isOpen={isOpen}
        closeOnBlur
      >
        <PopoverTrigger>
          <IconContainer>
            <HiOutlineMenuAlt2 onClick={toggleMenu} color='var(--text-color)' size={18} />
          </IconContainer>
        </PopoverTrigger>
        <Content
          minWidth='220px'
          width='220px'
          zIndex={20000}
          _focus={{ outline: 'none' }}
        >
          <PopoverArrow bg='var(--pane-background)' />
          <Menu isOpen={isOpen}>
            {options.map(option => (
              <MobileMenuItem key={option.children} {...option} toggleMenu={toggleMenu} />
            ))}
            <MobileMenuItem onClick={handleClickHelp} toggleMenu={toggleMenu}>Help</MobileMenuItem>
          </Menu>
        </Content>
      </Popover>
    </Wrapper>
  )
}

const Wrapper = styled.div(props => css`
  position: relative;
  display: none;

  @media only screen and (max-width: ${props.displayWidth}) {
    display: inline;
  }
`)

const IconContainer = styled.div`
  display: inline-block;
  margin-left: 10px;
`

const Content = styled(PopoverContent)`
  border: none;
`

const Menu = styled.div`
  position: absolute;
  background-color: var(--pane-background);
  width: 100%;
  border-radius: 10px;
  border: none;
  box-shadow: var(--editor-shadow);
`

export default MobileMenu
