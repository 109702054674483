import amplitude from 'amplitude-js'

export const initAmplitude = () => {
  amplitude.getInstance(import.meta.env.VITE_AMPLITUDE_INSTANCE).init(import.meta.env.VITE_AMPLITUDE_API_KEY)
}

export const setAmplitudeUserDevice = installationToken => {
  amplitude.getInstance(import.meta.env.VITE_AMPLITUDE_INSTANCE).setDeviceId(installationToken)
}

export const setAmplitudeUserId = userId => {
  amplitude.getInstance(import.meta.env.VITE_AMPLITUDE_INSTANCE).setUserId(userId)
}

export const setAmplitudeUserProperties = properties => {
  amplitude.getInstance(import.meta.env.VITE_AMPLITUDE_INSTANCE).setUserProperties(properties)
}

export const sendAmplitudeData = (eventType, eventProperties) => {
  amplitude.getInstance(import.meta.env.VITE_AMPLITUDE_INSTANCE).logEvent(eventType, eventProperties)
}

export const regenerateDeviceId = () => {
  amplitude.getInstance(import.meta.env.VITE_AMPLITUDE_INSTANCE).regenerateDeviceId()
}
